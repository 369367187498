import React, { useCallback } from 'react';
import { FaExclamationCircle } from "react-icons/fa";
import { useHistory } from 'react-router-dom';

const DemoPlate = () => {
    const history = useHistory();

    const handleSubcribe = useCallback(() => {
        history.push("/subscribe")
    }, []);

    return (
        <div className="tarot-demo" data-testid="tarot-demo">
            <FaExclamationCircle />
            <span>
                Приложение работает в режиме <b>демо-версии</b>. 
                Для доступа к полному функционалу пожалуйста 
                <a href="#" onClick={handleSubcribe} data-testid="subscribe-button"> <strong>оформите подписку</strong></a>.
            </span>
        </div>
    );
}

export default DemoPlate;