import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import WheelMenu from './WheelMenu';
import { getActiveDeal, fillPosition } from '../redux/deals/dealsSlice';
import { useGetDeckQuery } from '../api/deckApi';
import { useGetCardsByDeckQuery } from '../api/cardApi';
import { Arcane } from '../types/Arcane';
import { Suite } from '../types/Suite';
import { WheelMenuItem } from '../types/WheelMenuItem';
import { ExtraDef } from '../types/ExtraDef';
import { sortArcanes } from '..//types/arcanes';
import { sortSuites } from '..//types/suites';

type Props = {
    x: number;
    y: number;
    visible: boolean;
    position: string;
    setVisible: (v: boolean) => void;
    setSearchVisible: (v: boolean) => void;
    extra: ExtraDef;
}

const ChooseCardWheelMenu = ({ x, y, visible, position, setVisible, setSearchVisible, extra }: Props) => {
    const dispatch = useDispatch();

    const deal = useSelector(getActiveDeal);
    const { data: deck } = useGetDeckQuery(deal?.deck?.id, { skip: !deal?.deck?.id })
    const { data: cards } = useGetCardsByDeckQuery(deck?.id, { skip: !deck });
    const [mainMenu, setMainMenu] = useState<WheelMenuItem[]>([]);
    const [item_id, setItemId] = useState(0)

    useEffect(
        () => {
            const arcanes = sortArcanes(deck?.arcanes)
            fillMainMenu(arcanes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [deck, deal]
    );    

    const menuResult = useCallback((item: string) => {
        // if (item == "Портал P") item = "Портал"
        const card = cards?.find(rec => ((rec.title.toUpperCase() == item.toUpperCase().trim()) 
            && (rec.deck?.id == deck?.id))) 
        dispatch(fillPosition({ card: card, position: position, extra: extra }));
        setVisible(false);	
    }, [cards, deck?.id, dispatch, extra, position, setVisible]);

    const openSearch = useCallback(() => {
        setSearchVisible(true)
        setVisible(false)
    }, [setSearchVisible, setVisible]);

    const mapHotKey = useCallback((letter: string) => {
        switch(letter) {
            case "I":
                return 'KeyI';
            case "V":
                return 'KeyV';
            case "X":
                return 'KeyX';
            case "P":
                return 'KeyP';
            case "К":
                return "KeyR";
            case "Д":
                return "KeyL";
            case "Р":
                return "KeyH";
            case "П":
                return "KeyG";
            case "10":
                return "Digit1";
            case "9":
                return "Digit9";
            case "8":
                return "Digit8";
            case "7":
                return "Digit7";
            case "6":
                return "Digit6";
            case "5":
                return "Digit5";
            case "4":
                return "Digit4";
            case "3":
                return "Digit3";
            case "2":
                return "Digit2";
            case "Т":
                return "KeyN";
        }
    }, []);
    
    const getElderSubMenu = useCallback((elders: Arcane[], bread_crumbs: string) => {
        const  subset = elders.filter(e => e.code.substring(0, bread_crumbs.length) == bread_crumbs)
        const  subletters: string[] = []
        const  submenu = []
        submenu.push({
            id: item_id, 
            name: 'back', 
            label: 'icon', 
            action: 'go-back', 
            style: 'solo', 
            hint: 'Вернуться', 
            shortcut: 'Backspace'
        })
        const  top_arcane = elders.find(e => e.code == bread_crumbs)
        submenu.push({
            id: item_id + 1, 
            name: bread_crumbs, 
            label: 'text', 
            action: 'commit', 
            hint: `${top_arcane?.title} ${top_arcane?.code}`, 
            shortcut: 'Enter'
        })
        setItemId(item_id + 2)
        subset.map(si => {
            if (si.code.length > bread_crumbs.length) {
                const  first_letters = si.code?.substring(0, bread_crumbs.length + 1)
                if (!subletters.includes(first_letters)) {
                    subletters.push(first_letters)
                }
            }
        })
        subletters.map((l) => {
            const  arcane = elders.find(e => e.code == l)
            const  subsubmenu = getElderSubMenu(elders, l)
            const  menu_item: WheelMenuItem = {
                id: item_id, 
                name: l, 
                label: 'text', 
                action: 'commit', 
                style: 'sticky', 
                hint: `${arcane?.title} ${arcane?.code}`, 
                shortcut: mapHotKey(l.substring(l.length - 1))
            }
            if (subsubmenu.length > 1) {
                menu_item.action = 'open-child';
                menu_item.submenu = subsubmenu;
            }
            submenu.push(menu_item)
            setItemId(item_id + 1);
        })
        submenu.push({
            id: item_id, 
            name: 'search', 
            label: 'icon', 
            action: 'extra-func', 
            style: 'solo', 
            hint: 'Поиск', 
            shortcut: 'KeyS'
        })
        return submenu
    }, [item_id, mapHotKey]);
    
    const fillMainMenu = useCallback((deck_arcanes: Arcane[]) => {
        const  menu = []
        menu.push({
            id: item_id, 
            name: 'search', 
            label: 'icon', 
            action: 'extra-func', 
            style: 'solo', 
            hint: 'Поиск', 
            shortcut: 'KeyS'
        })
        const  elders = deck_arcanes?.filter(a => a.elder == true)
        const  letters: string[] = []
        elders?.map((e) => {
            const  first_letter = e.code.substring(0, 1)
            if (!letters.includes(first_letter)) {
                letters.push(first_letter)
            }
        })
        letters.map((l) => {
            const  arcane = elders.find(e => e.code == l)
            const  submenu = getElderSubMenu(elders, l)
            const  menu_item: WheelMenuItem = {
                id: item_id, 
                name: l, 
                label: 'text', 
                action: 'commit', 
                style: 'sticky', 
                hint: `${arcane?.title} ${arcane?.code}`, 
                shortcut: mapHotKey(l)
            }
            if (submenu.length > 1) {
                menu_item.submenu = submenu
                menu_item.action = 'open-child'
            }
            menu.push(menu_item)
            setItemId(item_id + 1);
        })
        const  nonElders = deck_arcanes?.filter(a => a.elder == false)
        nonElders?.map((item) => {
            const  menu_item = {
                id: item_id, 
                name: item.code, 
                label: 'text', 
                action: 'open-child', 
                style: 'composite', 
                hint: item.title, 
                shortcut: mapHotKey(item.code),
                submenu: fillSuitesMenu(deck?.suites)
            }
            menu.push(menu_item)
        })
        setMainMenu(menu)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deck?.suites, getElderSubMenu, item_id, mapHotKey]);
    
    const fillSuitesMenu = useCallback((deck_suites: Suite[]) => {
        const  menu = []
        menu.push({name: 'back', label: 'icon', action: 'go-back', style: 'solo', hint: 'Вернуться', shortcut: 'Backspace'})
        const suites = sortSuites(deck_suites)
        suites.map((suite) => {
            switch (suite.title) {
                case "Скипетров":
                case "Жезлов":
                    menu.push({name: 'wand.svg', label: 'image', action: 'commit', hint: ' Скипетров', shortcut: 'KeyC'});
                    break;
                case "Кубков":
                case "Чаш":
                    menu.push({name: 'cup.svg', label: 'image', action: 'commit', hint: ' Кубков', shortcut: 'KeyR'});
                    break;
                case "Мечей":
                    menu.push({name: 'sword.svg', label: 'image', action: 'commit', hint: ' Мечей', shortcut: 'KeyV'});
                    break;
                case "Пентаклей":
                case "Дисков":
                    menu.push({name: 'pentacle.svg', label: 'image', action: 'commit', hint: ' Пентаклей', shortcut: 'KeyG'});
                    break;
            }
        })
        return menu
    }, []);
    
    return (
        <>
            <WheelMenu 
                items={mainMenu} 
                h1={`Позиция ${position}`}
                x={x}
                y={y}
                visible={visible}
                setVisible={setVisible}
                callback={menuResult}
                extraFunc={openSearch}
            />
        </>
    )
}

export default ChooseCardWheelMenu;