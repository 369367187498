import { CardRef } from "../types/CardRef";

// eslint-disable-next-line no-undef
const { TAROT_API_URL } = process.env;

function loadImages(card_refs: CardRef[] | undefined, callback: (images: { [key: string]: HTMLImageElement; }) => void) {
    const images: { [key: string]: HTMLImageElement; } = {};
    let loadedImages = 0;
    let numImages = 0;

    card_refs?.map(ref => {
        if (ref?.card) {
            numImages++;
        }
    });

    if (numImages == 0) {
        callback(images);
    }

    card_refs?.map(ref => {
        if (ref.card && ref.card.pic) {
            images[ref.position] = new Image();
            images[ref.position].onload = function() {
                if(++loadedImages >= numImages) {
                    callback(images);
                }
            };
            images[ref.position].src = `${TAROT_API_URL}/medias/${ref.card.pic.url}`;
        }
    })
}

export default loadImages;