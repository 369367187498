import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import SubscribeModal from '../modals/SubscribeModal';
import { useGetSelfAccountQuery, useGetCatalogQuery, 
    useUpdateSubscriptionMutation, useGetProductQuery} from '../api/accountApi';
import { getChangeInProgress, setUser } from '../redux/settings/settingsSlice';
import { Price } from '../types/Price';
// import { Service } from '../types/Service';

import '../scss/custom.scss';
import "react-datepicker/dist/react-datepicker.css";
import '../css/tile.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../css/nkp.css';
import { ToastContent } from '../types/ToastContent';
import { Product } from '../types/Product';
import { getExpanded, showSubscribe } from '../redux/modal/modalSlice';

// const { TAROT_API_URL } = process.env;

const Subscribe = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [modalVisible, setModalVisible] = useState(false);
    const [theProduct, setTheProduct] = useState<Product | null>(null);
    const [thePrice] = useState<Price | null>(null);
    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
    const [toast, setToast] = useState<ToastContent>({});
    const [showToast, setShowToast] = useState(false);
    const changeInProgress = useSelector(getChangeInProgress);
    const { data: catalog } = useGetCatalogQuery()
    const [updateSubscription] = useUpdateSubscriptionMutation()
    const { data: currentProduct } = useGetProductQuery()
    const expanded = useSelector(getExpanded);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(
        () => {
            if (isAccountSuccess) {
                dispatch(setUser(account))
            }
        }, [account, dispatch, isAccountSuccess]
    )

    const formWord = useCallback((num: number) => {
        switch (num) {
            case 1:
                return "колода"
            case 2:
            case 3:
            case 4:
                return "колоды"
            default:
                return "колод"
        }
    }, []);

    const formDealTypes = useCallback((num: number) => {
        switch (num) {
            case 1:
            case 21:
            case 31:
                return "вариант расклада"
            case 2:
            case 3:
            case 4:
            case 22:
            case 23:
            case 24:
            case 32:
            case 33:
            case 34:
                return "варианта раскладов"
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 25:
            case 26:
            case 27:
            case 28:
            case 29:
            case 30:
            case 35:
            case 36:
            case 37:
            case 38:
            case 39:
            case 40:
                return "вариантов раскладов"
            default:
                return "вариантов раскладов"
        }
    }, []);

    const confirmSubscribe = useCallback((product: Product) => {
        setTheProduct(product)
        dispatch(showSubscribe())
        // setModalVisible(true)
        // let price = service.prices.find(rec => rec.currency == account?.currency);
        // setThePrice(price || null)
    }, [dispatch]);

    const handleSubscribe = async () => {
        try {
            await updateSubscription(theProduct?.id).unwrap()
            setModalVisible(false);
            history.push(`/profile`)
        } catch {
          setToast({
            title: 'Не получилось...',
            description: "Не удалось, пожалуйста попробуйте еще раз.",
            status: 'error'
          })
          setShowToast(true)
        }
    }

    const buttonBlock = useCallback((product: Product) => {
        if (changeInProgress) {
            return (
                <div className='subscription-action'>
                    <span className='subscription-current'>Идет изменение тарифа</span>
                </div>
            )
        }

        return (
            <>
                {currentProduct?.pretty_name == product?.pretty_name ?
                <div className='subscription-action'>
                    <FaCheck/> <span className='subscription-current'>Ваш текущий тариф</span>
                </div>
                :
                <Button className="tarot-sex-button" size="sm" 
                    onClick={() => confirmSubscribe(product)}
                    data-testid="tx-service-button-class">
                    <FaCheck/> Подключить
                </Button>}
            </> 
        )
    }, [changeInProgress, confirmSubscribe, currentProduct?.pretty_name]);

    return (
        <div className={expanded ? "tarot-content-expanded" : "tarot-content"}>
            <div className="content mt-3" style={{ overflowY: "scroll" }}>
                <Row className="mb-5 w-100 subscription-container">
                    {catalog?.map((product, index) => {
                        const price = product.price_plans?.find(rec => rec.currency == account?.currency);
                        const decks = product.services.filter(rec => rec.name == "ACCESS_DECK")
                        const numDealTypes = product.services.filter(rec => rec.name == "ACCESS_DEAL_TYPE").length
                        const features = product.services.filter(rec => (rec.name != "ACCESS_DECK") && (rec.name == "ACCESS_DEAL_TYPE"))
                        return (
                            <Col key={index} className="subscription-type" data-testid="tx-service-class">
                            <div id="subscription-section">
                                    <div>
                                        <div className='subscription-name' data-testid='subscription-name'>
                                            {product.pretty_name}
                                        </div>
                                        <div className='icon'>
                                            {/* {product.icon && <img src={`${TAROT_API_URL}/medias/${serviceClass.icon}`} />} */}
                                        </div>
                                    </div>
                                    <div id="subscription-description">
                                        <div>
                                        {/* {(account?.product?.phase != "ACTIVE" && account?.product?.phase != "TRIAL") &&
                                        <div className='subscription-trial'>
                                            {serviceClass.trialDisclaimer}
                                        </div>} */}
                                        <div className='field' data-testid='subscription-decks'>
                                            {decks.length} {formWord(decks.length)}:
                                            <ul>
                                                {decks.map((deck, index) => {
                                                    return (<li key={index}>{deck?.parameter}</li>)
                                                })}
                                            </ul>
                                        </div> 
                                        <div className='field' data-testid='subscription-deals'>
                                            {numDealTypes} {formDealTypes(numDealTypes)}
                                        </div> 
                                        {features.map((feature, index) => {
                                            return (
                                                <div className='field' key={index}>
                                                    {feature.description}
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>
                                    {price ?
                                    <div id="subscription-footer">
                                        <div className='field'>
                                            Абонентская плата
                                        </div> 
                                        <div className='subscription-price' data-testid='subscription-price'>
                                            {price?.amount} {price?.currency}
                                        </div> 
                                        <div className='field'>
                                            в месяц
                                        </div> 
                                        <div className='field' data-testid='subscription-button'>
                                            {buttonBlock(product)}
                                        </div> 
                                    </div>
                                    :
                                    <div id="subscription-footer">
                                        <div className='field' data-testid='subscription-button'>
                                            {buttonBlock(product)}
                                        </div> 
                                    </div>}
                                </div>
                            </Col>
                        )
                    })}
                </Row> 
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">{toast?.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{toast?.description}</Toast.Body>
                </Toast>
            </div>

            <SubscribeModal product={theProduct} price={thePrice} subscribe={handleSubscribe}/>
        </div>
        
    );
}

export default Subscribe;