import React from 'react';
import axios, { AxiosHeaders } from 'axios';

const ErrorComponent = () => {
  return <span className='error-message'>Что-то пошло не так... Но мы обязательно разберемся.</span>;
};

const { TAROT_API_URL } = process.env;

type Props = {
    module: string; 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
};

type State = {
    hasError: boolean;
    module: string | null;
    error: { message: string; } | null;
    info: { componentStack: string; } | null;
}

export class AppError extends React.Component<Props, State>  {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            module: props.module,
            error: null,
            info: null
        };
    }
  
    static getDerivedStateFromError = () => {
        return { hasError: true };
    };

    componentDidCatch = async (error: { message: string; }, info: { componentStack: string; }) => {
        this.setState({ error, info });
        const requestHeaders = new AxiosHeaders();
        requestHeaders.set('Content-Type', 'application/json');    
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));

        await axios.post(
            `${TAROT_API_URL}/log`, 
            { 
                application: "tarology", 
                module: this.state.module, 
                message: error.message,
                stack: info.componentStack
            }, {
                headers: requestHeaders
            });
    };

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        return hasError ? <ErrorComponent /> : children;
    }
}