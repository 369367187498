import React from 'react';
import Login from './components/Login';
import Register from './components/Register';
import Reminder from './components/Reminder';
import Confirm from './components/Confirm';
import Deal from './components/Deal';
import Client from './components/Client';
import Clients from './components/Clients';
import UserProfile from './components/UserProfile';
import Subscribe from './components/Subscribe';

const useRoutes = (props) => {
    return ([
        {
            path: "/",
            exact: true,
            redirect: "/deal/0",
            main: null,
            sidebar: true,
            header: true
        },
        {
            path: "/login",
            exact: false,
            redirect: null,
            main: <Login {...props} /* refresh={() => setFlag(!flag)}*/ />,
            sidebar: false,
            header: false
        },
        {
            path: "/register",
            exact: false,
            redirect: null,
            main: <Register {...props} /* refresh={() => setFlag(!flag)}*/ />,
            sidebar: false,
            header: false
        },
        {
            path: "/confirm/:token?",
            exact: false,
            redirect: null,
            main: <Confirm {...props} /* refresh={() => setFlag(!flag)}*/ />,
            sidebar: false,
            header: false
        },
        {
            path: "/reminder",
            exact: false,
            redirect: null,
            main: <Reminder {...props} /* refresh={() => setFlag(!flag)}*/ />,
            sidebar: false,
            header: false
        },
        {
            path: "/deal/:dealId?",
            exact: false,
            redirect: null,
            main: <Deal {...props} /* refresh={() => setFlag(!flag)}*/ />,
            sidebar: true,
            header: true
        },
        {
            path: "/client/:clientId?",
            exact: false,
            redirect: null,
            main: <Client {...props} /* refresh={() => setFlag(!flag)}*/ />,
            sidebar: true,
            header: true
        },
        {
            path: "/clients",
            exact: false,
            redirect: null,
            main: <Clients {...props} /* refresh={() => setFlag(!flag)}*/ />,
            sidebar: true,
            header: true
        },
        {
            path: "/profile",
            exact: false,
            redirect: null,
            main: <UserProfile {...props} /* refresh={() => setFlag(!flag)}*/ />,
            sidebar: true,
            header: true
        },
        {
            path: "/subscribe",
            exact: false,
            redirect: null,
            main: <Subscribe {...props} /*refresh={() => setFlag(!flag)}*/ />,
            sidebar: true,
            header: true
        },
        {
            path: "*",
            exact: false,
            redirect: "/deal/",
            main: null,
            sidebar: true,
            header: true
        }
    ])
}

export default useRoutes;