import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { getActiveDeal, /*setActiveDeal,*/ getZeroDeal, fillPosition, 
    resetActiveDeal, dealAdded, getActiveExtra, getNewDealFlag, 
    getNewDealStatus, increaseLimit2, setActiveDeal2, refreshDeal,
    chooseExtraCard, resetActiveExtra} from '../redux/deals/dealsSlice';
import { /*fillGeometry,*/ resetGeometry, selectGeometry } from '../redux/geometry/geometrySlice';
import { useGetExtraDealTypesQuery } from '../api/extraDealTypeApi';
import useWindowDimensions from '../useWindowDimensions';
import * as geo from '../helpers/geometry';
import * as drawing from '../helpers/draw';
import ModalChooseCard from './ModalChooseCard';
import DealContextMenu from './DealContextMenu';
import { DealTree } from './deal-tree/DealTree';
import Spinner from 'react-bootstrap/Spinner';
import Canvas from './Canvas';
import loadImages from '../helpers/loadImages';
import CardArticles from './deal-floats/CardArticles';
import DealHint from './deal-floats/DealHint';
import DealComments from './deal-floats/DealComments';
import DealQuestion from './deal-floats/DealQuestion';
import ChooseCardWheelMenu from './ChooseCardWheelMenu';
import useEscape from '../hooks/useEscape';
import { useGetSelfAccountQuery, useGetPermissionsQuery } from '../api/accountApi';
import { useGetDealQuery } from '../api/dealApi';
import { useGetDealTypeQuery } from '../api/dealTypeApi';
import { useGetDecksQuery/*, useGetDeckQuery*/ } from '../api/deckApi';
import { useGetCardsByDeckQuery } from '../api/cardApi';
import { getExpanded, hideQuestion } from '../redux/modal/modalSlice';
import { setUser } from '../redux/settings/settingsSlice';

import '../scss/custom.scss';
import '../css/nkp.css';
import '../css/tile.css';
import '../css/wheel1.css';
import { ExtraDef } from '../types/ExtraDef';
// import { ExtraDeal } from 'src/types/ExtraDeal';
import { Article } from '../types/Articles';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { ContextMenuState } from '../types/ContextMenuState';
import { ActionCreators } from 'redux-undo';

const Deal = () => {
    const expanded = useSelector(getExpanded);
	const [visible, setVisible] = useState(false);
	const [searchVisible, setSearchVisible] = useState(false);
	const [theCard, setTheCard] = useState<string | null>(null);
    // const [extraType, setExtraType] = useState(-1);
    const [singleSelections, setSingleSelections] = useState<Option[]>([]);
    const history = useHistory();
	const { data: permissions, isSuccess: isPermissionsSuccess } = useGetPermissionsQuery()
    const [cardHover, setCardHover] = useState("");

    const [contextMenuState, setContextMenuState] = useState<ContextMenuState>({
        visible: false,
        type: "",
        target: null,
        extra: null,
        extra_target: null
    })
    const [menuCoords, setMenuCoords] = useState({ x: 500, y: 500 });
    const [cardArticles, setCardArticles] = useState<Article[]>([]);
    const { height, width } = useWindowDimensions();

    const { dealId } = useParams<{dealId: string}>();
    const dispatch = useDispatch();
    const deal = useSelector(getActiveDeal);
    const extra = useSelector(getActiveExtra);
    const { data: extraTypes } = useGetExtraDealTypesQuery()
    // const { data: deck } = useGetDeckQuery(deal?.deck?.id, { skip: !deal?.deck?.id })
    const { data: decks } = useGetDecksQuery();
    // const { data: cards } = useGetCardsQuery();
    const { data: cards } = useGetCardsByDeckQuery(deal?.deck?.id, { skip: !deal?.deck?.id });
    const { data: existingDeal } = useGetDealQuery(dealId, { skip: !dealId || dealId == "0" })
    const { data: dealType } = useGetDealTypeQuery(deal?.deal_type?.id, { skip: !deal?.deal_type?.id })
    const zeroDeal = useSelector(getZeroDeal);

    const newDealStatus = useSelector(getNewDealStatus);
    const newDealFlag = useSelector(getNewDealFlag);
    const geometry = useSelector(selectGeometry);
    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()

    useEscape(() => setContextMenuState({ ...contextMenuState, visible: false }))

    useEffect(
        () => {
            if (isAccountSuccess) {
                dispatch(setUser(account))
            }
        }, [account, dispatch, isAccountSuccess]
    )

    useEffect(
        () => {
            if (newDealFlag) {
                resetDeal();
                dispatch({ type: "API_DEAL_FLAG_REMOVE" });
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [newDealFlag]
    )

    useEffect(
        () => {
            if (newDealStatus?.id) {
                history.push(`/deal/${newDealStatus.id}`)
            }            
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [newDealStatus]
    );

    useEffect(
        () => {
            dispatch(resetGeometry());
            setCardArticles([]);
        }, [dispatch]
    );

    useEffect(
        () =>{
            console.log("Deal Id changed to", dealId, existingDeal)
            if (isPermissionsSuccess) {
                if (!permissions?.deals?.view && dealId != "0") {
                    history.push(`/deal/0`);                
                }
                let currentDeal = {...existingDeal};
                if (existingDeal && existingDeal?.id != 0) {
                    dispatch(dealAdded(existingDeal))
                }
                if (dealId == "0") {
                    currentDeal = {...zeroDeal, id: 0}
                } 
                if (currentDeal && currentDeal.deal_cards) {
                    console.log("Dispatch", currentDeal)
                    resetDeal();
                    const long = currentDeal?.deck?.long;
                    // dispatch(setActiveDeal(currentDeal));
                    dispatch(setActiveDeal2(currentDeal, width, height, expanded, false, (long || false)))
                } else {
                    resetDeal()
                }
                dispatch(resetActiveExtra())
                dispatch(ActionCreators.clearHistory()) 
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dealId, existingDeal, zeroDeal, account, permissions]
    )

    const resetDeal = () => {
        dispatch(resetActiveDeal());
        dispatch(resetGeometry());
        setCardArticles([]);
    }

    useEffect(
        () => {
            if (singleSelections.length > 0) {
                const card = cards?.find(rec => rec.title == singleSelections[0] 
                    /*&& rec.deck.id == deck?.id*/);
                dispatch(fillPosition({ card: card, position: theCard, extra: extra }));
                setSingleSelections([]);
            }
            setSearchVisible(false);	
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [singleSelections]
    );
        
    const getExtraDeal = (extra: ExtraDef) => {
        if (extra?.index || extra?.index == 0) {
            if (extra?.position == "-1") {
                return deal?.extra_deals[extra?.index]
            } else {
                return deal?.deal_cards.find(rec => rec.position == extra?.position)?.extra_deals[extra?.index]
            }    
        }
    } 

    const getExtraType = (extra: ExtraDef) => {
        const extraDeal = getExtraDeal(extra)
        return extraTypes?.find(rec => rec.id == extraDeal?.extra_deal_type?.id)
    }

    useEffect(
        () => {
            console.log("Refresh on hook", deal)
            // refresh(true);
            if (extra?.position == "-1" && extra?.index == -1) {
                const long = deal?.deck?.long;
                dispatch(refreshDeal(deal?.deal_type, deal?.limit, width, height, expanded, true, long))
            } else {
                const extraType = getExtraType(extra)
                const extraDeal = getExtraDeal(extra)
                const long = deal?.deck?.long;
                dispatch(refreshDeal(extraType, extraDeal?.limit, width, height, expanded, true, long))
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [width, expanded/*, existingDeal*/]
    )


    const getTargetId = (event: React.MouseEvent<HTMLElement>, 
                        ref: React.RefObject<HTMLCanvasElement>, 
                        x2: number | null = null, y2: number | null = null): string | null => {
        const canvas = ref.current;
        const context = canvas?.getContext('2d');
        const rect = canvas?.getBoundingClientRect();
        let x
        let y
        if (x2 && y2) {
            x = x2 - (rect?.left || 0);
            y = y2 - (rect?.top || 0);    
        } else {
            x = event.clientX - (rect?.left || 0);
            y = event.clientY - (rect?.top || 0);
        }

        let targetId = null;
        geometry?.ids?.map(id => {
            const path: Path2D | undefined = geometry.entities[id]?.path
            if (path) {
                if (context?.isPointInPath(path, x, y)) {
                    if (getExtraType(extra)?.fixed != id) {
                        targetId = id;
                    } 
                }    
            }
        })

        return targetId;
    }

    const getTargetCard = (targetId: string | null): boolean => {
        let cardFlag = false;

        if (extra.position == "-1" && extra.index == -1) {
            if (deal?.deal_cards?.find(rec => rec.position == targetId)?.card) {
                cardFlag = true;
            }   
        } else {
            if (extra.position == "-1") {
                if (extra.index || extra.index == 0) {
                    if (deal?.extra_deals[extra.index]?.extra_deal_cards?.find(rec => rec.position == targetId)?.card) {
                        if (getExtraType(extra)?.fixed != targetId) {
                            cardFlag = true;
                        }
                    }    
                }
            } else {
                if (extra.index || extra.index == 0) {
                    const card = deal?.deal_cards.find(rec => rec.position == extra.position)
                    if (card) {
                        if (card.extra_deals[extra.index].extra_deal_cards.find(rec => rec.position == targetId)?.card) {
                            if (getExtraType(extra)?.fixed != targetId) {
                                cardFlag = true;
                            }
                        }
                    }
                }
            }
        }

        return cardFlag;
    }

    const handleCanvasClick = (event: React.MouseEvent<HTMLElement>, ref: React.RefObject<HTMLCanvasElement>) => { 
        const canvas = ref.current
        const context = canvas?.getContext('2d')
        const rect = canvas?.getBoundingClientRect();
        const x = event.clientX - (rect?.left || 0);
        const y = event.clientY - (rect?.top || 0);
        const targetId = getTargetId(event, ref)
        setMenuCoords({ x: event.screenX, y: event.screenY - 100 })
        const extraType = getExtraType(extra)

        if (targetId) {
            if (extra?.position == "-1" && extra?.index == -1) {
                setTheCard(targetId);
                setVisible(true);    
            } else {
                const target = new Path2D();
                const pos = geometry.entities[targetId];
                target.arc((pos?.path?.center.x || 0), (pos?.path?.center.y || 0), (pos?.width || 0) / 3, 0, 2 * Math.PI);
                target.closePath();
                if (cardHover == targetId 
                    // && extra?.extra_refs.find(rec => rec.position == pos.position)
                    && context?.isPointInPath(target, x, y) && extraType?.selectable) {
                        dispatch(chooseExtraCard(targetId));
                    // dispatch(chooseDealExtraCard({ position: targetId, extra: extra?.id }));
                } else {
                    setTheCard(targetId);
                    setVisible(true);
                }
            }
        } else if (geometry.geo.plusBtn && context?.isPointInPath(geometry.geo.plusBtn, x, y)) {
            if (extra?.position == "-1" && extra?.index == -1 && dealType) {
                const long = deal?.deck?.long;
                dispatch(increaseLimit2({ position: extra?.position, index: extra?.index }, 
                    dealType, null, width, height, expanded, long
                ));        
    
                setContextMenuState({ ...contextMenuState, visible: false })
                setVisible(false);    
            } else if (extraType) {
                const long = deal?.deck?.long;
                dispatch(increaseLimit2({ position: extra?.position, index: extra?.index }, 
                    extraType, null, width, height, expanded, long
                ));        
    
                setContextMenuState({ ...contextMenuState, visible: false })
                setVisible(false);    
            }
        } else {
            setContextMenuState({ ...contextMenuState, visible: false })
            setVisible(false);
            dispatch(hideQuestion())
        }
    };

    // const handleCanvasClickOutside = (e) => {
    //     // eslint-disable-next-line no-undef
    //     console.log("Click-claack-clang", e.target.className)
    //     if (e.target.className != "tarot-context-menu-item") {
    //         setContextMenuState({ ...contextMenuState, visible: false })
    //     }
    // }

    const handleCanvasContext = (event: React.MouseEvent<HTMLElement>, ref: React.RefObject<HTMLCanvasElement>) => { 
        if(deal?.readOnly != true) {
            const targetId = getTargetId(event, ref);
            const cardFlag = getTargetCard(targetId);
            const menuType = (extra.position == "-1" && extra.index == -1) ? "card" : "extra_card"
            if (targetId && cardFlag) {
                setMenuCoords({ x: event.clientX, y: event.pageY })
                setContextMenuState({ ...contextMenuState, 
                                        type: menuType, 
                                        target: targetId, 
                                        extra: (extra?.index || extra?.index == 0) ? extra.index : -1, 
                                        extra_target: extra?.position || "-1",
                                        visible: !contextMenuState.visible })
            } else {
                setMenuCoords({ x: event.clientX, y: event.pageY })
                setContextMenuState({ ...contextMenuState, type: "global", visible: !contextMenuState.visible })
            }
        }
    }


    const handleCanvasMouseMove = (event: React.MouseEvent<HTMLElement>, ref: React.RefObject<HTMLCanvasElement>) => {
        if (deal?.readOnly != true) {
            const targetId = getTargetId(event, ref);
            const cardFlag = getTargetCard(targetId);
            if ((targetId || targetId == "0") && cardFlag) {
                setCardHover(targetId);
            }
        }
    }

    const draw = (ctx: CanvasRenderingContext2D) => {
        if (newDealFlag) {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)    
        } else {
            // console.log(geometry)

            // eslint-disable-next-line no-undef
            if (extra?.index == -1 && extra?.position == "-1") {
                // eslint-disable-next-line no-undef
                loadImages(deal?.deal_cards, function(images) {
                    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
                    ctx.strokeStyle = "black";
                    const stage = geo.adjustStageDimensions(geometry.geo.stage)
                    ctx.canvas.width = stage.width
                    ctx.canvas.height = stage.height

                    geometry?.ids?.map((id) => {
                        const pos = geometry.entities[id];
                        const ref = deal?.deal_cards?.find(rec => rec.position == pos?.position)
                        drawing.drawCards(ctx, pos, images, ref);
    
                        if (dealType?.initialLimit && dealType?.initialLimit != 0 && 
                            (deal?.limit || deal?.limit == 0) &&
                            deal?.limit < (dealType.deal_cards?.length || 0)) {
                            drawing.drawPlusButton(ctx, geometry.geo);
                        }
                    })
                })    
            } else {
                const extraDeal = getExtraDeal(extra)
                const extraType = getExtraType(extra)
                loadImages(extraDeal?.extra_deal_cards, function(images) {
                    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
                    ctx.strokeStyle = "black";
                    const stage = geo.adjustStageDimensions(geometry.geo.stage)
                    ctx.canvas.width = stage.width
                    ctx.canvas.height = stage.height
    
                    geometry?.ids?.map(id => {
                        const pos = geometry.entities[id];
                        const ref = extraDeal?.extra_deal_cards?.find(rec => rec.position == pos?.position)
                        drawing.drawCards(ctx, pos, images, ref);
    
                        if (extraType?.initialLimit && extraType?.initialLimit != 0 &&
                            (extraDeal?.limit || extraDeal?.limit == 0) &&
                            extraDeal?.limit < (extraType.deal_cards?.length || 0)) {
                            drawing.drawPlusButton(ctx, geometry.geo);
                        }

                        if (cardHover == id && pos && extraType?.selectable /*&& images[pos.position] && type.selectable*/) {
                            drawing.drawTarget(ctx, pos)
                        }

                        if (ref?.selected == false && extraType?.selectable) {
                            if (pos?.path) {
                                ctx.save();
                                ctx.globalAlpha = 0.5;
                                ctx.fillStyle = "white";
                                ctx.fill(pos?.path);
                                ctx.restore();    
                            }
                        }        
                    })
                })    
            }
        }
    }

    const calculateMinWidth = () => {
        let minWidth
        let delta = 0
        if (expanded) {
            delta = 240
        }

        if (width > 640 + delta) {
            minWidth = width - 440 - delta
        } else {
            minWidth = 200
        }

        return minWidth
    }

    const switchCardArticle = (position: string) => {
        const newArticles = cardArticles.filter(rec => rec.position != position)
        setCardArticles(newArticles)
    }

    return (
		<>
        <div className={expanded ? "tarot-content-expanded" : "tarot-content"}>
        {(!isAccountSuccess || decks?.length == 0 || decks == null /*|| dealTypes == [] || dealTypes == null*/) ?
            <div id="tarology-spinner">
                <Spinner animation="border" />
            </div>
            :
            <>
                <DealTree state={contextMenuState} 
                    setContextMenuState={setContextMenuState} 
                    setMenuCoords={setMenuCoords}
                    height={height}
                    width={width}
                    expanded={expanded}
                />
                <div className="p-3" id="work-area" style={{ 
                        minWidth: calculateMinWidth() }}>
                    <Canvas 
                        // className={``}
                        draw={draw} 
                        // flag={newDealFlag.toString()} 
                        onClick={handleCanvasClick} 
                        onContextMenu={handleCanvasContext} 
                        onMouseMove={handleCanvasMouseMove}
                        onMouseLeave={() => setCardHover("")} 
                        // onClickOutside={handleCanvasClickOutside}
                        dataTestid="tx-deal-canvas"
                    />
                </div>
            </>
        }
        </div>

        <CardArticles 
            articles={cardArticles}
            switchFunc={switchCardArticle}
        />
        <DealComments permissions={permissions} />
        <DealQuestion />
        <DealHint hint={dealType?.hint} />
        <DealContextMenu
            state={contextMenuState} 
            menuCoords={menuCoords}
            setState={setContextMenuState}
            articles={cardArticles}
            setArticles={setCardArticles}
            setTheCard={setTheCard}
            setWheelVisible={setVisible}
            height={height}
            width={width}
            expanded={expanded}
        />
        {cards && deal?.deck &&
            <ModalChooseCard 
                visible={searchVisible} 
                cards={cards?.map(rec => rec.title)}
                // cards={cards.filter(rec => rec?.deck?.id == deal?.deck?.id).map(rec => rec.title)}
                close={() => setSearchVisible(false)} 
                handleChange={setSingleSelections} 
                selected={singleSelections}
                data-testid="tx-deal-choose-card-modal"
            />
        }

            <ChooseCardWheelMenu 
                visible={visible} 
                x={menuCoords.x} 
                y={menuCoords.y}
                extra={extra}
                position={theCard || ""} 
                setVisible={setVisible}
                setSearchVisible={setSearchVisible}
            /> 
		</>
	);
}

export default Deal;