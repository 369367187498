import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LocalAuthData } from '../types/Account';
import { setAuthenticated, selectIsAuthenticated } from '../redux/settings/settingsSlice';
import { useLoginMutation, useRerequestMutation } from '../api/authApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

const Login = () => {
    const isAuthenticated = useSelector(selectIsAuthenticated); 
    const dispatch = useDispatch();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [remember, setRemember] = useState(false)
    const [error, setError] = useState<string | JSX.Element | null>(null)
    const history = useHistory();

    const [login] = useLoginMutation();
    const [rerequest] = useRerequestMutation();

    const handleKeyPress = (event: { key: string; }) => {
        if(event.key === 'Enter'){
          handleLogin();
        }
    }

	useEffect(
		() => {
            const accessToken = localStorage.getItem("accessToken")
			if (accessToken) {
				dispatch(setAuthenticated(true))
            }
            if (isAuthenticated) {
                history.push(`/`)
            } 
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isAuthenticated]
	)

    const handleLogin = async () => {
        try {
            const auth: LocalAuthData = await login({ username: username, password: password, remember: remember }).unwrap()
            setError(null)
            localStorage.setItem('accessToken', auth.access_token)
            localStorage.setItem('refreshToken', auth.refresh_token)
            dispatch(setAuthenticated(true))
            history.push(`/`)
        } catch (err) {
            console.log("ERROR!", err)
            const error = err as FetchBaseQueryError
            switch (error.status) {
                case 401:
                    setError("Имя пользователя или пароль введены неверно")
                    break;
                case 409:
                    setError(<>Аккаунт еще не активирован. Пожалуйста найдите в почтовом ящике 
                        письмо от Тарологии и 
                        <span className="tarot-intro-link" onClick={async () => {
                            try {
                                await rerequest({ username: username }).unwrap()
                                setError("Новое письмо выслано!")
                            } catch (err) {
                                setError("Что-то пошло не так...")
                            }
                        }}> пройдите 
                        по ссылке </span> или запросите новое письмо.</>)
                    break;
                default:
                    setError("Что-то пошло не так...")
            }
        }
    } 

    return (
        <>
        <div className="splash-body" style={{ background: `url("/image/tarology-bg.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <div className='splash-card'>
                <div className='splash-logo'>
                </div>
                <p className='tarot-intro-title'>Войдите в вашу учетную запись</p>
                <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Имя пользователя</small></p>
                    <input 
                        type="text" 
                        value={username}
                        className="tarot-modal-input"
                        data-testid='tarot-login-username-input'
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'>
                        <small>Пароль</small>
                    </p>
                    <input 
                        type="password" 
                        value={password}
                        className="tarot-modal-input"
                        data-testid='tarot-login-password-input'
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={handleKeyPress} 
                    />
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p-sub'>
                        <small>
                            <input type="checkbox" checked={remember} onChange={() => setRemember(!remember)}/> Запомнить меня
                        </small>
                    </p>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <p className='tarot-modal-p-sub'>
                        <small>
                            <span className="tarot-intro-link"
                                onClick={async () => { history.push(`/reminder`) }}>
                                Забыли пароль?
                            </span>
                        </small>
                    </p>
                </Col>
            </Row>
            {error &&
            <Row id="subscribe-confirm-body">
                <Col>
                    <center>
                        <p className='tarot-modal-p-error'>
                            <small>{error}</small>
                        </p>
                    </center>
                </Col>
            </Row>}
            <Button className="tarot-intro-button tarot-sex-button" onClick={handleLogin}
                data-testid="modal-button-ok">
                Вход
            </Button>
            <p className='tarot-intro-footer'>Новый пользователь? &nbsp;
                <span className='modal-registration-link' 
                    onClick={() => { history.push(`/register`) }}
                >Регистрация</span>
            </p>
            </div>
        </div>
        </>
    )
}

export default Login;