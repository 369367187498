import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import { getConfirmDeleteVisible, hideConfirmDelete } from '../redux/modal/modalSlice';
import { getActiveDeal, selectDealToDelete } from '../redux/deals/dealsSlice';
import { useGetCustomerQuery } from '../api/clientApi';
import { useGetDealTypeQuery } from '../api/dealTypeApi';
import { useDeleteDealMutation } from '../api/dealApi';
import { ToastContent } from '../types/ToastContent';

const ConfirmDeleteModal = () => {
    const history = useHistory();

    const dispatch = useDispatch();
	const ConfirmDeleteVisible = useSelector(getConfirmDeleteVisible)
    const deal = useSelector(getActiveDeal);
    const dealToDelete = useSelector(selectDealToDelete);
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const { data: client } = useGetCustomerQuery(deal?.customer?.id!, { skip: !deal?.customer?.id || deal?.customer?.id == "-1" })
    const { data: dealType } = useGetDealTypeQuery(deal?.dealTypeId, { skip: !deal?.dealTypeId || deal?.dealTypeId == "0" })
    const { data: dealToDeleteClient } = 
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        useGetCustomerQuery(dealToDelete?.customer?.id!, { skip: !dealToDelete?.customer?.id || dealToDelete?.customer?.id == "0" })
    const [toast, setToast] = useState<ToastContent>({});
    const [showToast, setShowToast] = useState(false);
    const [deleteDeal, { isLoading: isDeleteLoading }] = useDeleteDealMutation()

    const handleClose = () => {
        dispatch(hideConfirmDelete())
    }

    useEffect(
        () => { console.log(isDeleteLoading) }, [isDeleteLoading]
    )

    const handleDelete = async () => {
        let deal_deleted = deal
        if (dealToDelete) {
            deal_deleted = dealToDelete
        } 
        try {
            await deleteDeal(deal_deleted.id || "").unwrap()
            dispatch(hideConfirmDelete())
            history.push(`/deal/0`);
            dispatch({ type: "API_DEAL_FLAG_SET" });
        } catch {
            setToast({
            title: 'Не получилось...',
            description: "Не удалось удалить расклад, пожалуйста попробуйте еще раз.",
            status: 'error'
            })
            setShowToast(true)
        }
    }

    return (
        <Modal show={ConfirmDeleteVisible} size="sm" className="tarology-modal" 
            animation={false} onHide={handleClose} data-testid="modal-confirm-delete-deal">
        <Modal.Header className="bg-white" closeButton>
            <Modal.Title>Удалить расклад</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row id="subscribe-confirm-body" className='mt-5'>
                <Col>
                    <p>Вы действительно хотите удалить расклад <strong>{dealToDelete ? dealToDelete?.deal_type?.title : dealType?.title} </strong>
                    клиента <strong>{dealToDelete ? dealToDeleteClient?.name : client?.name}</strong>?</p>
                </Col>
            </Row>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">{toast?.title}</strong>
                </Toast.Header>
                <Toast.Body>{toast?.description}</Toast.Body>
            </Toast>
        </Modal.Body>
        <Modal.Footer>
            {isDeleteLoading ?
            <Button className="tarot-button" size="sm" disabled={true}>
                <Spinner 
                    as="span"
                    data-testid="tarot-button-spinner"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                /> Сохраняю...
            </Button>
            :
            <Button  className="tarot-sex-button" onClick={handleDelete} data-testid="modal-button-ok">
                Подтвердить
            </Button>}
            <Button className="tarot-red-button" onClick={handleClose} data-testid="modal-button-cancel">
                Отменить
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default ConfirmDeleteModal;