/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createSelector, createAsyncThunk, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import prepareHeaders from '../../helpers/prepare-headers';
import { apiSlice } from '../apiSlice';
import { RootState } from '../store';
import { WritableDraft } from 'immer/dist/internal.js';
import { Client } from '../../types/Client';
import { Paging, Sort } from '../../types/Paging';
import { stat } from 'fs';

export interface ClientState {
  newclient: Client;
  activeClientId: string | null;
  activeClient: Client;
  sortBy: Array<Sort>;
  sortClientsBy: Array<Sort>;
  paging: Paging;
  editMode: boolean;
  confirmClientDeleteVisible: boolean;
  clientToDelete: Client | null;
  downloadStatus: string;
}

const initialState: ClientState = {
  newclient: {},
  activeClientId: null,
  activeClient: {},
  sortBy: [
    { field: "date", order: "desc" },
    { field: "deal_type", order: "none" }
  ],
  sortClientsBy: [
    { field: "name", order: "asc" },
    { field: "email", order: "none" },
    { field: "phone", order: "none" }, 
    { field: "sex", order: "none" }
  ],
  paging: { size: 25, start: 0, page: 1 },
  editMode: false,
  confirmClientDeleteVisible: false,
  clientToDelete: null,
  downloadStatus: 'idle',
}

const { TAROT_API_URL } = process.env;

const sortBy = (state: RootState) => state.clients.present.sortBy;
const sortClientsBy = (state: RootState) => state.clients.present.sortClientsBy;
const paging = (state: RootState) => state.clients.present.paging;

export const getPDFFromApi = createAsyncThunk('clients/getPDF', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch(`${TAROT_API_URL}/reports/clients`, {
      method: "GET",
      headers: prepareHeaders(localStorage.getItem('accessToken'))
    })
    .then(res => {
      return res.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Customers.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })

    return response
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response)
  } 
})

export const selectDealsSortParams = createSelector(sortBy, (sortBy: Array<Sort>) => 
{
  let  sorts = "";
  let  index = 0;
  sortBy.forEach(sort => {
      if (sort.order != "none") {
          if (index != 0) {
              sorts = sorts + "|"
          }
          sorts = sorts + sort.field + ":" + sort.order;
          index++;
      }
  });
  return sorts;
})

export const selectSortParams = createSelector(sortClientsBy, (sortClientsBy) => 
  { 
    let sorts = "";
    let index = 0;
    sortClientsBy.forEach(sort => {
        if (sort.order != "none") {
            if (index != 0) {
                sorts = sorts + "|"
            }
            sorts = sorts + sort.field + ":" + sort.order;
            index++;
        }
    });
    return sorts;
  }
)

export const selectPaging = createSelector(paging, (paging) => 
  {
    return `size=${paging.size}&page=${paging.page}`
  }
)

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    activeClientLoaded(state, action) {
      state.activeClient = action.payload;
    },
    clientUpdated(state, action: PayloadAction<Client>) {
      const { name, email, phone, birthday, sex, tags, comment } = action.payload;
      if (sex) { state.activeClient.sex = sex }
      if (birthday) { state.activeClient.birthday = birthday }
      if (name) {
        state.activeClient.name = name 
      } else if (name === "") {
        state.activeClient.name = ""
      }
      if (email) { state.activeClient.email = email?.replace(" ", "") }
      if (phone) { state.activeClient.phone = phone }
      if (tags) { state.activeClient.tags = tags }
      if (comment) { state.activeClient.comment = comment }
    },
    clientSwapFavorite(state, action: PayloadAction<void>) {
      const existingClient = state.activeClient
      if (existingClient) {
        existingClient.favorite = !existingClient.favorite;
      }
    },
    sortUpdated(state, action) {
        const  tmp: WritableDraft<Sort>[] = []
        state.sortBy.map((sort) => {
            if (sort.field === action.payload.field) {
                const  newSort = {...sort, order: action.payload.order }
                tmp.push(newSort)
            } else {
                tmp.push(sort)
            }
          })
          state.sortBy = tmp
    },
    switchEditMode(state, action: PayloadAction<void>) {
      const  currentMode = state.editMode;
      state.editMode = !currentMode;
    },
    setEditModeOn(state, action: PayloadAction<void>) {
      state.editMode = true;
    },
    setEditModeOff(state, action: PayloadAction<void>) {
      state.editMode = false;
    },
    clearNewClientFlag(state, action: PayloadAction<void>) {
      state.newclient = {};
    },
    sortClientAdded(state, action) {
      const  tmp: WritableDraft<Sort>[] = []
      state.sortClientsBy.map((sort) => {
          if (sort.field === action.payload.field) {
              const  newSort = {...sort, order: action.payload.order }
              tmp.push(newSort)
          } else {
              tmp.push(sort)
          }
      })
      state.sortClientsBy = tmp
    },
    sortClientUpdated(state, action) {
      const  tmp: WritableDraft<Sort>[] = []
      state.sortClientsBy.map((sort) => {
          if (sort.field === action.payload.field) {
              const  newSort = {...sort, order: action.payload.order }
              tmp.push(newSort)
          } else {
              tmp.push(sort)
          }
      })
      state.sortClientsBy = tmp
    },
    sortClientRemoved(state, action) {
      const  tmp: WritableDraft<Sort>[] = []
      state.sortClientsBy.map((sort) => {
          if (sort.field === action.payload.field) {
              const  newSort = {...sort, order: "none" }
              tmp.push(newSort)
          } else {
              tmp.push(sort)
          }
      })
      state.sortClientsBy = tmp
    },
    pageSizeSet(state, action) {
        state.paging.size = action.payload
    },
    pageSet(state, action) {
        const page = action.payload
        state.paging.start = state.paging.size * (page - 1)
        state.paging.page = page 
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPDFFromApi.pending, (state, action) => {
        state.downloadStatus = 'active';
      })
      .addCase(getPDFFromApi.fulfilled, (state, action) => {
        state.downloadStatus = 'idle';              
      })
      // .addMatcher(isAnyOf(apiSlice.endpoints.addCustomer.matchFulfilled), 
      //   (state, { payload }) => {
      //     state.activeClient = payload as Client
      // }) 
      // .addMatcher(isAnyOf(apiSlice.endpoints.getCustomer.matchFulfilled), 
      //   (state, { payload }) => {
      //     state.activeClient = payload as Client
      // }) 
  }
})

export const { activeClientLoaded, clientUpdated, switchEditMode, sortUpdated,
  clearNewClientFlag, sortClientAdded, sortClientRemoved, sortClientUpdated, 
  pageSet, pageSizeSet, setEditModeOn, setEditModeOff, clientSwapFavorite } = clientSlice.actions;

export default clientSlice.reducer;

export const getActiveClient = (state: RootState) => state.clients.present.activeClient;

export const getDealSorts = (state: RootState) => state.clients.present.sortBy;

export const selectAllSorts = (state: RootState) => state.clients.present.sortClientsBy;

export const getClientUndoQueueSize = (state: RootState) => state.clients.past.length; 

export const getClientRedoQueueSize = (state: RootState) => state.clients.future.length;

export const getNewClientStatus = (state: RootState) => state.clients.present.newclient;

export const getClientToDelete = (state: RootState) => state.clients.present.clientToDelete;

export const getEditMode = (state: RootState) => state.clients.present.editMode;

export const getPageSize = (state: RootState) => state.clients.present.paging.size;

export const getExportStatus = (state: RootState) => state.clients.present.downloadStatus;

export const getSortBy = (state: RootState) => state.clients.present.sortBy;
