import { AnyAction, Reducer, configureStore } from '@reduxjs/toolkit';
import undoable, { includeAction, StateWithHistory } from 'redux-undo';
import settingsReducers, { SettingsState } from './settings/settingsSlice';
import clientReducers, { ClientState } from './client/clientSlice';
import dealsReducers, { DealState } from './deals/dealsSlice';
import geometryReducers from './geometry/geometrySlice';
import modalRedusers from './modal/modalSlice';
import socketMiddleware from '../middleware/middleware';
import { apiSlice } from './apiSlice'
import { setupListeners } from '@reduxjs/toolkit/query';

const store = configureStore({
  reducer: {
      settings: undoable(settingsReducers, {
        filter: includeAction([
          "settings/setUserAvatar",
          "settings/setUserEmail",
          "settings/setUserGivenName",
          "settings/setUserFamilyName",
          "settings/setUserPhone",
          "settings/setUserCountry",
          "settings/setUserUsername"
        ])
      }) as Reducer<StateWithHistory<SettingsState>, AnyAction>,
      clients: undoable(clientReducers, {
        filter: includeAction([
          "clients/clientSwapFavorite",
          "clients/clientUpdated",
          "clients/sortUpdated",
          "clients/sortClientAdded",
          "clients/sortClientRemoved",
          "clients/sortClientUpdated",
          "clients/pageSet",
          "clients/pageSizeSet"
        ])
      }) as Reducer<StateWithHistory<ClientState>, AnyAction>,
      deals: undoable(dealsReducers, { 
        filter: includeAction([
          "deals/setActiveDealType",
          "deals/setActiveDealDeck",
          "deals/setActiveDealCustomer",
          "deals/addComment",
          "deals/updateComment",
          "deals/removeComment",
          "deals/fillPosition",
          "deals/invertPosition",
          "deals/addExtra",
          "deals/deleteExtra",
          "deals/setActiveDealDate",
          "deals/setActiveDealQuestion",
          "deals/setActiveExtraQuestion",
          "deals/increaseLimit",
          "deals/chooseExtraCard",
          "deals/resetActiveExtra",
          "deals/resetActiveDeal",
          "deals/setActiveExtra",
          "deals/setActiveDealLimit",
          "deals/setActiveExtraLimit"
        ]) 
      }) as Reducer<StateWithHistory<DealState>, AnyAction>,
      geometry: geometryReducers,
      modals: modalRedusers,
      [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(socketMiddleware()).concat(apiSlice.middleware),
})

setupListeners(store.dispatch);

export type AppStore = typeof store

export type RootState = ReturnType<typeof store.getState>

export default store