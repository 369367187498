import React  from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const blockquoteRenderer = (node: any) => {
    const tmp = node.children[1].children[0].value; 
    if (tmp) {
        const t = tmp.split("{"); 
        if (t.length > 1) {
            switch(t[1]) {
                case ".is-success}":
                    return (
                        <blockquote className="is-success">{t[0]}</blockquote>
                    )     
                case ".is-warning}":
                    return (
                        <blockquote className="is-warning">{t[0]}</blockquote>
                    )     
                case ".is-info}":
                    return (
                        <blockquote className="is-info">{t[0]}</blockquote>
                    )     
                default:
                    return (<b style={{ color: "black" }}>{t[0]}</b>)     
                }    
        }    
    }
    
    return (<b>{node.children[1].children[0].value}</b>)     
}