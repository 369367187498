import React from 'react';
import { connect } from 'react-redux';
import { socketConnect } from './redux/socket/socket';
import { string, func, node } from 'prop-types';

class SocketConnection extends React.Component {

    componentDidMount() {
        const { dispatch, host } = this.props;
        dispatch(socketConnect(host));
    }

    render() {
        return <div style={{ width: "100%", height: "101vh", margin: "0px", padding: "0px" }}>{this.props.children}</div>
    }

}

SocketConnection.propTypes = {
    host: string,
    dispatch: func,
    children: node
};

// eslint-disable-next-line react-redux/prefer-separate-component-file
export default connect()(SocketConnection);