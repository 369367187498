import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { getActiveDeal, setActiveDealDate } from '../../redux/deals/dealsSlice';

import "react-datepicker/dist/react-datepicker.css";

type Props = {
    disabled: boolean;
}

const DateSelector = ({ disabled }: Props) => {
    const dispatch = useDispatch();
    const deal = useSelector(getActiveDeal);

    registerLocale('ru', ru);

    return (
        <div data-testid="tarot-deal-date">
            <DatePicker 
                disabled={disabled}
                locale="ru"
                dateFormat="dd/MM/yyyy"
                className="datepicker"
                selected={deal.date ? new Date(deal.date) : new Date()} 
                onChange={(date) => dispatch(setActiveDealDate(date?.toISOString()))} 
            />
        </div>
    )
}

export default DateSelector;