import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getActiveClient, getClientToDelete } from '../redux/client/clientSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import { getConfirmDeleteClientVisible, hideConfirmDeleteClient } from '../redux/modal/modalSlice';
import { useDeleteCustomerMutation } from '../api/clientApi';

interface ToastContent {
    title: string;
    description: string;
    status: string;
}

const ConfirmDeleteClientModal = () => {
    const history = useHistory();

    const dispatch = useDispatch();
	const ConfirmDeleteVisible = useSelector(getConfirmDeleteClientVisible)
    const activeClient = useSelector(getActiveClient)
    const clientToDelete = useSelector(getClientToDelete);
    const [toast, setToast] = useState<ToastContent | null>(null);
    const [showToast, setShowToast] = useState(false);
    const [deleteClient, { isLoading }] = useDeleteCustomerMutation()

    const handleClose = () => {
        dispatch(hideConfirmDeleteClient())
    }

    const handleDelete = async () => {
        let client_deleted = activeClient
        if (clientToDelete) {
            client_deleted = clientToDelete
        } 
        try {
            await deleteClient(client_deleted.id || "").unwrap()
            dispatch(hideConfirmDeleteClient())
            history.push(`/clients`);
        } catch {
            setToast({
                title: 'Не получилось...',
                description: "Не удалось удалить клиента, пожалуйста попробуйте еще раз.",
                status: 'error'
            })
            setShowToast(true)
        }
    }

    return (
        <Modal show={ConfirmDeleteVisible} size="sm" className="tarology-modal" 
            animation={false} onHide={handleClose} data-testid="modal-confirm-delete-client">
        <Modal.Header className="bg-white" closeButton>
            <Modal.Title>Удалить расклад</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row id="subscribe-confirm-body" className='mt-5'>
                <Col>
                    <p>Вы действительно хотите удалить клиента <strong>{clientToDelete ? clientToDelete?.name : activeClient?.name} </strong>?</p>
                </Col>
            </Row>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">{toast?.title}</strong>
                </Toast.Header>
                <Toast.Body>{toast?.description}</Toast.Body>
            </Toast>
        </Modal.Body>
        <Modal.Footer>
            {isLoading ?
            <Button className="tarot-button" size="sm" disabled={true}>
                <Spinner 
                    as="span"
                    data-testid="tarot-button-spinner"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                /> Сохраняю...
            </Button>
            :
            <Button  className="tarot-sex-button" onClick={handleDelete} data-testid="modal-button-ok">
                Подтвердить
            </Button>}
            <Button className="tarot-red-button" onClick={handleClose} data-testid="modal-button-cancel">
                Отменить
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default ConfirmDeleteClientModal;