import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { tooltip } from './toolbar/ToolbarTooltip';
import { FaBars, /*FaRegQuestionCircle,*/ FaEllipsisV, FaBook } from "react-icons/fa";
import Toast from 'react-bootstrap/Toast';
import { BsPencilSquare } from 'react-icons/bs';
import DeckSelector from './control-panel/DeckSelector';
import DealTypeSelector from './control-panel/DealTypeSelector';
import CustomerSelector from './control-panel/CustomerSelector';
import DateSelector from './control-panel/DateSelector';
import { getActiveDeal, getQuestionsCount } from '../redux/deals/dealsSlice'; 
import { showHint, showQuestion, showComments } from '../redux/modal/modalSlice';
import { getActiveClient } from '../redux/client/clientSlice'; 
import { useGetDealTypeQuery } from '../api/dealTypeApi';
import { useGetPermissionsQuery } from '../api/accountApi';
import UndoRedoButton from './toolbar/UndoRedoButton';
import ExportPDFButton from './toolbar/ExportPDFButton';
import DeleteButton from './toolbar/DeleteButton';
import SaveButton from './toolbar/SaveButton';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ToastContent } from '../types/ToastContent';

const Toolbar = () => {
    const dispatch = useDispatch();

    const activeClient = useSelector(getActiveClient)    
    const deal = useSelector(getActiveDeal);
    const { data: dealType } = useGetDealTypeQuery(deal?.deal_type?.id, { skip: !deal?.deal_type?.id })
	const { data: permissions } = useGetPermissionsQuery()

    const questionsCount = useSelector(getQuestionsCount);
    const location = useLocation();
    const [toast, setToast] = useState<ToastContent>({});
    const [showToast, setShowToast] = useState(false);
    const [changeEnabled, setChangeEnabled] = useState(true);

    useEffect(
        () => {
            setChangeEnabled((deal?.deal_cards.filter(rec => rec.card !== undefined) || []).length == 0)
        }, [deal?.deal_cards]
    )

    const dealControlsActive = useCallback(() => {
        const section = location?.pathname?.split('/')[1]
        if (section == "deal") return true;
        return false; 
    }, [location?.pathname]);

    const getCommentsTip = useCallback(() => {
        let commentText = ""
        {deal?.comments?.map((comment, ix) => {
            if (ix > 0) {
                commentText = commentText + "|"
            }
            commentText = commentText + (ix + 1) + ". " + comment?.text
        })}

        return (
            commentText
        )
    }, [deal?.comments]);

    const questionButtonName = useCallback(() => {
        if (questionsCount > 0 && deal?.questions?.trim() != "") {
            if (deal?.questions && deal?.questions.length >= 16) {
                return deal?.questions?.substring(0,16) + "..."
            }
            return deal?.questions
        }
        return "Вопросы к раскладу"
    }, [deal, questionsCount]);

    return (
        <>
        <div className="toolbar">
        <div className="toolbar-burger">
            <div className="toolbar-button">
                {/* <a href="#"
                onclick = "document.getElementById('sidebar').classList.add('responsive');"> */}
                    <FaBars />
                {/* </a> */}
            </div>
        </div>
        <div className="tarology-logo">
            <img src="/image/tarology-dark.svg" />
        </div>
        <div id="toolbar-menu" className="toolbar-menu">
            <div className="toolbar-left">
                <DeckSelector 
                    permissions={permissions} 
                    disabled={!changeEnabled || !dealControlsActive()} 
                    setShowToast={setShowToast} 
                    setToast={setToast} 
                />
                <DealTypeSelector 
                    permissions={permissions} 
                    disabled={!changeEnabled || !dealControlsActive()} 
                    setShowToast={setShowToast} 
                    setToast={setToast} 
                />
                <CustomerSelector disabled={!dealControlsActive()}/>
                <DateSelector  disabled={!dealControlsActive()}/>
                <div className={`toolbar-button nonbadge ${!dealControlsActive() ? "disabled" : ""}`} 
                    onClick={() => dispatch(showQuestion({ position: "-1", index: -1 }))} 
                    data-testid="tarot-toolbar-questions">
                    <OverlayTrigger 
                        placement="bottom" 
                        overlay={tooltip(`${deal?.questions ? deal?.questions : "Вопросы к раскладу"}`)}>
                        <div>
                            {/* <FaRegQuestionCircle /> */}
                            {questionButtonName()}
                        </div>
                    </OverlayTrigger>
                    {/* <div className={`tarot-badge ${(deal?.questions && deal?.questions != "") ? "active" : "inactive"}`}>
                        {questionsCount > 0 ? questionsCount : ""}
                    </div>                    */}
                </div>
                <div className={`toolbar-button badge ${!dealControlsActive() ? "disabled" : ""}`} 
                    onClick={() => dispatch(showComments())} 
                    data-testid="tarot-toolbar-comments">
                    <OverlayTrigger placement="bottom" overlay={tooltip(`${deal?.comments?.length > 0 ? getCommentsTip() : "Добавить комментарий"}`)}>
                        <div>
                            <BsPencilSquare />
                        </div>
                    </OverlayTrigger>
                    <div className={`tarot-badge ${deal?.comments?.length > 0 ? "active" : "inactive"}`}>
                        { deal?.comments?.length > 0 && deal?.comments?.length }
                    </div>
                </div>
                <div className={`toolbar-button ${!(dealType?.hint && dealType?.hint != "") ? "disabled" : ""}`} 
                    onClick={() => dispatch(showHint())}
                    data-testid="tarot-toolbar-hint">
                    <OverlayTrigger placement="bottom" overlay={tooltip("Показать инструкцию к раскладу")}>
                        <div>
                            <FaBook />
                        </div>
                    </OverlayTrigger>
                </div>
            </div> 
            <div className="toolbar-right">
                <SaveButton deal={deal} path={location?.pathname} activeClient={activeClient}
                    setShowToast={setShowToast} setToast={setToast} />
                <UndoRedoButton type="undo" path={location?.pathname} />
                <UndoRedoButton type="redo" path={location?.pathname} />
                <ExportPDFButton deal={deal} path={location?.pathname} />
                <DeleteButton path={location?.pathname} activeClient={activeClient} deal={deal} />
            </div>
        </div>
        <div className="toolbar-ellipsis">
            <div className="toolbar-button">
                {/* <a href="#"
                onclick = "document.getElementById('toolbar-menu').classList.add('responsive');"> */}
                    <FaEllipsisV />
                {/* </a> */}
            </div>
        </div>
    </div>
    <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
        <Toast.Header>
            <strong className="me-auto">{toast?.title}</strong>
        </Toast.Header>
        <Toast.Body>{toast?.description}</Toast.Body>
    </Toast>
    </>
    )
}

export default Toolbar;