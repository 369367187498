import jwt_decode from 'jwt-decode';

interface Token {
    name: string;
    exp: number;
}

export const isTokenExpired = (token: string) => {
    try {
        const decoded: Token = jwt_decode(token);
        return (decoded.exp < Date.now() / 1000);
    }
    catch (err) {
        return false;
    }
}

export const formatDate = (dt: string | Date) => {
    const  d = new Date(dt);
    let result = "";
    const  monthsA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря"
    if (d.getDate() && d.getFullYear()) {
        result = d.getDate() + " " + monthsA.split(",")[d.getMonth()] + " " + d.getFullYear() + " г."
    }
    return result;
}

export const shiftDate = (dt: string | Date, delta: number) => {
    const  d = new Date(dt);
    d.setDate(d.getDate() + delta)
    let result = "";
    const  monthsA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря"
    if (d.getDate() && d.getFullYear()) {
        result = d.getDate() + " " + monthsA.split(",")[d.getMonth()] + " " + d.getFullYear() + " г."
    }
    return result;
}

export const formatDateTime = (dt: string | Date) => {
    const  d = new Date(dt);
    let result = "";
    const  monthsA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря"
    if (d.getDate() && d.getFullYear()) {
        result = d.getDate() + " " + monthsA.split(",")[d.getMonth()] + " " + d.getFullYear() + " " +
            d.getHours().toString().padStart(2, "0") + ":" + d.getMinutes().toString().padStart(2, "0");
    }
    return result;
}
