import { Arcane } from './Arcane';

const ref_arcanes = [
    "Король",
    "Королева",
    "Дама",
    "Принц",
    "Рыцарь",
    "Принцесса",
    "Паж",
    "Десятка",
    "Девятка",
    "Восьмерка",
    "Семерка",
    "Шестерка",
    "Пятерка",
    "Четверка",
    "Тройка",
    "Двойка",
    "Туз"
]

export const sortArcanes = (arcanes?: Arcane[]): Arcane[] => {
    const result: Arcane[] = [] 

    if (arcanes) {
        arcanes.map(arcane => {
            if (arcane.elder) {
                result.push(arcane)
            }
        })

        ref_arcanes.map(arcane => {
            const found = arcanes.find(rec => rec.title == arcane)
            if (found) {
                result.push(found)
            }
        })    
    }

    return result
}