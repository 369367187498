import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaAngleDown } from 'react-icons/fa';
import { getActiveDeal, getActiveExtra, setActiveExtra2 } from '../../redux/deals/dealsSlice';
import { useGetExtraDealTypesQuery } from '../../api/extraDealTypeApi';
import { useGetDealTypeQuery } from '../../api/dealTypeApi';
import { useGetDeckQuery } from '../../api/deckApi';
import * as utils from '../../helpers/utils';
import DealExtras from './DealExtras';
import DealCard from './DealCard';
import { Point } from '../../types/Geometry';

type Props = {
    state: ContextMenuState;
    setContextMenuState: (state: ContextMenuState) => void;
    setMenuCoords: (coords: Point) => void;
    height: number;
    width: number;
    expanded: boolean;
}

export type ContextMenuState = {
    visible: boolean;
    type: string;
    target: string | null;
    extra: number | null;
    extra_target: string | null;
}

export const DealTree = ({ state, setContextMenuState, setMenuCoords, height, width, expanded } : Props) => {    
    const dispatch = useDispatch();

    const deal = useSelector(getActiveDeal);
    const activeExtra = useSelector(getActiveExtra);
    const { data: dealType } = useGetDealTypeQuery(deal?.deal_type?.id, { skip: !deal?.deal_type?.id })
    const { data: deck } = useGetDeckQuery(deal?.deck?.id, { skip: !deal?.deck?.id })
    const { data: extraTypes } = useGetExtraDealTypesQuery()

    const upliftToDeal = useCallback(() => {
        dispatch(setActiveExtra2({ position: "-1", index: -1 }, null, width, height, expanded, false, deck?.long || false))
    }, [deck?.long, dispatch, expanded, height, width]);

    const handleTreeContext = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        setContextMenuState({
            visible: !state.visible,
            type: "global",
            target: null,
            extra: null,
            extra_target: null            
        })
        setMenuCoords({ x: e.clientX, y: e.pageY })
    }, [setContextMenuState, setMenuCoords, state.visible]);

    const tooltip = (message: string) => (
        <Tooltip id="tooltip" style={{ backgroundColor: "transparent" }}>
            {message}
        </Tooltip>
    );

    return (
        <div className='deal-tree' data-testid="tarot-deal-tree">
            <div className='tree-content'>
                <div className='tree-header'>
                    Структура расклада
                </div>
                <div className='body' data-testid="tarot-deal-tree-body">
                    {(deal?.deck?.id && deal?.deal_type?.id) ?
                        <div className='tree-level-0'>
                            <div className='tree-chevron'>
                                <FaAngleDown />
                            </div>
                            <div className='tree-line' data-testid="tarot-deal-tree-lines">
                                <OverlayTrigger placement="right" overlay={tooltip("Вернуться к основному раскладу")}>
                                    <div className='deal-tree-title' onClick={upliftToDeal} onContextMenu={e => handleTreeContext(e)}>
                                        <div className='deal-type'>{dealType?.title}</div>
                                        <div className='deal-type'>{deck?.title}</div>
                                        <div className='deal-client'>
                                            {(deal?.date && deal?.customer) ?
                                                `(${utils.formatDate(new Date(deal.date))}, ${deal?.customer?.name})`
                                                :
                                                deal?.date ?
                                                    `(${utils.formatDate(new Date(deal.date))})`
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                </OverlayTrigger>
                                <DealCard deal={deal} state={state} types={extraTypes || []} 
                                    activeExtra={activeExtra} dealType={dealType}
                                    setContextMenuState={setContextMenuState} 
                                    setMenuCoords={setMenuCoords} long={deck?.long}
                                    height={height} width={width} expanded={expanded} />
                                <DealExtras deal={deal} state={state} types={extraTypes || []} 
                                    activeExtra={activeExtra}
                                    setContextMenuState={setContextMenuState} 
                                    setMenuCoords={setMenuCoords} long={deck?.long}
                                    height={height} width={width} expanded={expanded} />
                            </div>
                        </div>
                        :
                        <></>
                        }
                </div>
            </div>
        </div>
    )
}

export default DealTree;