/* eslint-disable @typescript-eslint/no-unused-vars */
import { apiSlice } from '../redux/apiSlice';
import { DealType } from '../types/DealType';

const enhancedApi = apiSlice.enhanceEndpoints({
  addTagTypes: ["DealTypes"],
});

const dealTypeApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getDealTypes: builder.query<DealType[], void>({
        query: () => '/deal-types',
        transformResponse: (response: DealType[], meta, arg) => response.sort((a, b) => (a.title > b.title) ? 1 : -1),
        providesTags: (result, error, id) => [{ type: 'DealTypes', id: 'LIST' }],
        keepUnusedDataFor: 36000
    }),
    getDealTypesByDeck: builder.query<DealType[], string | undefined>({
        query: deckId => `/deal-types/by_deck=${deckId}`,
        transformResponse: (response: DealType[], meta, arg) => response.sort((a, b) => (a.title > b.title) ? 1 : -1),
        providesTags: (result, error, systemName) => [{ type: 'DealTypes', id: 'LIST' }],
        keepUnusedDataFor: 36000
    }),
    getDealType: builder.query<DealType, string | undefined>({
        query: dealTypeId => `/deal-types/${dealTypeId}`,
        providesTags: (result, error, id) => [{ type: 'DealTypes', id }],
        keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetDealTypeQuery, useGetDealTypesQuery, useGetDealTypesByDeckQuery } = dealTypeApi