import { Suite } from "./Suite"

const ref_suites = [
    'Скипетров', 
    'Жезлов', 
    'Кубков', 
    'Чаш', 
    'Мечей', 
    'Пентаклей', 
    'Дисков'
]

export const sortSuites = (suites?: Suite[]): Suite[] => {
    const result: Suite[] = [] 

    if (suites) {
        ref_suites.map(suite => {
            const found = suites.find(rec => rec.title == suite)
            if (found) {
                result.push(found)
            }
        })    
    }

    return result
}