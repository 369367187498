import React, { useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { tooltip } from '../toolbar/ToolbarTooltip';
import { FaRegSave } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { getAvaChanged, getAvaEditMode, getProfileUndoQueueSize, 
    selectAccount, setAvaChanged, setUserAvatar, switchAvaEditMode} from '../../redux/settings/settingsSlice';
import { useHistory } from 'react-router-dom';
import { activeClientLoaded, getClientUndoQueueSize, setEditModeOff } from '../../redux/client/clientSlice';
import { getDealUndoQueueSize } from '../../redux/deals/dealsSlice';
import { useAddCustomerMutation, useUpdateCustomerMutation } from '../../api/clientApi';
import { useAddDealMutation, useUpdateDealMutation } from '../../api/dealApi';
import { useUpdateSelfProfileMutation } from '../../api/accountApi';
import { Client } from '../../types/Client';
import { Deal } from '../../types/Deal';
import { ActionCreators } from 'redux-undo';
import { ToastContent } from '../../types/ToastContent';
import { User } from '../../types/Account';

type Props = {
    path: string;
    activeClient?: Client;
    deal?: Deal;
    setShowToast: (visible: boolean) => void;
    setToast: (toast: ToastContent) => void;
}

const SaveButton = ({ path, activeClient, deal, setShowToast, setToast }: Props) => {
    const dispatch = useDispatch();
    const account = useSelector(selectAccount);
    const history = useHistory();
    const profileAvaEditMode = useSelector(getAvaEditMode);
    const avaChanged = useSelector(getAvaChanged);

    const dealUndoQueue = useSelector(getDealUndoQueueSize);
    const clientUndoQueue = useSelector(getClientUndoQueueSize);
    const profileUndoQueue = useSelector(getProfileUndoQueueSize);

    const [updateCustomer] = useUpdateCustomerMutation()
    const [addCustomer] = useAddCustomerMutation()
    const [updateDeal] = useUpdateDealMutation()
    const [addDeal] = useAddDealMutation()
    const [updateSelfProfile] = useUpdateSelfProfileMutation()

    const saveActive = useCallback(() => {
        const section = path?.split('/')[1]
        switch (section) {
            case "clients":
                return true
            case "client":
                return clientUndoQueue != 0
            case "deal":
                return (dealUndoQueue != 0 && deal?.deal_type && deal?.customer?.name && deal?.deck)
            case "profile":
                return profileUndoQueue != 0
            default:
                return false
        }
    }, [clientUndoQueue, deal?.customer?.name, deal?.deal_type, deal?.deck, dealUndoQueue, path, profileUndoQueue]);

    const handleSaveClient = useCallback(async () => {
        console.log("Save client", activeClient)
        if (activeClient?.id && activeClient?.id != "0") {
            try {
                await updateCustomer(activeClient).unwrap()
                dispatch(setEditModeOff());
            } catch (e) {
                setToast({
                    title: 'Не получилось...',
                    description: "Не удалось сохранить клиента, пожалуйста попробуйте еще раз.",
                    status: 'error'
                })
              setShowToast(true)
            }
        } else {
            if (activeClient) {
                try {
                    const res = await addCustomer(activeClient).unwrap()
                    history.push(`/client/${res.id}`)
                    dispatch(activeClientLoaded(res))
                    dispatch(setEditModeOff());
                } catch {
                  setToast({
                    title: 'Не получилось...',
                    description: "Не удалось создать клиента, пожалуйста попробуйте еще раз.",
                    status: 'error'
                  })
                  setShowToast(true)
                }    
            }
        }
        dispatch(ActionCreators.clearHistory()) 
    }, [activeClient, addCustomer, dispatch, history, setShowToast, setToast, updateCustomer]);

    const handleSaveDeal = useCallback(async () => {
        if (deal?.id != "-1") {
            try {
                await updateDeal(deal).unwrap()
            } catch (e) {
                setToast({
                    title: 'Не получилось...',
                    description: "Не удалось сохранить расклад, пожалуйста попробуйте еще раз.",
                    status: 'error'
                })
              setShowToast(true)
            }
        } else {
            try {
                const res = await addDeal(deal).unwrap()
                history.push(`/deal/${res.id}`)
            } catch {
              setToast({
                title: 'Не получилось...',
                description: "Не удалось создать расклад, пожалуйста попробуйте еще раз.",
                status: 'error'
              })
              setShowToast(true)
            }
        }
        dispatch(ActionCreators.clearHistory()) 
    }, [addDeal, deal, dispatch, history, setShowToast, setToast, updateDeal]);

    const handleSaveProfile = useCallback(async () => {
        const changed = avaChanged
        if (profileAvaEditMode) {
            dispatch(setUserAvatar(null))
            dispatch(setAvaChanged(false))
            dispatch(switchAvaEditMode())    
        }
        if (account) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { avatar, avatarAlt, ...rest } = account;
                const payload: User = { ...rest }
                if (changed) {
                    payload.avatarAlt = avatarAlt
                }
                await updateSelfProfile(payload).unwrap()
            } catch {
                setToast({
                title: 'Не получилось...',
                description: "Не удалось загрузить аватар, пожалуйста попробуйте еще раз.",
                status: 'error'
                })
                setShowToast(true)
            }    
        }
        setAvaChanged(false);
        dispatch(ActionCreators.clearHistory()) 
    }, [account, avaChanged, dispatch, profileAvaEditMode, setShowToast, setToast, updateSelfProfile]);

    const handleSaveButton = useCallback(() => {
        const section = path?.split('/')[1]
        switch (section) {
            case "client":
                handleSaveClient()
                break;
            case "deal":
                handleSaveDeal()
                break;
            case "profile":
                handleSaveProfile()
                break;
        }
    }, [handleSaveClient, handleSaveDeal, handleSaveProfile, path]);
    
    return (
        <div className={`toolbar-button ${!saveActive() ? "disabled" : ""}`} 
                onClick={() => handleSaveButton()}
                data-testid="tarot-toolbar-save">
            <OverlayTrigger placement="bottom" overlay={tooltip("Сохранить")}>
                <div>
                    <FaRegSave />
                </div>
            </OverlayTrigger>
        </div>
    );    
}

export default SaveButton;