import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import Spinner from 'react-bootstrap/esm/Spinner';
import { getActiveDeal, setActiveDealCustomer } from '../../redux/deals/dealsSlice';
import { useGetCustomersQuery } from '../../api/clientApi';

import 'react-bootstrap-typeahead/css/Typeahead.css';

type Props = {
    disabled: boolean;
}

const CustomerSelector = ({ disabled }: Props) => {
    const dispatch = useDispatch();
    const { data: clients, isSuccess: isClientsSuccess } = useGetCustomersQuery({ sort: "name:asc", paging: null })
    const deal = useSelector(getActiveDeal);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSetClient = useCallback((e: string | any[] | undefined) => {
        if (e !== undefined) {
            if (e.length == 0) {
                dispatch(setActiveDealCustomer({ name: "" }));
            } else {
                const tmpClient = e[0]
                if (tmpClient?.customOption == true) {
                    tmpClient.id = "-1"
                    dispatch(setActiveDealCustomer(tmpClient));
                } else {
                    dispatch(setActiveDealCustomer(tmpClient));
                }
            }
        }
    }, [dispatch]);

    const showSelectedClient = useCallback(() => {
        if (deal?.customer?.id) return [deal?.customer]
        return []
    }, [deal]);

    return (
        <div className="tarot-deal-client" data-testid="tarot-deal-client" style={{ maxHeight: "40px" }}>
            {isClientsSuccess ?
                <Typeahead
                    id="basic-typeahead-single"
                    disabled={disabled}
                    newSelectionPrefix="Новый клиент: "
                    labelKey="name"
                    onChange={handleSetClient}
                    options={clients?.entities || []}
                    placeholder="Клиент"
                    allowNew={true}
                    selected={showSelectedClient()}
                    className="mb-3"
                />
                :
                <div id="tarology-spinner" data-testid="tarology-spinner">
                    <Spinner animation="border" />
                </div>
            }
        </div>
    )
}

export default CustomerSelector;