import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getAlert, hideAlert } from '../redux/modal/modalSlice';

const Alert = () => {
    const dispatch = useDispatch();
	const alert = useSelector(getAlert)

    const handleClose = () => {
        dispatch(hideAlert())
    }

    return (
        <Modal show={alert?.visible} size="sm" className="tarology-modal" 
            animation={false} onHide={handleClose} data-testid="modal-alert">
        <Modal.Header className="bg-white" closeButton>
            <Modal.Title>Предупреждение</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row id="subscribe-confirm-body" className='mt-5'>
                <Col>
                    <p>{alert.message}</p>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button  className="tarot-sex-button" onClick={handleClose} data-testid="modal-button-ok">
                Ok
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default Alert;