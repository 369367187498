import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortUpdated, getSortBy } from '../../redux/client/clientSlice';
import { BiSortAlt2, BiSortUp, BiSortDown } from "react-icons/bi";
import Button from 'react-bootstrap/Button';

import '../../scss/custom.scss';
import '../../css/nkp.css';

type Props = {
    field: string;
}

const SortButton = ({ field }: Props) => {
    const sorts = useSelector(getSortBy);
    const sort = sorts.find(sort => sort.field == field);
    const dispatch = useDispatch();

    const changeSort = useCallback(() => {
        switch(sort?.order) {
            case "asc":
                dispatch(sortUpdated({ field: field, order: "desc" }));
                break;
            case "desc":
                dispatch(sortUpdated({ field: field, order: "none" }));
                break;
            default:
                dispatch(sortUpdated({ field: field, order: "asc" }));
        }
    }, [dispatch, field, sort?.order]);

    const SortIcon = useCallback((order?: string) => {
        switch(order) {
            case "asc":
                return <BiSortUp/>
            case "desc":
                return <BiSortDown />
            default:
                return <BiSortAlt2 />
        }
    }, []);

    const SortTitle = useCallback((field?: string) => {
        switch(field) {
            case "date":
                return "Сортировать по дате"
            case "deal_type":
                return "Сортировать по раскладу"
            default:
                return "Иная сортировка"
        }
    }, []);

    return (
        <Button className="tarot-white-button mr-3" data-testid='tarot-sort-button' size="sm" onClick={changeSort}>
            { SortIcon(sort?.order) }
            { SortTitle(sort?.field) }
        </Button>    
    )
}

export default SortButton;