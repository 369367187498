import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideHint, getHint } from '../../redux/modal/modalSlice';
import ReactMarkdown from 'react-markdown'
import { blockquoteRenderer } from '../../helpers/blockquote-renderer';
import Floating from './Floating';

type Props = {
    hint?: string;
}

const DealHint = ({ hint }: Props) => {
    const dispatch = useDispatch();
    const hintVisible = useSelector(getHint)

    return (
        <>
            {hintVisible.visible == true &&
                <Floating close={() => dispatch(hideHint())} title="Подсказка"
                    dataTestid="tarot-floating-hint">
                    <ReactMarkdown className="tarot-hint-content" components={{ 
                        h2: 'h3', 
                        blockquote: ({node}) => { return(blockquoteRenderer(node)) }
                    }}
                    // eslint-disable-next-line react/no-children-prop
                    children={hint || ""} />
                </Floating>
            }
        </>
    )
}


export default DealHint;