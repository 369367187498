import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pageSizeSet, selectAllSorts, sortClientAdded, 
    sortClientUpdated, sortClientRemoved, selectSortParams, 
    selectPaging, getPageSize } from '../redux/client/clientSlice';
import { FaMars, FaVenus, FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { LuPin, LuPinOff } from 'react-icons/lu';
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pages from './Pages';
import { useGetCustomersQuery } from '../api/clientApi';
import { getExpanded } from '../redux/modal/modalSlice';

type Props = {
    // eslint-disable-next-line react-redux/no-unused-prop-types
    sex: string;
}

const Clients = () => {
    const expanded = useSelector(getExpanded);
    const dispatch = useDispatch();
    const pageSize = useSelector(getPageSize) || 10;
    const sorts = useSelector(selectAllSorts)
    const sort = useSelector(selectSortParams);
    const paging = useSelector(selectPaging);

    const { data: customers, isSuccess: isCustomersSuccess } = useGetCustomersQuery({sort, paging})

    const handleSetPageSize = useCallback((e: { target: { value: string; }; }) => {
        dispatch(pageSizeSet(e.target.value))
    }, [dispatch]);

    const SexIcon = useCallback(({ sex }: Props) => {
        switch(sex) {
            case "male":
                return <FaMars />
            case "female":
                return <FaVenus />
            default:
                return ""
        }
    }, []);

    const handleChangeSort = useCallback((field: string) => {
        const current = sorts.find(rec => rec.field === field)
        if (current?.order == "asc") {
            dispatch(sortClientUpdated({ field: field, order: 'desc' }));
        } else if (current?.order == "desc") {
            dispatch(sortClientRemoved({ field: field }));
        } else {
            dispatch(sortClientAdded({ field: field, order: 'asc' }));   
        }
    }, [dispatch, sorts]);

    return (
        <div className={expanded ? "tarot-content-expanded" : "tarot-content"}>
            {isCustomersSuccess &&
            <div className='tarot-clients'>
                <div className='tarot-clients-table-bed'>
                <Table striped className='tarot-clients-table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="client-table-header" data-testid="client-thead-sort" 
                                onClick={() => handleChangeSort("name")}>
                                <span>Имя</span>
                                {sorts?.find(rec => rec.field === "name")?.order == "asc" ?
                                <FaSortUp /> : 
                                sorts?.find(rec => rec.field === "name")?.order == "desc" ?
                                <FaSortDown /> : <FaSort />}
                            </th>
                            <th>Пол</th>
                            <th>Закреплен</th>
                            <th>E-mail</th>
                            <th>Телефон</th>
                        </tr>
                    </thead>
                    <tbody>
                    {customers.entities.map((client, index) => {
                        return(
                        <tr key={index} data-testid="tarot-client-trow">
                            <td>{index + 1}</td>
                            <td data-testid="tarot-client-tname">
                                <Link to={`/client/${client.id}`}>
                                    {client.name}
                                </Link>
                            </td>
                            <td data-testid="tarot-client-tsex"><center><SexIcon sex={client.sex || ""} /></center></td>
                            <td data-testid="tarot-client-tfav"><center>{client.favorite ? <LuPin/> : <LuPinOff/>}</center></td>
                            <td data-testid="tarot-client-tmail">{client.email}</td>
                            <td data-testid="tarot-client-tphone">{client.phone}</td>
                        </tr>
                        )}
                    )}
                    </tbody>
                </Table>
                </div>

                <Row className="d-flex tarot-clients-paging">
                    <Col className="my-2 entity-footer">
                        <select name="items" id="items" value={pageSize} onChange={handleSetPageSize} 
                            className="entity-items-per" data-testid="tarot-page-size-selector">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select> записей на странице
                    </Col>

                    <Col className="entity-button-place-right d-flex">
                        <Pages page={customers.page} totalPages={customers.total_pages} />
                    </Col>
                </Row>
            </div>}
        </div>
    );
}

export default Clients;