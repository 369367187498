/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

type handlersOptions = {
  [key: string]: () => void;
}

const useKeyPress = (handlers: handlersOptions = {}, visible = false, dependencies = []) => {
  useEffect(() => {
    const onKeyUp = (event: { repeat: boolean; code: string; }) => {
      if (!event.repeat && visible) {
        handlers[event.code]?.();
        // console.log(event)
      }
    };

    document.addEventListener("keyup", onKeyUp);

    return () => document.removeEventListener("keyup", onKeyUp);
  }, [handlers, visible, ...dependencies]);
};

export default useKeyPress;