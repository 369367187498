/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk, createEntityAdapter,
  PayloadAction, EntityId, Dictionary} from '@reduxjs/toolkit';
import prepareHeaders from '../../helpers/prepare-headers';
import { fillGeometry, resetGeometry } from '../geometry/geometrySlice';
import { Comment } from '../../types/Comment';
import { AppStore, RootState } from '../store';
import { ExtraDeal } from '../../types/ExtraDeal';
import { ExtraDealType } from '../../types/ExtraDealType';
import { CardRef } from '../../types/CardRef';
import { Deal } from '../../types/Deal';
import { ExtraDef } from '../../types/ExtraDef';
import { DealType, DealCard } from 'src/types/DealType';
import * as geo from '../../helpers/geometry';

const dealsAdapter = createEntityAdapter<Deal>({})

export interface DealState {
  status: string;
  error: string | null;
  newdeal: Deal | null;
  newDealAction: boolean;
  newGlobal: boolean;
  activeDeal: Deal;
  activeExtra: ExtraDef;
  commentsEditable: Array<number>;
  confirmDealDeleteVisible: boolean;
  dealToDelete?: Deal | null;
  updateStatus: string;
  downloadStatus: string;
  downloadId?: string | null;
  ids: EntityId[];
  entities: Dictionary<Deal>;
}

const initialState: DealState = dealsAdapter.getInitialState ({
    status: 'idle',
    error: null,
    newdeal: {
      comments: [],
      deal_cards: [],
      extra_deals: []
    },
    newDealAction: false,
    newGlobal: false,
    activeDeal: {
      comments: [],
      deal_cards: [] as Array<CardRef>,
      extra_deals: [],
      limit: 0
    },
    activeExtra: {
      position: "-1",
      index: -1
    },
    commentsEditable: [],
    confirmDealDeleteVisible: false,
    dealToDelete: null,
    updateStatus: 'idle',
    downloadStatus: 'idle',
    downloadId: null
})

const { TAROT_API_URL } = process.env;

export const getDealPDFFromApi = createAsyncThunk('deals/getPDF', async (payload: string, { rejectWithValue }) => {
  const dealId = payload;
  try {
    const response = await fetch(`${TAROT_API_URL}/reports/deal/${dealId}`, {
      method: "GET",
      headers: prepareHeaders(localStorage.getItem('accessToken'))
    })
    .then(res => {
      return res.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Deal_${dealId}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })

    return response
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response)
  } 
})

export const setActiveDeal2 = (deal: Deal, width: number, height: number, 
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expanded: boolean, keepCards: boolean, long: boolean = false) => (dispatch: AppStore['dispatch'], getState: any) => {
  // const extra = getState().deals.activeExtra;
  dispatch(setActiveDeal(deal))
  if (deal?.deal_type) {
    console.log("Set active deal type", deal.deal_type)
    dispatch(setActiveDealType2(deal?.deal_type, width, height, expanded, keepCards, long))
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setActiveExtra2 = (extra: ExtraDef, extra_deal_type: ExtraDealType | null, width: number, height: number, 
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expanded: boolean, keepCards: boolean, long: boolean = false) => (dispatch: AppStore['dispatch'], getState: any) => {
    if (extra.position == "-1" && extra.index == -1) {
    dispatch(setActiveExtra(extra))
    const dealType = getState().deals.present.activeDeal.deal_type;
    dispatch(setActiveDealType2(dealType, width, height, expanded, keepCards, long))
  } else {
    if (extra_deal_type) {
      dispatch(resetGeometry()); 
      dispatch(setActiveExtra(extra))
      let existingExtra  
      if (extra.position == "-1") {
        if (extra.index || extra.index == 0) {
          existingExtra = getState().deals.present.activeDeal.extra_deals[extra.index]
        }
      } else {
        if (extra.position) {
          if (extra.index || extra.index == 0) {
            const card = getState().deals.present.activeDeal.deal_cards.find((rec: DealCard) => rec.position == extra.position)
            existingExtra = card?.extra_deals[extra.index]
          }  
        }
      }
      let limit
      if (existingExtra) {
        limit = existingExtra.limit
      } else {
        limit = extra_deal_type.initialLimit || extra_deal_type?.deal_cards?.length || 0;
      }
      dispatch(increaseLimit2(extra, extra_deal_type, (limit || 0), width, height, expanded, long))
      dispatch(refreshDeal(extra_deal_type, limit, width, height, expanded, keepCards, long))  
    }
  }
}

export const setActiveDealType2 = (dt: DealType | ExtraDealType | null, width: number, height: number, 
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  expanded: boolean, keepCards: boolean, long: boolean = false) => (dispatch: AppStore['dispatch'], getState: any) => {
  const extra = getState().deals.activeExtra;
  dispatch(resetGeometry()); 
  dispatch(setActiveDealType(dt));
  const limit = dt?.initialLimit || dt?.deal_cards?.length || 0;
  dispatch(increaseLimit2(extra, dt, (limit || 0), width, height, expanded, long))
  dispatch(refreshDeal(dt, limit, width, height, expanded, keepCards, long))
  console.log("Set active deal type 2", dt, limit)
}

export const refreshDeal = (dealType: DealType | ExtraDealType | null | undefined, limit: number | undefined, width: number, 
        height: number, expanded: boolean, keepCards: boolean, long: boolean = false) => (dispatch: AppStore['dispatch']) => {
  if (dealType && dealType.deal_cards) {
    const tmpGeometry = geo.calculatePositions(dealType.deal_cards?.slice(0, limit || 0), 
        width, height, expanded, long);
    console.log("Fill geometry", tmpGeometry)
    dispatch(fillGeometry({ geometry: tmpGeometry, keepCards: keepCards }));
  }

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const increaseLimit2 = (extra: ExtraDef, type: DealType | ExtraDealType | null, value: number | null, width: number, 
      height: number, expanded: boolean, long: boolean = false) => (dispatch: AppStore['dispatch'], getState: () => RootState) => {
  const maxLimit = type?.deal_cards?.length
  const deal = getState().deals.present.activeDeal
  let limit = 0
  if (extra?.position == '-1') {
    if (extra?.index || extra?.index == 0) {
      if (extra?.index == -1) {
        const target = ((deal?.limit || 0) + 1) <= (maxLimit || 0) ? (deal?.limit || 0) + 1 : deal.limit;
        limit = (value && value <= (maxLimit || 0)) ? value : target || 0;
        dispatch(setActiveDealLimit(limit))
      } else {
        const  target = ((deal?.extra_deals[extra?.index].limit || 0) + 1) <= (maxLimit || 0) ? 
                      (deal?.extra_deals[extra?.index].limit || 0) + 1 : 
                      deal.extra_deals[extra?.index].limit;
        limit = (value && value <= (maxLimit || 0)) ? value : target;
        // dispatch(setActiveDealLimit(limit))
        dispatch(setActiveExtraLimit(limit))
      }  
    }
  } else {
    if (deal?.deal_cards) {
      if (extra?.index || extra?.index == 0) {
        const target: number | undefined = ((deal?.deal_cards
          .find(rec => rec.position == extra?.position)?.extra_deals[extra?.index].limit || 0) + 1) <= (maxLimit || 0) ? 
          (deal?.deal_cards
          .find(rec => rec.position == extra?.position)?.extra_deals[extra?.index].limit || 0) + 1 : 
          deal.deal_cards.find(rec => rec.position == extra?.position)?.extra_deals[extra?.index].limit;
        limit = ((value && value <= (maxLimit || 0)) ? value : target || 0);
        // dispatch(setActiveDealLimit(limit))
        dispatch(setActiveExtraLimit(limit))
      }
    }
  }
  dispatch(refreshDeal(type, limit, width, height, expanded, true, long))
}

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    dealAdded(state, action: PayloadAction<Deal>) {
      dealsAdapter.addOne(state, action.payload);
    },
    setActiveDeal(state, action) {
      state.activeDeal = action.payload
    }, 
    setActiveDealType(state, action) {
      state.activeDeal.deal_type = action.payload
    },
    setActiveDealDate(state, action) {
      state.activeDeal.date = action.payload
    },
    setActiveDealQuestion(state, action) {
      state.activeDeal.questions = action.payload.slice(0, 2000)
    },
    setActiveExtraQuestion(state, action) {
      const { extra, question } = action.payload
      if (extra?.position == "-1") {
        state.activeDeal.extra_deals[extra?.index].question = question.slice(0, 2000)
      } else {
        if (state.activeDeal.deal_cards.find(rec => rec.position == extra?.position)) {
          state.activeDeal.deal_cards.find(rec => rec.position == extra?.position)!.extra_deals[extra?.index].question = question.slice(0, 2000)
        }
      }
    },
    setActiveDealCustomer(state, action) {
      state.activeDeal.customer = action.payload
    },
    setActiveDealLimit(state, action) {
      state.activeDeal.limit = action.payload
    },
    resetActiveDeal(state, action: PayloadAction<void>) {
      const currentDeal = state.activeDeal;
      currentDeal.comments = [];
      currentDeal.customer = {};
      const tmpDate = new Date();
      currentDeal.date = tmpDate.toJSON();
      currentDeal.deal_type = null;
      currentDeal.deck = null;
      currentDeal.deal_cards = [];
      currentDeal.id = "-1";
      currentDeal.questions = "";
      currentDeal.extra_deals = [];
    },
    setActiveDealDeck(state, action) {
      state.activeDeal.deck = action.payload.deck
    },
    increaseLimit(state, action) {
      const { position, index, type, value } = action.payload;
      const  maxLimit = type.deal_cards.length
      if (position == -1) {
        if (index == -1) {
          const  target = ((state?.activeDeal?.limit || 0) + 1) <= maxLimit ? 
                        (state?.activeDeal?.limit || 0) + 1 : 
                        state.activeDeal.limit;
          state.activeDeal.limit = (value && value <= maxLimit) ? value : target;
        } else {
          const  target = ((state?.activeDeal?.extra_deals[index].limit || 0) + 1) <= maxLimit ? 
                        (state?.activeDeal?.extra_deals[index].limit || 0) + 1 : 
                        state.activeDeal.extra_deals[index].limit;
          state.activeDeal.extra_deals[index].limit = (value && value <= maxLimit) ? value : target;
        }
      } else {
        const  target = ((state?.activeDeal?.deal_cards.find(rec => rec.position == position)?.extra_deals[index].limit || 0) + 1) <= maxLimit ? 
                      (state?.activeDeal?.deal_cards.find(rec => rec.position == position)?.extra_deals[index].limit || 0) + 1 : 
                      state.activeDeal.deal_cards.find(rec => rec.position == position)?.extra_deals[index].limit;
        if (state.activeDeal.deal_cards.find(rec => rec.position == position)) {
          state.activeDeal.deal_cards.find(rec => rec.position == position)!.extra_deals[index].limit = (value && value <= maxLimit) ? value : target;
        }
      }
    },
    addCommentEditable(state, action: PayloadAction<number>) {
      const  currentCommentsEditable = state.commentsEditable
      currentCommentsEditable.push(action.payload)
    },
    removeCommentEditable(state, action: PayloadAction<number>) {
      state.commentsEditable = state.commentsEditable.filter(rec => rec != action.payload)
    },
    addComment(state, action: PayloadAction<Comment>) {
      const  currentComments = state.activeDeal.comments;
      currentComments.push(action.payload)
    },
    updateComment(state, action: PayloadAction<Comment>) {
      const  currentComment = state.activeDeal.comments.find(rec => rec.id == action.payload.id) || {} as Comment;
      currentComment.text = action.payload.text
    },
    removeComment(state, action) {
      state.activeDeal.comments = state.activeDeal.comments.filter(rec => rec.id != action.payload.id);
    },
    setDealToDetele(state, action) {
      state.dealToDelete = action.payload;
    },
    fillPosition(state, action) {
      const {position, card, extra} = action.payload;
      if (extra?.position != -1 || extra?.index != -1) {
        const  newCard = {} as CardRef
        newCard.card = card
        newCard.inverted = false
        newCard.position = position
        if (extra?.position == -1) {
          const  tmp1: Array<CardRef> = state.activeDeal.extra_deals[extra.index]
                                .extra_deal_cards.filter(rec => rec.position != position)
          tmp1.push(newCard)
          state.activeDeal.extra_deals[extra.index].extra_deal_cards = tmp1
        } else {
          const  deal_cards = state.activeDeal.deal_cards
          const  the_card = deal_cards.find(rec => rec.position == extra?.position)
          const  extra_deals = the_card?.extra_deals
          if (extra_deals) {
            const  the_extra = extra_deals[extra.index]
            if (the_extra) {
              const  extraDCs = the_extra.extra_deal_cards.filter(rec => rec.position != position)
              extraDCs.push(newCard)
              the_extra.extra_deal_cards = extraDCs
              the_card!.extra_deals[extra.index] = the_extra
              const  tmp_cards = deal_cards.filter(rec => rec.position != extra?.position)
              tmp_cards.push(the_card!)
              state.activeDeal.deal_cards = tmp_cards
            }
          }
        }
      } else {
        const  newCard = {} as CardRef
        newCard.card = card
        newCard.inverted = false
        newCard.position = position
        newCard.extra_deals = []
        const  tmp: Array<CardRef> = state.activeDeal.deal_cards.filter(rec => rec.position != position)
        tmp.push(newCard)
        state.activeDeal.deal_cards = tmp
      }
    },
    invertPosition(state, action) {
      const {position, extra, extra_position} = action.payload
      if ((!extra && extra != 0) || extra == -1) {
        const  newState = !state.activeDeal.deal_cards.find(rec => rec.position == position)?.inverted
        state.activeDeal.deal_cards.find(rec => rec.position == position)!.inverted = newState
      } else {
        if (extra_position == -1) {
          const  newState = !state.activeDeal.extra_deals[extra].extra_deal_cards.find(rec => rec.position == position)?.inverted
          state.activeDeal.extra_deals[extra].extra_deal_cards.find(rec => rec.position == position)!.inverted = newState  
        } else {
          const  newState = !state.activeDeal.deal_cards
                        .find(rec => rec.position == extra_position)?.extra_deals[extra].extra_deal_cards
                        .find(rec => rec.position == position)?.inverted
          state.activeDeal.deal_cards
                        .find(rec => rec.position == extra_position)!.extra_deals[extra].extra_deal_cards
                        .find(rec => rec.position == position)!.inverted = newState  
        }
      }
    },
    resetActiveExtra(state, action: PayloadAction<void>) {
      state.activeExtra.index = -1,
      state.activeExtra.position = "-1"
    },
    setActiveExtra(state, action) {
      state.activeExtra.index = action.payload.index,
      state.activeExtra.position = action.payload.position
    },
    addExtra(state, action) {
      const { position, type } = action.payload
      const  newExtra = {} as ExtraDeal
      newExtra.extra_deal_cards = []
      newExtra.extra_deal_type = type
      newExtra.limit = type.initialLimit || type.deal_cards.length
      if (position != -1) {
        if (type?.fixed) {
          const  card = state.activeDeal.deal_cards.find(rec => rec.position == position)
          const  fixedCard = {} as CardRef
          fixedCard.inverted = false
          fixedCard.position = type.fixed
          fixedCard.selected = false
          if (card) {
            fixedCard.card = {...card.card}
          }
          newExtra.extra_deal_cards.push(fixedCard)
        }
        const  target_card = state.activeDeal.deal_cards.find(rec => rec.position == position)
        target_card?.extra_deals.push(newExtra)
      } else {
        const  target = state.activeDeal.extra_deals
        target?.push(newExtra)
      }
    },
    setActiveExtraLimit(state, action) {
      const limit = action.payload
      const position = state.activeExtra.position
      const index = state.activeExtra.index
      if (state.activeExtra.position != "-1") {
        const card = state.activeDeal.deal_cards.find(rec => rec.position == position)
        if (card && (index || index == 0) && card.extra_deals[index]) {
          card.extra_deals[index].limit = limit
        }
      } else {
        if ((index || index == 0) && state.activeDeal.extra_deals[index]) {
          state.activeDeal.extra_deals[index].limit = limit
        }
      }
    },
    deleteExtra(state, action) {
      const { position, index } = action.payload
      if (position != -1) {
        let extra_deals = state.activeDeal.deal_cards.find(rec => rec.position == position)?.extra_deals;
        const  new_extras = extra_deals?.splice(index, 1);
        extra_deals = new_extras;
      } else {
        let extras = state.activeDeal.extra_deals
        const  new_extras = extras?.splice(index, 1)
        extras = new_extras;
      }
    },
    chooseExtraCard(state, action: PayloadAction<string>) {
      const position = state.activeExtra.position
      const index = state.activeExtra.index
      const posId = action.payload
      if (position == "-1") {
        if (index || index == 0) {
          const targetPosition = state.activeDeal.extra_deals[index].extra_deal_cards.find(rec => rec.position == posId)
          if (targetPosition) {
            targetPosition.selected = true;
          }
          const otherTargets = state.activeDeal.extra_deals[index].extra_deal_cards.filter(rec => rec.position != posId)
          otherTargets?.map(rec => rec.selected = false)
        }
      } else {
        if (index || index == 0) {
          const card = state.activeDeal.deal_cards.find(rec => rec.position == position)
          const targetPosition = card?.extra_deals[index].extra_deal_cards.find(rec => rec.position == posId)
          if (targetPosition) {
            targetPosition.selected = true;
          }
          const otherTargets = card?.extra_deals[index].extra_deal_cards.filter(rec => rec.position != posId)
          otherTargets?.map(rec => rec.selected = false)
        }
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getDealPDFFromApi.pending, (state, action) => {
        state.downloadStatus = 'active';
      })
      .addCase(getDealPDFFromApi.fulfilled, (state, action) => {
        state.downloadId = null;
        state.downloadStatus = 'idle';
      })
      .addCase('API_DEAL_FLAG_SET', (state, action) => {
        state.newDealAction = true
      })
      .addCase('API_DEAL_FLAG_REMOVE', (state, action) => {
        state.newDealAction = false
      })
  }
})

export const { dealAdded, setActiveDeal, setActiveDealDeck, setActiveDealCustomer, resetActiveExtra,
  setActiveDealType, setActiveDealDate, setActiveDealQuestion, resetActiveDeal, 
  addCommentEditable, removeCommentEditable, addComment, updateComment, chooseExtraCard, /*removeComment, */
  setDealToDetele, fillPosition, invertPosition, setActiveExtra, setActiveDealLimit,
  addExtra, deleteExtra, setActiveExtraQuestion, setActiveExtraLimit, increaseLimit } = dealsSlice.actions;

export default dealsSlice.reducer;

export const selectCommentsEditable = (state: RootState) => state.deals.present.commentsEditable;

export const selectDealToDelete = (state: RootState) => state.deals.present.dealToDelete;

export const getActiveDeal = (state: RootState) => state.deals.present.activeDeal;

export const getActiveExtra = (state: RootState) => state.deals.present.activeExtra;

export const getZeroDeal = (state: RootState) => 
{
  return (state.deals.present.entities[0])
};

export const getActiveQuestion = (state: RootState): string => {
  const extra = state.modals.question.extra
  if (extra.index == -1 && extra.position == "-1") {
    return state.deals.present.activeDeal.questions || ""
  } else {
    if (extra.position == "-1") {
      if (extra.index || extra.index == 0) {
        return state.deals.present.activeDeal.extra_deals[extra.index]?.question || ""
      }
    } else {
      const position = state.deals.present.activeDeal.deal_cards.find(rec => rec.position == extra.position)
      if (position) {
        if (extra.index || extra.index == 0) {
          return position!.extra_deals[extra.index]?.question || ""
        }
      }
    }
  }
  return ""
}

export const getQuestionsCount = (state: RootState): number => {
  let count = 0;
  if (state.deals.present.activeDeal.questions != null) { count = count + 1 }
  let extras: ExtraDeal[] = state.deals.present.activeDeal.extra_deals
  state.deals.present.activeDeal.deal_cards.map(card => extras = extras.concat(card.extra_deals))
  extras.map(ex => { if (ex.question != null) { count = count + 1 } })
  return count
}

export const getDealUndoQueueSize = (state: RootState) => state.deals.past.length; 

export const getDealRedoQueueSize = (state: RootState) => state.deals.future.length;

export const getDealExportStatus = (state: RootState) => state.deals.present.downloadStatus;

export const getExportId = (state: RootState) => state.deals.present.downloadId;

export const getNewDealStatus = (state: RootState) => state.deals.present.newdeal;

export const getNewDealFlag = (state: RootState) => state.deals.present.newDealAction;