import { apiSlice } from '../redux/apiSlice'

const enhancedApi = apiSlice.enhanceEndpoints({});

const authApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
        query: (credentials) => ({
          url: '/login',
          method: 'POST',
          body: credentials,
        }),
    }),
    logout: builder.mutation<void, void>({
        query: () => ({
          url: '/logout',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
          }
        }),
    }),
    register: builder.mutation({
        query: (credentials) => ({
          url: '/register',
          method: 'POST',
          body: credentials,
        }),
    }),
    confirm: builder.mutation({
        query: (token) => ({
          url: '/confirm',
          method: 'POST',
          body: token,
        }),
    }),
    rerequest: builder.mutation({
        query: (email) => ({
          url: '/rerequest',
          method: 'POST',
          body: email,
        }),
    }),
    reminder: builder.mutation({
        query: (email) => ({
          url: '/reminder',
          method: 'POST',
          body: email,
        }),
    }),
    confirmReminder: builder.mutation({
        query: (email) => ({
          url: '/confirm_reminder',
          method: 'POST',
          body: email,
        }),
    }),
    changePassword: builder.mutation({
        query: (payload) => ({
          url: '/set_password',
          method: 'POST',
          body: payload,
        }),
    }),
  }),
  overrideExisting: false,
})

export const { useLoginMutation, useLogoutMutation, useRegisterMutation,
    useConfirmMutation, useRerequestMutation, useReminderMutation,
    useConfirmReminderMutation, useChangePasswordMutation } = authApi