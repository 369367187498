import React, { useState, useEffect, useCallback } from 'react';
import { TbCards, TbArrowForwardUp, TbBook, TbTrash } from 'react-icons/tb';
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";

type Props = {
    visible: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
    x: number;
    y: number;
}

export const ContextMenu = ({ visible, children, x, y }: Props) => { 
    const [scrollTop, setScrollTop] = useState(0);
    const [lx, setLX] = useState(0)
    const [ly, setLY] = useState(0)

    useEffect(() => {
        fixEdges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, children, x, y, scrollTop])

    useEffect(() => {
        const handleScroll = () => {
          setScrollTop(window.scrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const fixEdges = useCallback(() => {
        const newY = y - scrollTop
        const newX = x
        setLY(newY)
        setLX(newX)
        // if ((newY + props.children.length * 50) > height) {
        //     setY(newY - (newY + props.children.length * 50 - height))
        // }
        // if (newY < 80) {
        //     setY(80)
        // }
        // if ((newX + 350) > width) {
        //     setX(newX - (newX + 350 - width))
        // }
    }, [scrollTop, x, y]);

    return (
        <div className={`tarot-context-menu ${visible == false && "hidden"}`}
            data-testid="tarot-context-menu"
            style={{ top: ly + "px" || "500px", left : lx + "px" || "500px" }}>
            {children}
        </div>
    )
}

type IconProps = {
    icon: string;
    size: number;
}

const ContextMenuIcon = ({ icon, size, ...rest }: IconProps) => {
    switch (icon) {
        case "cards":
            return <TbCards size={size} {...rest}/>
        case "book":
            return <TbBook size={size} {...rest}/>
        case "trash":
            return <TbTrash size={size} {...rest}/>
        case "turn":
            return <TbArrowForwardUp size={size} {...rest}/>
        case "question":
            return <HiOutlineChatBubbleBottomCenterText size={size} {...rest}/>
    }

    return ""
}

type ItemProps = {
    icon: string;
    title: string;
    handleOnClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ContextMenuItem = ({ icon, title, handleOnClick }: ItemProps) => {
    return (
        <div className='tarot-context-menu-item' onClick={handleOnClick} >
            <ContextMenuIcon icon={icon} size={20} />
            <span>{title}</span>
        </div>
    )
}

export const ContextMenuDivider = () => {
    return(
        <div className='tarot-context-menu-divider' ></div>
    )
}
