import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import prepareHeaders from '../../helpers/prepare-headers';
import { User, ApiToken/*, LocalAuthData*/ } from '../../types/Account';
// import { apiSlice } from '../apiSlice';
import { RootState } from '../store';
import { apiSlice } from '../apiSlice';

const { TAROT_API_URL, TAROT_PDF_URL } = process.env;

export interface SettingsState {
  status: string;
  authenticated: boolean;
  account: User | null;
  token: string | null;
  tarotApiUrl?: string;
  tarotPdfUrl?: string;
  editMode?: boolean;
  avaEditMode?: boolean;
  avaChanged?: boolean;
  od_status: string;
  od_error?: string | null; 
  changeInProgress: boolean;
  overdue: object,
  loading: boolean;
}

interface AuthData {
  apiToken?: ApiToken;
}

const initialState: SettingsState = {
    status: 'idle',
    authenticated: false,
    account: null,
    token: null,
    tarotApiUrl: TAROT_API_URL,
    tarotPdfUrl: TAROT_PDF_URL,
    editMode: false,
    avaEditMode: false,
    avaChanged: false,
    od_status: 'idle',
    od_error: null, 
    changeInProgress: false,
    overdue: {},
    loading: true,
}

export const fetchOverdue = createAsyncThunk('settings/fetchOverdue', async (authData: AuthData) => {
  const response = await fetch(`${TAROT_API_URL}/setting`, {
    method: "Get",
    headers: new Headers(prepareHeaders(authData.apiToken?.jwt))
  })
  .then(res => {
    return res.json();
  })
  return response
})

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setUser(state, action) {
      const newAccount = {...action.payload};
      if (state.account?.avatarAlt) {
        newAccount.avatarAlt = state.account.avatarAlt
      }
      state.account = newAccount;
    },
    setUserAvatar(state, action) {
      const avatar = action.payload;
      if (state.account) {
        state.account!.avatarAlt = avatar;
      }
    },
    setUserUsername(state, action) {
      const user_name = action.payload;
      const currentUser = state.account;
      if (currentUser) {
        currentUser.user_name = user_name;
      }
    },
    setUserCountry(state, action) {
      const country = action.payload;
      const currentUser = state.account;
      if (currentUser) {
        currentUser.country = country;
      }
    },
    setUserEmail(state, action) {
      const email = action.payload;
      const currentUser = state.account;
      if (currentUser) {
        currentUser.email = email;
      }
    },
    setUserGivenName(state, action) {
      const name = action.payload;
      const currentUser = state.account;
      if (currentUser) {
        currentUser.name = name;
      }
    },
    setUserFamilyName(state, action) {
      const surname = action.payload;
      const currentUser = state.account;
      if (currentUser) {
        currentUser.surname = surname;
      }
    },
    setUserPhone(state, action) {
      const phone = action.payload;
      const currentUser = state.account;
      if (currentUser) {
        currentUser.phone = phone;
      }
    },
    stopLoading(state) {
      state.loading = false;
    },
    setAuthenticated(state, action) {
      state.authenticated = action.payload;
    },
    switchEditMode(state) {
      state.editMode = !state.editMode
    },
    switchAvaEditMode(state, action: PayloadAction<void>) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const replaceAva = action.payload
    },
    setAvaChanged(state, action: PayloadAction<boolean>) {
      state.avaChanged = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOverdue.pending, (state) => {
        state.od_status = 'loading'
      })
      .addCase(fetchOverdue.fulfilled, (state, action) => {
        state.od_status = 'succeeded'
        state.overdue = action.payload
      })
      .addCase(fetchOverdue.rejected, (state, action) => {
        state.od_status = 'failed'
        state.od_error = action.error.message
      })
      // .addMatcher(apiSlice.endpoints.login.matchFulfilled, 
      // (state, { payload }) => {
      //   const data: LocalAuthData = payload as LocalAuthData
      //   localStorage.setItem('accessToken', data.access_token)
      //   localStorage.setItem('refreshToken', data.refresh_token)
      //   state.authenticated = true
      // })
      .addMatcher(apiSlice.endpoints.getSelfAccount.matchFulfilled, 
        (state, { payload }) => {
          const newAccount = {...payload as User};
          if (state.account?.avatarAlt) {
            newAccount.avatarAlt = state.account.avatarAlt
          }
          state.account = newAccount;
          // state.account = payload as User
        })
    }
})

export const { setUser, setUserAvatar, setUserEmail, setUserGivenName, 
  setUserFamilyName, setUserPhone, setUserCountry, setUserUsername, 
  stopLoading, setAuthenticated, switchEditMode,
  switchAvaEditMode, setAvaChanged } = settingsSlice.actions
  
export default settingsSlice.reducer

export const selectAvatar = (state: RootState) => {
  if (state.settings.present.account?.avatarAlt) {
    return `${state.settings.present.account?.avatarAlt}`;
  }

  if (state.settings.present.account?.avatar?.url) {
    return `${TAROT_API_URL}/medias/${state.settings.present.account?.avatar?.url}`;
  }

  return "/image/user.png";
}

export const selectAccount = (state: RootState) => state.settings.present.account;

export const selectIsAuthenticated = (state: RootState) => state.settings.present.authenticated;

export const getEditMode = (state: RootState) => state.settings.present.editMode;

export const getAvaEditMode = (state: RootState) => state.settings.present.avaEditMode;

export const getAvaChanged = (state: RootState) => state.settings.present.avaChanged;

export const getSettingsStatus = (state: RootState) => state.settings.present.status;

export const getOdStatus = (state: RootState) => state.settings.present.od_status;

export const getOverdue = (state: RootState) => state.settings.present.overdue;

export const getChangeInProgress = (state: RootState) => state.settings.present.changeInProgress;

export const getProfileUndoQueueSize = (state: RootState) => state.settings.past.length; 

export const getProfileRedoQueueSize = (state: RootState) => state.settings.future.length;
