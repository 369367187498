import { apiSlice } from '../redux/apiSlice'
import { Deal } from '../types/Deal';

interface ClientDealsRequest {
  clientId?: string;
  sort?: string;
}

const enhancedApi = apiSlice.enhanceEndpoints({
  addTagTypes: ["Deals"],
});

const dealApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeals: builder.query({
      query: () => '/deals',
      providesTags: () => [{ type: 'Deals', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getClientDeals: builder.query<Deal[], ClientDealsRequest>({
      query: ({clientId, sort}) => `/deals/customer=${clientId}?sort=${sort}`,
      providesTags: () => [{ type: 'Deals', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getDealsOfFavorites: builder.query({
      query: () => '/deals?favorites=true',
      providesTags: () => [{ type: 'Deals', id: 'FAVS' }],
      keepUnusedDataFor: 36000
    }),
    getDeal: builder.query({
      query: dealId => `/deals/${dealId}`,
      providesTags: (result, error, id) => [{ type: 'Deals', id }],
      keepUnusedDataFor: 36000
    }),
    addDeal: builder.mutation({
      query(data) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...body } = data;
        return {
          url: `/deals/`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Deals', id: 'FAVS' },
                        { type: 'Deals', id: 'LIST' }] 
    }),
    updateDeal: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/deals/${id}`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: (result, error, { id }) => [ { type: 'Deals', id }, 
                                                    { type: 'Deals', id: 'FAVS' },
                                                    { type: 'Deals', id: 'LIST' }],
    }),
    deleteDeal: builder.mutation<void, string>({
      query(id) {
        return {
          url: `/deals/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [ { type: 'Deals', id }, 
                                                { type: 'Deals', id: 'FAVS' },
                                                { type: 'Deals', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetClientDealsQuery, useGetDealsQuery, 
  useGetDealQuery, useGetDealsOfFavoritesQuery, useAddDealMutation,
  useUpdateDealMutation, useDeleteDealMutation } = dealApi
