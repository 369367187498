/* eslint-disable @typescript-eslint/no-unused-vars */
import { apiSlice } from '../redux/apiSlice'
import { Card } from '../types/Card';

const enhancedApi = apiSlice.enhanceEndpoints({
  addTagTypes: ["Cards"],
});

const cardApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getCards: builder.query<Card[], void>({
      query: () => '/cards',
      transformResponse: (response: Card[], meta, arg) => response.sort((a, b) => (a.title > b.title) ? 1 : -1),
      providesTags: (result, error, id) => [{ type: 'Cards', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getCardsByDeck: builder.query<Card[], string | undefined>({
      query: deckId => `/cards/deck=${deckId}`,
      transformResponse: (response: Card[], meta, arg) => response.sort((a, b) => (a.title > b.title) ? 1 : -1),
      providesTags: (result, error, id, deckId) => [{ type: 'Cards', id: `LIST_${deckId}` }],
      keepUnusedDataFor: 36000
    }),
    getCard: builder.query({
      query: cardId => `/cards/${cardId}`,
      providesTags: (result, error, id) => [{ type: 'Cards', id }],
      keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetCardsQuery, useGetCardQuery, useGetCardsByDeckQuery } = cardApi