// eslint-disable react-redux/connect-prefer-named-arguments
import React from 'react';
// const ReactDOM = require('react-dom');
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './scss/custom.scss';
import './css/modal.css';
import App from './App';
import store from './redux/store'
import { Provider } from 'react-redux'

// eslint-disable-next-line no-undef
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<BrowserRouter basename="/">
			<App />
		</BrowserRouter>
	</Provider>
);

// ReactDOM.render(
// 	<Provider store={store}>
// 		<BrowserRouter basename="/">
// 			<App />
// 		</BrowserRouter>
// 	</Provider>,
// 	document.getElementById('root')
// );
