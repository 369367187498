import React, { useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaUndo, FaRedo } from "react-icons/fa";
import { tooltip } from '../toolbar/ToolbarTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { getDealUndoQueueSize, getDealRedoQueueSize } from '../../redux/deals/dealsSlice'; 
import { getProfileRedoQueueSize, getProfileUndoQueueSize } from '../../redux/settings/settingsSlice';
import { getClientUndoQueueSize, getClientRedoQueueSize } from '../../redux/client/clientSlice'; 
import { ActionCreators } from 'redux-undo';

type Props = {
    type: string;
    path: string;
}

const UndoRedoButton = ({ type, path }: Props) => {
    const dispatch = useDispatch();

    const dealUndoQueue = useSelector(getDealUndoQueueSize);
    const dealRedoQueue = useSelector(getDealRedoQueueSize);
    const clientUndoQueue = useSelector(getClientUndoQueueSize);
    const clientRedoQueue = useSelector(getClientRedoQueueSize);
    const profileUndoQueue = useSelector(getProfileUndoQueueSize);
    const profileRedoQueue = useSelector(getProfileRedoQueueSize);

    const getQueues = useCallback((type: string) => {
        let section = path?.split('/')[1]
        switch (section) {
            case "clients":
                return true
            case "client":
                if (type == "undo") return clientUndoQueue == 0
                return clientRedoQueue == 0
            case "deal":
                if (type == "undo") return dealUndoQueue == 0
                return dealRedoQueue == 0
            case "profile":
                if (type == "undo") return profileUndoQueue == 0
                return profileRedoQueue == 0
            default:
                return false
        }
    }, [
        path, 
        clientUndoQueue, 
        clientRedoQueue, 
        dealUndoQueue, 
        dealRedoQueue, 
        profileUndoQueue, 
        profileRedoQueue
    ]);

    const getAction = useCallback((type: string) => {
        switch(type) {
            case "undo":
                return ActionCreators.undo()
            case "redo":
                return ActionCreators.redo()
            default:
                return ActionCreators.undo()
        }
    }, []);

    return (
        <div className={`toolbar-button ${getQueues(type) ? "disabled" : ""}`} onClick={() => dispatch(getAction(type))}>
            <OverlayTrigger placement="bottom" overlay={tooltip(type == "undo" ? "Отмена" : "Повтор")}>
                <div>
                    {
                        type == "undo" ?
                            <FaUndo />
                        :
                            <FaRedo />
                    }
                </div>
            </OverlayTrigger>
        </div>
    )
}

export default UndoRedoButton;