import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ContextMenu, ContextMenuItem, ContextMenuDivider } from './ContextMenu';
import { getActiveDeal, invertPosition, setActiveExtra2, addExtra, deleteExtra } from '../redux/deals/dealsSlice';
import { showQuestion } from '../redux/modal/modalSlice';
import { useGetDealTypeQuery } from '../api/dealTypeApi';
import { useGetExtraDealTypesQuery } from '../api/extraDealTypeApi';
import { useGetPermissionsQuery } from '../api/accountApi';
import { ContextMenuState } from '../types/ContextMenuState';
import { Article } from '../types/Articles';
import { ExtraDealType } from '../types/ExtraDealType';
import { ExtraDef } from '../types/ExtraDef';
import { CardRef } from '../types/CardRef';
import { Point } from '../types/Geometry';

type Props = {
    state: ContextMenuState; 
    setState: (state: ContextMenuState) => void; 
    setTheCard: (id: string) => void; 
    setWheelVisible: (wv: boolean) => void;
    menuCoords: Point;
    articles: Article[]; 
    setArticles: (articles: Article[]) => void;
    height: number;
    width: number;
    expanded: boolean;
}

const DealContextMenu = ({ state, setState, menuCoords, articles, setArticles, setTheCard, 
    height, width, setWheelVisible, expanded }: Props) => {
    const deal = useSelector(getActiveDeal);
    const { data: dealType } = useGetDealTypeQuery(deal?.deal_type?.id, { skip: !deal?.deal_type?.id })
	const { data: permissions } = useGetPermissionsQuery()
    const { data: extraTypes } = useGetExtraDealTypesQuery()

    const dispatch = useDispatch();

    const handleInvert = useCallback(() => {
        if (state.target) {
            if (state.extra_target) {
                dispatch(invertPosition({ position: state.target, extra: state.extra, extra_position: state.extra_target })); 
            } else {
                dispatch(invertPosition({ position: state.target })); 
            }
        } else {
            dispatch(invertPosition({ extra: state.extra, extra_position: state.extra_target })); 
        }
        setState({...state, visible: false});
    }, [dispatch, setState, state]);

    const handleSelectCard = useCallback((extra: boolean) => {
        if (extra && state.extra_target) {
            setTheCard(state.extra_target);
            setWheelVisible(true);
        } else if (state.target) {
            setTheCard(state.target);
            setWheelVisible(true);
        }
        setState({...state, visible: false});
    }, [setState, setTheCard, setWheelVisible, state]);

    const handleSwitchArticle = useCallback((e: { pageX: number; pageY: number; clientY: number; }) => {
        let card: CardRef | undefined
        if (state.target) {
            if (state.extra_target != '-1' && (state.extra || state.extra == 0)) {
                card = deal?.deal_cards?.find(rec => rec.position == state.target)?.extra_deals[state.extra]
                    .extra_deal_cards?.find(rec => rec.position == state.extra_target)
            } else {
                card = deal?.deal_cards
                    .find(rec => rec.position == state.target)
            }
        } else {
            if (state.extra_target  != '-1' && (state.extra || state.extra == 0)) {
                card = deal?.extra_deals[state.extra]
                    .extra_deal_cards?.find(rec => rec.position == state.extra_target)
            }
        }
        const article = articles.find(rec => rec.cardId == card?.id)
        if (article) {
            const newArticles = articles.filter(rec => rec.cardId != card?.id)
            setArticles(newArticles)
        } else {
            const newArticles = ([] as Article[]).concat(articles)
            const newArticle: Article = {
                x: e.pageX,
                y: e.pageY,
                cardId: card?.card.id || "",
                title: `${card?.card.title}`,
                article: card?.card.article?.article || ""
            }
            if ((e.clientY + 600) > window.innerHeight) { 
                newArticle.y = e.pageY - ((e.clientY + 600) - window.innerHeight) 
            }
            newArticles.push(newArticle)
            setArticles(newArticles)
        }
        setState({...state, visible: false});
    }, [articles, deal?.deal_cards, deal?.extra_deals, setArticles, setState, state]);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleGoToExtra = useCallback((extra: ExtraDef) => {
        let extraType: ExtraDealType = {
            deal_cards: [],
            global_extra: false
        }
        if (extra.position && extra.position == "-1") {
            if (extra.index && extra.index != -1) {
                extraType = deal.extra_deals[extra.index].extra_deal_type
            }
        } else {
            if (extra.index && extra.index != -1) {
                const card = deal.deal_cards.find(rec => rec.position == extra.position)
                if (card) {
                    extraType = card.extra_deals[extra.index].extra_deal_type
                }
            }
        }
        dispatch(setActiveExtra2(extra, extraType, width, height, expanded, false))
        // dispatch(showQuestion())
        setState({...state, visible: false});
    }, [deal.deal_cards, deal.extra_deals, dispatch, expanded, height, setState, state, width]);

    const handleAddExtra = useCallback((e: React.MouseEvent, extra: ExtraDealType | undefined) => {
        const long = deal.deck?.long
        if (state.target && extra?.global_extra != true) {
            const maxIndex = deal?.deal_cards.find(rec => rec.position == state.target)?.extra_deals.length || 0
            dispatch(addExtra({ position: state.target, type: extra }))
            if (extra) {
                dispatch(setActiveExtra2({ position: state.target, index: maxIndex }, extra, width, height, expanded, false, long || false))                
            }
        } else {
            const maxIndex = deal?.extra_deals.length || 0
            dispatch(addExtra({ position: -1, type: extra }))
            if (extra) {
                dispatch(setActiveExtra2({ position: "-1", index: maxIndex }, extra, width, height, expanded, false, long || false))
            }
        }
        // dispatch(showQuestion())
        setState({...state, visible: false});
    }, [deal?.deal_cards, deal.deck?.long, deal?.extra_deals.length, dispatch, expanded, height, setState, state, width]);

    const handleDeleteExtra = useCallback((extra: ExtraDef | undefined) => {
        dispatch(setActiveExtra2({ position: "-1", index: -1 }, null, width, height, expanded, false))
        if (state.target && extra?.position != "-1") {
            dispatch(deleteExtra({ position: extra?.position, index: extra?.index }))
        } else {
            dispatch(deleteExtra({ position: -1, index: extra?.index }))
        }
        setState({...state, visible: false});
    }, [dispatch, expanded, height, setState, state, width]);

    const handleShowExtraQuestion = useCallback((extra: ExtraDef | undefined) => {
        if (extra) {
            dispatch(showQuestion(extra))
        }
        setState({...state, visible: false});
    }, [dispatch, setState, state]);

    switch(state.type) {
        case "global":
            return(
                <ContextMenu 
                    x={menuCoords.x} 
                    y={menuCoords.y} 
                    visible={state.visible} 
                >
                    {extraTypes?.filter(rec => rec.global_extra == true).map((extra, index) => {
                        return(
                            <ContextMenuItem 
                                key={index} 
                                title={`Даат: ${extra.title}`} 
                                icon="cards" 
                                handleOnClick={(e: React.MouseEvent) => handleAddExtra(e, extra)}
                            />
                            )
                    })}            
                </ContextMenu>
            )
        case "card":
            return(
                <ContextMenu 
                    x={menuCoords.x} 
                    y={menuCoords.y} 
                    visible={state.visible} 
                >
                    {deal?.deal_cards.find(rec => rec.position == state.target)?.card ?
                    <ContextMenuItem title="Заменить карту" icon="cards" handleOnClick={() => handleSelectCard(false)} />
                    :
                    <ContextMenuItem title="Указать карту" icon="cards" handleOnClick={() => handleSelectCard(false)} />}
                    <ContextMenuItem title="Перевернуть карту" icon="turn" handleOnClick={handleInvert} />
                    {permissions?.articles?.view &&
                        <ContextMenuItem title="Показать значение" icon="book" handleOnClick={handleSwitchArticle} />}
                    <ContextMenuDivider />
                    {dealType?.extra_deal_types?.map((extra, index) => {
                        const type = extraTypes?.find(rec => rec.id == extra)
                        if (!type?.global_extra) {
                            return(
                                <ContextMenuItem 
                                    key={index} 
                                    title={`Даат: ${type?.title}`} 
                                    icon="cards" 
                                    handleOnClick={(e: React.MouseEvent) => handleAddExtra(e, type)}
                                />
                            )    
                        }
                    })}            
                </ContextMenu>
            )
        case "extra":
            // eslint-disable-next-line no-case-declarations
            const extra: ExtraDef = {}
            extra.position = state.target ? state.target : "-1"
            extra.index = (state.extra || state.extra == 0) ? state.extra : undefined
            return(
                <ContextMenu 
                    x={menuCoords.x} 
                    y={menuCoords.y} 
                    visible={state.visible} 
                >
                    <ContextMenuItem title="Перейти к Даат" icon="cards" handleOnClick={() => handleGoToExtra(extra)} />
                    <ContextMenuItem title="Вопрос к Даат" icon="cards" handleOnClick={() => handleShowExtraQuestion(extra)} />
                    <ContextMenuDivider />
                    <ContextMenuItem title="Удалить Даат" icon="trash" handleOnClick={() => handleDeleteExtra(extra)} />
                </ContextMenu>
            )
        case "extra_card":
            // eslint-disable-next-line no-case-declarations
            let extraDeal
            if (state.target) {
                if (state.extra || state.extra == 0) {
                    extraDeal = deal?.deal_cards.find(rec => rec.position == state.target)?.extra_deals[state.extra]
                }
            } else {
                if (state.extra || state.extra == 0) {
                    extraDeal = deal?.extra_deals[state.extra]
                }
            }

            return(
                <ContextMenu 
                    x={menuCoords.x} 
                    y={menuCoords.y} 
                    visible={state.visible} 
                >
                    {extraDeal?.extra_deal_cards.find(rec => rec.position == state.extra_target)?.card ?
                    <ContextMenuItem title="Заменить карту" icon="cards" handleOnClick={() => handleSelectCard(true)} />
                    :
                    <ContextMenuItem title="Указать карту" icon="cards" handleOnClick={() => handleSelectCard(true)} />}
                    <ContextMenuItem title="Перевернуть карту" icon="turn" handleOnClick={handleInvert} />
                    {permissions?.articles?.view &&
                        <ContextMenuItem title="Показать значение" icon="book" handleOnClick={handleSwitchArticle} />}
                </ContextMenu>
            )
        default:
            return(<></>)
    }
}

export default DealContextMenu;