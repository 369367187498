import type { Position } from '../types/Position';
import type { DealCard } from '../types/DealType';

export interface Dimensions {
    width?: number;
    height?: number;
}

export interface Geometry {
    stage?: Dimensions;
    cardWidth?: number;
    cardHeight?: number;
    xOffset?: number;
    yOffset?: number;
    margin?: number;
    marginRate?: number;
    outerMarginX?: number;
    outerMarginY?: number;
    outerMarginRateX?: number;
    outerMarginRateY?: number;
    positions: Array<Position>;
    // limit?: number;
    hintBtn?: Path2D;
    plusBtn?: Path2D;
}

export const getStageDimensions = (width: number, height: number/*, expanded, x, y*/) => {
    const dimensions: Dimensions = {}

    // let stageWidth, stageHeight;

    // if (width > 720) {
    //     if (expanded) {
    //         stageWidth = width > 880 ? width - 680 : 200;
    //     } else {
    //         stageWidth = width - 440;
    //     }
    //     stageHeight = (y / x) * stageWidth * 1.5;
    //     if (stageHeight > stageWidth * 1.5) {
    //         stageHeight = stageWidth * 1.5;
    //     }
    //     if (stageHeight > height - 80) {
    //         stageHeight = height - 80;
    //     }

    //     console.log(stageWidth, stageHeight)

    //     dimensions.width = stageWidth;
    //     dimensions.height = stageHeight;    
    // } else {
        const stageWidth = width - 460;
        const stageHeight = height - 160;
   
        dimensions.width = stageWidth;
        dimensions.height = stageHeight;    
    // }

    return dimensions;
}

export const getStageDimensionsVertical = (width: number, x: number, y: number) => {
    const dimensions: Dimensions = {} 

    // let stageHeight;

    const stageHeight = (y / x) * width * 1.7;

    dimensions.width = width;
    dimensions.height = stageHeight;

    return dimensions;
}

const findRotated = (cards: Array<Position>, maxX: number) => {
    let result = false;
    cards.map((card) => {
        if (card.rotate != "C" && card.rotate != "C180" && card.x == 1 && card.x == maxX) {
            result = true;
        }
    })
    return result;
}

export const getCardDimensions = (stage: Dimensions, x: number, y: number, margins: number, 
                                    outerX: number, outerY: number, long: boolean = false) => {
    const geo: Geometry = { positions: [] };
    const rate = long ? 1.82 : 1.5

    geo.outerMarginRateX = outerX;
    geo.outerMarginRateY = outerY;
    geo.marginRate = margins;

    const tmpWidth = (stage.width || 0) / (x + (x - 1) * geo.marginRate + geo.outerMarginRateX * 2);
    const tmpHeight = (stage.height || 0) / (y + (y - 1) * geo.marginRate / rate + geo.outerMarginRateY * 2 / rate);

    if (tmpWidth * rate <= tmpHeight) {
        geo.cardWidth = tmpWidth;
        geo.cardHeight = tmpWidth * rate;
        geo.margin = tmpWidth * geo.marginRate;
        geo.outerMarginX = tmpWidth * geo.outerMarginRateX;
        geo.outerMarginY = tmpWidth * geo.outerMarginRateY; 
        geo.yOffset = ((stage.height || 0) - (geo.outerMarginY * 2 + y * geo.cardHeight + (y - 1) * geo.margin)) / 2;
        geo.xOffset = 0;
    } else {
        geo.cardWidth = tmpHeight / rate;
        geo.cardHeight = tmpHeight;
        geo.margin = tmpHeight * geo.marginRate / rate;
        geo.outerMarginX = tmpHeight * geo.outerMarginRateX / rate;
        geo.outerMarginY = tmpHeight * geo.outerMarginRateY / rate; 
        geo.yOffset = 0;
        geo.xOffset = ((stage.width || 0) - (geo.outerMarginX * 2 + x * geo.cardWidth + (x - 1) * geo.margin)) / 2;
    }

    return geo;
}

export const applyRotation = (pos: Position) => {
    let rotation, x, y, offX, offY;
    if(pos) {
        switch (pos.rotate) {
            case "L45":
                if (pos?.inverted) {
                    rotation = 140;
                } else {
                    rotation = -40;
                }
                x = (pos.x || 0);
                y = (pos.y || 0) + (pos.height || 0) / 2;
                offX = (pos.width || 0) / 2;
                offY = (pos.height || 0) / 2;
                break;
            case "R45":
                if (pos?.inverted) {
                    rotation = -140;
                } else {
                    rotation = 40;
                }
                x = (pos.x || 0) + (pos.width || 0);
                y = (pos.y || 0) + (pos.height || 0) / 2;
                offX = (pos.width || 0) / 2;
                offY = (pos.height || 0) / 2;
                break;
            case "L90":
                if (pos?.inverted) {
                    rotation = 90;
                } else {
                    rotation = -90;
                }
                x = (pos.x || 0) + (pos.width || 0) / 2;
                y = (pos.y || 0) + (pos.height || 0) / 2;
                offX = (pos.width || 0) / 2;
                offY = (pos.height || 0) / 2;
                break;
            case "R90":
                if (pos?.inverted) {
                    rotation = -90;
                } else {
                    rotation = 90;
                }
                x = (pos.x || 0) + (pos.width || 0) / 2;
                y = (pos.y || 0) + (pos.height || 0) / 2;
                offX = (pos.width || 0) / 2;
                offY = (pos.height || 0) / 2;
                break;
            case "C180":
                if (pos?.inverted) {
                    rotation = 0;
                } else {
                    rotation = 180;
                }
                x = (pos.x || 0) + (pos.width || 0) / 2;
                y = (pos.y || 0) + (pos.height || 0) / 2;
                offX = (pos.width || 0) / 2;
                offY = (pos.height || 0) / 2;
                break;
            default:
                if (pos?.inverted) {
                    rotation = 180;
                } else {
                    rotation = 0;
                }
                x = (pos.x || 0) + (pos.width || 0) / 2;
                y = (pos.y || 0) + (pos.height || 0) / 2;
                offX = (pos.width || 0) / 2;
                offY = (pos.height || 0) / 2;
                break;
            }
        }
    return {rotation, x, y, offX, offY};
}

export const calculatePositions = (dealCards: Array<DealCard>, width: number, height: number, 
                                    expanded: boolean, long: boolean, vertical?: boolean) => {
    const maxX = Math.max(...dealCards.map(function(o) { return o.x; }))
    const maxY = Math.max(...dealCards.map(function(o) { return o.y; }))

    console.log("Long: ", long)

    let stage, geo;
    
    if (vertical) {
        let ofX = 0.3;
        stage = getStageDimensionsVertical(width, maxX, maxY);
        if (findRotated(dealCards, maxX)) { ofX = 1.1; stage.height = (stage.height || 0) / 1.7 }
        geo = getCardDimensions(stage, maxX, maxY, 1, ofX, 0.1, long);
    } else {
        stage = getStageDimensions(width, height/*, expanded, maxX, maxY*/);
        geo = getCardDimensions(stage, maxX, maxY, 1, 1, 0.5, long);
    }

    const newPositions: Array<Position> = [];
    geo.stage = stage;
    dealCards.map((card) => {
        const newCard: Position = { position: card.position }
        // newCard.inverted = false;
        // newCard.position = card.position;
        newCard.rotate = card.rotate;
        newCard.id = card.id;
        newCard.x = (geo.outerMarginX || 0) + (card.x - 1) * ((geo.cardWidth || 0) + (geo.margin || 0)) + (geo.xOffset || 0);
        newCard.y = (geo.outerMarginY || 0) + (card.y - 1) * ((geo.cardHeight || 0)  + (geo.margin || 0)) + (geo.yOffset || 0);
        newCard.width = geo.cardWidth;
        newCard.height = geo.cardHeight;
        newPositions.push(newCard);	
    });    

    geo.positions = newPositions;

    return geo;
}

export const adjustStageDimensions = (stage?: Dimensions) => {
    let width = 100
    let height = 100
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (stage && !isNaN(stage?.width as any)) { width = (stage.width || 0) }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (stage && !isNaN(stage?.height as any)) { height = (stage.height || 0) }

    return { width: width, height: height }
}
