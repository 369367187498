import { apiSlice } from '../redux/apiSlice';
import { Permissions, User } from '../types/Account';
import { Product } from '../types/Product';

const { TAROT_API_URL } = process.env;

interface Account {
  id?: string,
  currency?: string;
}

const enhancedApi = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "Account", 
    "Catalog", 
    "Product", 
    "Permissions", 
    "Articles", 
    "DealTypes", 
    "Cards", 
    "ExtraDealTypes", 
    "Systems"
  ]
});

const accountApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getSelfAccount: builder.query<Account, void>({
      query: () => '/accounts/me',
      providesTags: () => [{ type: 'Account' }],
      keepUnusedDataFor: 36000
    }),
    getCatalog: builder.query<Product[], void>({
      query: () => '/products/catalog',
      providesTags: () => [{ type: 'Catalog' }],
      keepUnusedDataFor: 36000
    }),
    getPermissions: builder.query<Permissions, void>({
      query: () => '/accounts/permissions',
      providesTags: () => [{ type: 'Permissions' }],
      keepUnusedDataFor: 36000
    }),
    getProduct: builder.query<Product, void>({
      query: () => '/accounts/product',
      providesTags: () => [{ type: 'Product' }],
      keepUnusedDataFor: 36000
    }),
    updateSelfProfile: builder.mutation<ReadableStream<Uint8Array>, User>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { avatar, avatarAlt, ...body } = _arg
        const attachments = new FormData();
        if (avatarAlt) {
          const avatar_img = await fetch(avatarAlt || "")
          if (!avatar_img.ok) return { error: { status: 500, statusText: 'Internal Server Error', 
                                        data: avatar_img.statusText + ": " + avatar_img.text } }
          const blob = await avatar_img.blob()  
          attachments.append('avatar', blob, 'avatar.png');
        }
        if (body?.name) attachments.append("name", body.name);
        if (body?.user_name) attachments.append("user_name", body.user_name);
        if (body?.surname) attachments.append("surname", body.surname);
        if (body?.country) attachments.append("country", body.country);
        if (body?.sex) attachments.append("sex", body.sex);
        if (body?.email) attachments.append("email", body.email);
        if (body?.phone) attachments.append("phone", body.phone);
        if (body?.birthday) attachments.append("birthday", body.birthday);
        const token = localStorage.getItem('accessToken')
        const result = await fetch(`${TAROT_API_URL}/accounts/me`, {
          method: "PUT",
          body: attachments,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        return result.body ? { data: result.body } : { error: { status: 500, statusText: 'Internal Server Error', data: "" } }
      },
      invalidatesTags: () => [{ type: 'Account' }],
    }),
    updateSubscription: builder.mutation({
      query(plan) {
        return {
          url: `/accounts/me/subscribe/${plan}`,
          method: 'POST'
        }
      },
      invalidatesTags: () => [{ type: 'Account' }, 
        { type: 'Articles' }, { type: 'DealTypes' }, { type: 'Cards' }, 
        { type: 'ExtraDealTypes' }, { type: 'Systems' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetSelfAccountQuery, useGetCatalogQuery, 
  useGetPermissionsQuery, useUpdateSubscriptionMutation, useGetProductQuery, 
  useUpdateSelfProfileMutation } = accountApi