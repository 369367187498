import { useRef, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCanvas = (draw: (ctx: any) => void) => {
  
    const canvasRef = useRef<HTMLCanvasElement>(null)
  
    useEffect(() => {   
        const canvas = canvasRef.current
        const context = canvas?.getContext('2d')
        draw(context)
    }, [draw])
  
    return canvasRef
}

export default useCanvas