import React from 'react';
import { useDispatch } from 'react-redux';

type Props = {
    action: (val: string) => void;  
    editMode: boolean; 
    label: string; 
    value: string; 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rest?: any;
}

const Field = ({action, editMode, label, value, ...rest}: Props) => {
    const dispatch = useDispatch();

    return (
        <div className='field' data-testid="tarot-field"
            {...rest}
        >
            <div className='label'>
                {label}:
            </div>
            <div className='data' data-testid='tarot-data'>
                {editMode ?
                    <input 
                        type="text" 
                        value={value} 
                        className="tarot-client-field"
                        onChange={(e) => dispatch(action(e.target.value))}
                    />
                    :
                    value
                }
            </div>
        </div> 
    )
}

export default Field;