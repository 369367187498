import React from 'react';
import TreeExtra from './TreeExtra';
import TreeCard from './TreeCard';
import { ContextMenuState } from './DealTree';
import { Deal } from '../../types/Deal';
import { ExtraDealType } from '../../types/ExtraDealType';
import { ExtraDef } from '../../types/ExtraDef';
import { Point } from '../../types/Geometry';

type Props = {
    setContextMenuState: (state: ContextMenuState) => void;
    setMenuCoords: (coords: Point) => void;
    state: ContextMenuState;
    types: ExtraDealType[];
    deal: Deal;
    activeExtra: ExtraDef;
    height: number;
    width: number;
    expanded: boolean;
    long?: boolean;
}

const DealExtras = ({ deal, activeExtra, types, state, setContextMenuState, setMenuCoords,
    height, width, expanded, long }: Props) => {   
    return (
        <>
            {deal?.extra_deals?.map((extra, eix) => {
                const extraType = types.find(rec => rec.id == extra.extra_deal_type.id)
                return (
                    <div key={eix} className={`tree-extra ${(activeExtra?.position == "-1" && activeExtra?.index == eix) ? "active" : ""}`}>
                        <TreeExtra 
                            state={state} level={1} target={"-1"} index={eix} type={extraType} 
                            question={extra.question || ""} setContextMenuState={setContextMenuState} 
                            setMenuCoords={setMenuCoords} long={long || false}
                            height={height} width={width} expanded={expanded}
                        />

                    {extraType?.deal_cards.slice(0, extra?.limit).map((extra_card, cix) => {

                        return (
                            <TreeCard level={1} index={eix} key={cix}
                                cards={extra?.extra_deal_cards} e_position={extra_card.position}
                                state={state} setState={setContextMenuState} setMenuCoords={setMenuCoords} />
                        )
                    })}
                    </div>
                )
            })}
        </>
    )
}

export default DealExtras;