import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientUpdated, getEditMode } from '../../redux/client/clientSlice';
import ContentEditable from "react-contenteditable";
import { Client } from '../../types/Client';

type Props = {
    client: Client;
}

const EmailControl = ({ client } : Props) => {
    const dispatch = useDispatch();
    const editMode = useSelector(getEditMode);

    return (
        <div className='tarot-email-control' data-testid="tarot-email-control">
            <b>E-mail:</b> 
            <ContentEditable
                html={client?.email || ""}
                disabled={!editMode}
                className="tarot-client-field"
                onChange={(e) => dispatch(clientUpdated({ id: client.id, email: e.target.value.replace("<br>", "") }))}
            />                     
        </div>
    )
}

export default EmailControl;