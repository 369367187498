import React, { useCallback } from 'react';
import { FaLongArrowAltDown } from 'react-icons/fa';
import { ContextMenuState } from './DealTree';
import { CardRef } from '../../types/CardRef';
import { Point } from '../../types/Geometry';

type Props = {
    level: number;
    index?: number;
    position?: string; 
    cards: CardRef[];
    state: ContextMenuState;
    setState: (state: ContextMenuState) => void;
    e_position?: string;
    setMenuCoords: (coords: Point) => void;
}

const TreeCard = ({ level, index, position, cards, state, setState, e_position, setMenuCoords } : Props) => {
    const search = e_position ? e_position : position
    const card = cards?.find(card => card.position == search)

    const classLevel = useCallback((level: number) => {
        switch(level) {
            case 1:
                return "tree-level-1"
            case 2:
                return "tree-level-2-5"
            default:
                return ""
        }
    }, []);

    const handleTreeContext = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>, 
                                index: number | null | undefined, 
                                position?: string, e_position?: string) => {
        e.preventDefault()
        let type, extra, target, extra_target
        if (index === undefined || index == null) {
            type = "card"
            target = position
            extra = null
            extra_target = null
        } else {
            type = "extra_card"
            target = position
            extra = index
            extra_target = e_position
        }
        setState({
            visible: !state.visible,
            type: type,
            target: target || null,
            extra: extra,
            extra_target: extra_target || null
        })
        setMenuCoords({ x: e.clientX, y: e.pageY })
    }, [setMenuCoords, setState, state.visible]);

    return (
        <div className={classLevel(level)} onContextMenu={e => handleTreeContext(e, index, position, e_position)}>
            <div className='tree-chevron'>

            </div>
            <div className='position'>
                {e_position ? e_position : position}
            </div>
            {card?.card ?
            <div className={`line ${card.selected && "selected"}`}>
                {card.card?.title}  {card.inverted == true && <FaLongArrowAltDown data-testid="tarot-tree-card-inverted"/>}
            </div>
            :
            <div className='line-empty'>
                &lt; Не заполнено &gt;
            </div>
            }
        </div>
    )
}

export default TreeCard;