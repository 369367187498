import React from 'react';
import TreeExtra from './TreeExtra';
import TreeCard from './TreeCard';
import { ContextMenuState } from './DealTree';
import { Deal } from '../../types/Deal';
import { DealType } from '../../types/DealType';
import { ExtraDealType } from '../../types/ExtraDealType';
import { ExtraDef } from '../../types/ExtraDef';
import { Point } from '../../types/Geometry';

type Props = {
    deal: Deal;
    dealType?: DealType;
    activeExtra: ExtraDef;
    types: ExtraDealType[];
    state: ContextMenuState;
    setContextMenuState: (state: ContextMenuState) => void;
    setMenuCoords: (coords: Point) => void;
    height: number;
    width: number;
    expanded: boolean;
    long?: boolean;
}

const DealCard = ({ deal, dealType, activeExtra, types, state, setContextMenuState, setMenuCoords,
    height, width, expanded, long }: Props) => {

    return (
        <>
            {dealType?.deal_cards?.slice(0, deal?.limit).sort((a, b) => (a.order > b.order) ? 1 : -1).map(deal_card => {
                const card = deal?.deal_cards.find(card => card.position == deal_card?.position)

                return (
                    <div key={deal_card?.position}>
                        <TreeCard level={1} position={deal_card?.position} cards={deal?.deal_cards}
                            state={state} setState={setContextMenuState} setMenuCoords={setMenuCoords} />

                        {card?.extra_deals.map((extra, eix) => {
                            const extraType = types.find(rec => rec.id == extra.extra_deal_type.id)

                            return (
                                    <div key={eix} className={`tree-extra ${(activeExtra?.position == deal_card?.position && activeExtra?.index == eix) ? "active" : ""}`}>
                                    <TreeExtra 
                                        state={state} level={2} type={extraType} target={deal_card?.position} index={eix} 
                                        question={extra.question || ""} setContextMenuState={setContextMenuState} 
                                        setMenuCoords={setMenuCoords} long={long || false}
                                        height={height} width={width} expanded={expanded} />

                                    {extraType?.deal_cards.slice(0, extra?.limit).map((extra_card, cix) => {
                                        return (
                                            <TreeCard key={cix} level={2} index={eix} position={deal_card?.position} 
                                                cards={extra?.extra_deal_cards} e_position={extra_card.position}
                                                state={state} setState={setContextMenuState} setMenuCoords={setMenuCoords} />
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </> 
    )

}

export default DealCard;