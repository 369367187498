import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { RiArrowGoBackLine, RiSearchLine } from "react-icons/ri";
import useKeyPress from '../hooks/useKeyPress';

import '../css/wheel1.css';
import { WheelMenuItem } from '../types/WheelMenuItem';

interface WheelMenuState {
    currentItems: WheelMenuItem[];
    chosen: WheelMenuItem[];
    prevMenu: WheelMenuItem[][];
}

type WheelMenuAction = {
    type: string;
    item?: WheelMenuItem;
    items?: WheelMenuItem[];
}

type Props = {
    items: WheelMenuItem[];
    h1: string;
    x: number;
    y: number;
    visible: boolean;
    callback: (item: string) => void;
    extraFunc: () => void;
    setVisible: (v: boolean) => void;
}

type shortcutOptions = {
    [key: string]: () => void;
}

function reducer(state: WheelMenuState, action: WheelMenuAction): WheelMenuState {
    switch (action.type) {
        case 'go_forward':
            // eslint-disable-next-line no-case-declarations
            const newPrevMenu = [...state.prevMenu]
            newPrevMenu[newPrevMenu.length] = state.currentItems
            // eslint-disable-next-line no-case-declarations
            const newChosen = [...state.chosen]
            if (action.item) {
                newChosen[newChosen.length] = action.item
            }
            return {
                prevMenu: newPrevMenu,
                currentItems: action.item?.submenu || [],
                chosen: newChosen
            };
        case 'go_back':
            return {
                prevMenu: state.prevMenu.filter((_, i) => i != state.prevMenu.length - 1),
                currentItems: state.prevMenu[state.prevMenu.length - 1],
                chosen: state.chosen.filter((_, i) => i != state.chosen.length - 1)
            };
        case 'reset':
            // eslint-disable-next-line no-case-declarations
            const prevMenu = [...state.prevMenu]
            // eslint-disable-next-line no-case-declarations
            const chosen = [...state.chosen]
            return {
                prevMenu: prevMenu,
                currentItems: action.items || [],
                chosen: chosen
            }
        case "clear":
            return {
                prevMenu: [],
                currentItems: action.items || [],
                chosen: []
            }
    }
    throw Error('Unknown action: ' + action.type);
}

const WheelMenu = ({ items, h1, x, y, visible, callback, extraFunc, setVisible }: Props) => {
    const initialState: WheelMenuState = { currentItems: items, prevMenu: [], chosen: [] };
    const [state, dispatch] = useReducer(reducer, initialState);

    const [shortcuts, setShortcuts] = useState({});
    const [activeItem, setActiveItem] = useState<WheelMenuItem | null>(null);

    useKeyPress(shortcuts, visible);

    useEffect(
        () => {
            dispatch({ type: 'reset', items: items })
        }, [items]
    )

    useEffect(
        () => {
            // if (visible == false) {
                dispatch({ type: 'clear', items: items })
            // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [visible]
    );    

    useEffect(
        () => {
            const newShortcuts: shortcutOptions = {}
            state.currentItems.map((item) => {
                if (item.shortcut) {
                    newShortcuts[item.shortcut] = () => performAction(item)
                } 
            })
            newShortcuts["Escape"] = () => setVisible(false)
            setShortcuts(newShortcuts)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state.currentItems]
    )

    const segmentStyle = useCallback((total: number, current: number) => {
        const sector_angle = 360 / total
        const sector_clip = (180 - sector_angle) / 2
        const angle = sector_angle * current
        let clip
        if (sector_angle <= 90) {
            const d = Math.abs(50 * Math.tan(((sector_angle / 2) * Math.PI) / 180))
            const x1 = 50 - d
            const x2 = 50 + d
            clip = `polygon(50% 100%, ${x1}% 0, ${x2}% 0`
        } else {
            const y = Math.abs(100 * Math.tan((sector_clip * Math.PI) / 180))
            clip = `polygon(50% 100%, 0 ${100 - y}%, 0 0, 100% 0, 100% ${100 - y}%)`
        }
        return {
            "--rotate": `${angle}deg`,
            clipPath: clip,
        }
            
    }, []);

    const getItem = useCallback((item: WheelMenuItem) => {
        switch(item.label) {
            case "icon":
                switch (item.name) {
                    case "back":
                        return <RiArrowGoBackLine />
                    case "search":
                        return <RiSearchLine />
                }
                break;
            case 'image':
                return <img src={`/image/${item.name}`} />
            default:
                return item.name
        }
    }, []);

    const generateHint = useCallback(() => {
        let hint = ""
        state.chosen.map((item: WheelMenuItem) => hint = hint + item.hint)
        switch(state.chosen[state.chosen.length - 1]?.style) {
            case 'composite':
                if (activeItem?.style == "solo") return `${activeItem?.hint ? activeItem.hint : ""}`
                return `${hint}${activeItem?.hint ? activeItem.hint : ""}`
            case 'sticky':
                return `${activeItem?.hint ? activeItem.hint : state.chosen[state.chosen.length - 1]?.hint}`
            default:
                return `${activeItem?.hint ? activeItem.hint : ""}`
            
        }
    }, [activeItem?.hint, activeItem?.style, state.chosen]);

    const performAction = useCallback((item: WheelMenuItem) => {
        // if (visible) {
            const action = item.action
            switch (action) {
                case "open-child":
                    dispatch({ type: 'go_forward', item: item });
                    setActiveItem(null) 
                    break
                case "go-back":
                    dispatch({ type: 'go_back' }); 
                    setActiveItem(null) 
                    break
                case "extra-func":
                    extraFunc()
                    break
                case "commit":
                    if (state.chosen[state.chosen.length - 1]?.style != 'composite') {
                        callback(`${item?.hint ? item.hint : ''}`)
                    } else {
                        let result = ''
                        state.chosen.map((item) => result = result + item.hint)
                        callback(`${result}${item?.hint ? item.hint : ""}`);
                    }
            }    
        // }
    }, [callback, extraFunc, state.chosen]);

    const style = { "--x": `${x}px`, "--y": `${y}px` } as React.CSSProperties;

    return (
        <div className={`wheel ${visible ? " visible" : ""}`} style={style} data-testid="tarot-wheel-menu" >
            {
                state.currentItems?.map((item, index) => {
                    return (
                        <div className="segment" style={segmentStyle(state.currentItems.length, index)} key={index}
                            onClick={() => performAction(item)} 
                            onMouseOver={() => setActiveItem(item)}
                            onMouseOut={() => setActiveItem(null)}
                            data-testid="tarot-wheel-menu-item"
                        >
                            <span>{getItem(item)}</span>
                        </div>        
                    )
                })
            }
            <div className='wheel-label'>
                <div className='label-h1' data-testid="tarot-wheel-menu-header">
                    {h1}
                </div>
                <div className='label-h2' data-testid="tarot-wheel-menu-hint">
                    {generateHint()}
                </div>
            </div>
        </div>
    )
}

export default WheelMenu;