import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { setAuthenticated } from '../redux/settings/settingsSlice';
import { Mutex } from 'async-mutex';

const { TAROT_API_URL } = process.env;

const mutex = new Mutex()

interface LoginResponse {
    access_token?: string;
}

const baseQuery = fetchBaseQuery({ 
    baseUrl: `${TAROT_API_URL}`,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('accessToken')
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        headers.set('Content-Type', 'application/json')
        return headers
    }
})

const baseQueryWithoutAuth = fetchBaseQuery({ baseUrl: `${TAROT_API_URL}` })

const baseQueryWithReauth: BaseQueryFn<
                                string | FetchArgs,
                                unknown,
                                FetchBaseQueryError
                            > = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
            const refreshResult = await baseQueryWithoutAuth(
                { 
                    url: '/refresh', 
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('refreshToken')
                    }
                },
                api,
                {}
            )
            if (refreshResult.data) {
                const data: LoginResponse = refreshResult.data
                localStorage.setItem('accessToken', data.access_token || "")
                api.dispatch(setAuthenticated(true))
                result = await baseQuery(args, api, extraOptions)
            } else {
                localStorage.removeItem("accessToken")
                api.dispatch(setAuthenticated(false))
            }
        } finally {
            release()
        }
      } else {
        await mutex.waitForUnlock()
        result = await baseQuery(args, api, extraOptions)
      }
    }
    return result
  }
  
export default baseQueryWithReauth;  