import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { getActiveDeal, setActiveDealDeck } from '../../redux/deals/dealsSlice';
import { useGetOnlyAuthorizedDecksQuery, useGetDeckQuery } from '../../api/deckApi';
import { Permissions } from '../../types/Account';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContent } from '../../types/ToastContent';

type Props = {
    permissions?: Permissions;
    disabled: boolean;
    setShowToast: (visible: boolean) => void;
    setToast: (toast: ToastContent) => void;
}

const DeckSelector = ({ permissions, disabled, setShowToast, setToast }: Props) => {
    const dispatch = useDispatch();
    const { data: decks, isLoading } = useGetOnlyAuthorizedDecksQuery();
    const deal = useSelector(getActiveDeal);
    const { data: deck, isError } = useGetDeckQuery(deal?.deck?.id, { skip: !deal?.deck?.id })

    useEffect(
        () => {
            if (isError) {
                setToast({
                    title: 'Интернет недоступен',
                    description: "Не удалось связаться с Тарологией, возможно проблемы со связью. Попробуйте повторить позже.",
                    status: 'error'
                })
                setShowToast(true)
                dispatch(setActiveDealDeck({ deck: null }));
            }
        }, [dispatch, isError, setShowToast, setToast]
    )

    const handleSetActiveDeck = (key: string | null) => {
        if (key) {
            const i = parseInt(key)
            if (!isError && (i || i == 0)) {
                dispatch(setActiveDealDeck({ deck: ((decks && (i || i == 0)) ? decks![i] : null) }));
            }
        }
    }

    const activeDeckName = useCallback(() => {
        if (deck && deal?.deck?.title) {
            return(deck.title)
        }
        return "Выберите колоду"
    }, [deal, deck]);

    const tooltip = (
        <Tooltip id="tooltip" style={{ backgroundColor: "transparent" }}>
            {activeDeckName()}
        </Tooltip>
    );

    return(
        <>
            {isLoading 
            ? 
            <Spinner 
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
                data-testid="tarot-save-client-spinner"
            />
            :
            <OverlayTrigger placement="right" overlay={tooltip}>
                <DropdownButton
                    onSelect={(key) => handleSetActiveDeck(key)}
                    title={activeDeckName()}
                    size="sm"
                    disabled={permissions?.deals?.save === undefined ? disabled : (!permissions?.deals?.save || disabled)}
                    className="pb-2 toolbar-dropdown"
                    variant={"primary"}
                    data-testid="tarot-systems-selector"
                >
                    {decks?.map((sys, index) => {
                        return(
                            <Dropdown.Item 
                                key={index} 
                                eventKey={index}
                                data-testid="tarot-system"
                            >
                                {sys.title}
                            </Dropdown.Item>
                        );
                    })}
                </DropdownButton>
            </OverlayTrigger>
            }
        </>
    )
}

export default DeckSelector;