import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clientSwapFavorite, switchEditMode, clearNewClientFlag, 
    activeClientLoaded, clientUpdated, getActiveClient, setEditModeOn, setEditModeOff, 
    getEditMode, getNewClientStatus } from '../redux/client/clientSlice';
import { FaPencilAlt } from "react-icons/fa";
import { LuPin, LuPinOff } from 'react-icons/lu';
import { IoIosClose } from 'react-icons/io';
import { useHistory, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SortButton from './control-client/SortButton';
import Spinner from 'react-bootstrap/Spinner';
import { AppError } from './AppError';
import ContentEditable from "react-contenteditable";
import { Typeahead } from 'react-bootstrap-typeahead';
import BirthdaySelector from './control-client/BirthdaySelector';
import SexSelector from './control-client/SexSelector';
import EmailControl from './control-client/EmailControl';
import PhoneControl from './control-client/PhoneControl';
import NameControl from './control-client/NameControl';
import ClientDeals from './control-client/ClientDeals';
import { useGetSelfAccountQuery, useGetPermissionsQuery } from '../api/accountApi';
import { useGetCustomerQuery, useGetTagsQuery } from '../api/clientApi';
import { getExpanded } from '../redux/modal/modalSlice';
import { setUser } from '../redux/settings/settingsSlice';

const Client = () => {
    const expanded = useSelector(getExpanded);
    const history = useHistory();
    const { clientId } = useParams<{clientId: string}>();
    const dispatch = useDispatch();
    const newClientStatus = useSelector(getNewClientStatus);
    const activeClient = useSelector(getActiveClient)
    const editMode = useSelector(getEditMode);

    const [singleSelections, setSingleSelections] = useState([]);

    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
    const { data: tags } = useGetTagsQuery()
    const { data: client, isSuccess: isClientSuccess } = useGetCustomerQuery(clientId, { skip: !clientId || clientId == "0" })
	const { data: permissions, isSuccess: isPermissionsSuccess } = useGetPermissionsQuery()

    useEffect(
        () => {
            if (isAccountSuccess) {
                dispatch(setUser(account))
            }
        }, [account, dispatch, isAccountSuccess]
    )

    useEffect(
        () => {
            if (isPermissionsSuccess && !permissions?.clients.view) {
                history.push("/profile")
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [account]
    )

    useEffect(
        () => {
            if (newClientStatus?.id) {
                history.push(`/client/${newClientStatus.id}`)
                dispatch(clearNewClientFlag())
            }            
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [newClientStatus]
    );

    useEffect(
        () => {
            if (clientId != "0") {
                dispatch(activeClientLoaded(client))
                dispatch(setEditModeOff());
            } else {
                dispatch(activeClientLoaded({ id: 0, tags: [] }))
                dispatch(setEditModeOn());
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [client, clientId]
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleAddTag = useCallback((e: any[]) => {
        let newTag = e
        if (e[0].name) newTag = [e[0].name]
        const newTags = activeClient?.tags?.concat(newTag);
        dispatch(clientUpdated({ tags: newTags }));
        setSingleSelections([]);
    }, [activeClient?.tags, dispatch]);

    const handleDeleteTag = useCallback((index: number) => {
        const newTags = activeClient?.tags?.filter((_, i) => i !== index )
        dispatch(clientUpdated({ tags: newTags }));
    }, [activeClient?.tags, dispatch]);

    return (
        <div className={expanded ? "tarot-content-client-expanded" : "tarot-content-client"}>
            {(!isClientSuccess && clientId != "0") ?
            <div id="tarology-spinner" data-testid="tarology-spinner">
                <Spinner animation="border" />
            </div>
            :
            <>
            <div className="content mt-3 pt-3" id="customer-profile">
                <div className='profile-section-name'>
                    <NameControl className="profile-title mt-3" client={activeClient} />
                    <div className="tarot-client-icon-bed">
                        <FaPencilAlt size={20} className="tarot-client-icon" 
                            data-testid='tarot-edit-mode-selector'
                            onClick={() => { dispatch(switchEditMode()) }}/> 
                        {activeClient?.favorite ?
                        <LuPin size={30} className="tarot-client-icon" 
                            data-testid='tarot-favicon'
                            onClick={() => dispatch(clientSwapFavorite())} />
                        :
                        <LuPinOff size={30} className="tarot-client-icon" 
                            data-testid='tarot-favicon'
                            onClick={() => dispatch(clientSwapFavorite())} />}
                    </div>
                </div>
                <div className="profile-section">
                    <Row>
                        <Col>
                            <AppError module="SexSelector">
                                <SexSelector client={activeClient} />
                            </AppError>
                            <AppError module="BirthdaySelector">
                                <BirthdaySelector client={activeClient} />
                            </AppError>
                            <AppError module="EmailControl">
                                <EmailControl client={activeClient} />
                            </AppError>
                            <AppError module="PhoneControl">
                                <PhoneControl client={activeClient} />
                            </AppError>
                        </Col>
                        <Col>
                            <ContentEditable
                                html={activeClient?.comment || ""}
                                disabled={!editMode}
                                className="tarot-client-comments"
                                onChange={(e) => dispatch(clientUpdated({ comment: e.target.value.replace("<br>", "") }))}
                            /> 
                            <div className='tarot-client-tags'>
                                {activeClient?.tags?.map((tag, index) => {
                                    return(
                                        <span key={index}>
                                            {tag} 
                                            &nbsp;
                                            <IoIosClose onClick={() => handleDeleteTag(index)}/>
                                        </span>
                                    )
                                })}
                            </div>
                            {editMode && 
                                <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    onChange={handleAddTag}
                                    options={tags || []}
                                    allowNew={true}
                                    placeholder="Тег"
                                    newSelectionPrefix="Новый тег: "
                                    selected={singleSelections}
                                    className="client-tag-edit"
                                />
                            }
                        </Col>
                    </Row>
                </div>

                <div className='deals-section'>
                    <Row>
                        <Col className='tarot-client-buttons'>
                            <div className="mt-3">
                                <AppError module="SortButtons">
                                    <SortButton field="date" />
                                    <SortButton field="deal_type" />
                                </AppError>
                            </div>
                        </Col>
                    </Row>

                    <AppError module="ClientDeals">
                        <ClientDeals client={activeClient} />
                    </AppError>
                </div>
            </div>
        </>}
        </div>
    );
}

export default Client;