import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useRegisterMutation } from '../api/authApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const Register = () => {
    const [step, setStep] = useState("register");
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [email, setEmail] = useState("")
    const [country, setCountry] = useState("russia")
    const [passwordError, setPasswordError] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const history = useHistory();

    const [register, { }] = useRegisterMutation()

    useEffect(
        () => {
            const status = localStorage.getItem("registration-status")
            if (status == "confirmation") setStep(status)        
        },[]
    )

    useEffect(
        () => {
            if (password != password2) {
                setPasswordError(true)
            } else {
                setPasswordError(false)
            }
        }, [password, password2]
    )

    return (
        <>
        <div className="splash-body" style={{ background: `url("/image/tarology-bg.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <div className='splash-card'>
                <div className='splash-logo'>
                    <Image src='/image/tarology.svg' fluid />
                </div>
                <p className='tarot-intro-title'>Регистрация новой учетной записи</p>
            {step == "register" ?
            <>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Имя</small></p>
                    <input 
                        type="text" 
                        value={name}
                        className="tarot-modal-input"
                        onChange={(e) => setName(e.target.value)}
                    />
                </Col>
                <Col>
                    <p className='tarot-modal-p'><small>Фамилия</small></p>
                    <input 
                        type="text" 
                        value={surname}
                        className="tarot-modal-input"
                        onChange={(e) => setSurname(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Имя пользователя</small></p>
                    <input 
                        type="text" 
                        value={username}
                        className="tarot-modal-input"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>E-mail</small></p>
                    <input 
                        type="text" 
                        value={email}
                        className="tarot-modal-input"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Страна</small></p>
                    <select
                        className="tarot-modal-select"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    >
                        <option value="austria">Австрия</option>
                        <option value="azerbaijan">Азербайджан</option>
                        <option value="albania">Албания</option>
                        <option value="andorra">Андорра</option>
                        <option value="armenia">Армения</option>
                        <option value="belgium">Бельгия</option>
                        <option value="belarus">Белоруссия</option>
                        <option value="bulgaria">Болгария</option>
                        <option value="bosnia">Босния и Герцеговина</option>
                        <option value="vatican">Ватикан</option>
                        <option value="uk">Великобритания</option>
                        <option value="hungary">Венгрия</option>
                        <option value="germany">Германия</option>
                        <option value="greece">Греция</option>
                        <option value="georgia">Грузия</option>
                        <option value="denmark">Дания</option>
                        <option value="israel">Израиль</option>
                        <option value="ireland">Ирландия</option>
                        <option value="iceland">Исландия</option>
                        <option value="spain">Испания</option>
                        <option value="italy">Италия</option>
                        <option value="kazakhstan">Казахстан</option>
                        <option value="canada">Канада</option>
                        <option value="cyprus">Кипр</option>
                        <option value="china">Китай</option>
                        <option value="latvia">Латвия</option>
                        <option value="lithuania">Литва</option>
                        <option value="liechtenstein">Лихтенштейн</option>
                        <option value="luxembourg">Люксембург</option>
                        <option value="malta">Мальта</option>
                        <option value="moldova">Молдавия</option>
                        <option value="monaco">Монако</option>
                        <option value="netherlands">Нидерланды</option>
                        <option value="norway">Норвегия</option>
                        <option value="poland">Польша</option>
                        <option value="portugal">Португалия</option>
                        <option value="russia">Россия</option>
                        <option value="romania">Румыния</option>
                        <option value="sanmarino">Сан-Марино</option>
                        <option value="macedonia">Северная Македония</option>
                        <option value="serbia">Сербия</option>
                        <option value="slovakia">Словакия</option>
                        <option value="slovenia">Словения</option>
                        <option value="usa">Соединенные Штаты Америки</option>
                        <option value="turkey">Турция</option>
                        <option value="ukraine">Украина</option>
                        <option value="finland">Финляндия</option>
                        <option value="france">Франция</option>
                        <option value="chroatia">Хорватия</option>
                        <option value="montenegro">Черногория</option>
                        <option value="czech">Чехия</option>
                        <option value="switzerland">Швейцария</option>
                        <option value="sweden">Швеция</option>
                        <option value="estonia">Эстония</option>
                        <option value="japan">Япония</option>
                    </select>
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Пароль</small></p>
                    <input 
                        type="password" 
                        value={password}
                        className="tarot-modal-input"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Col>
                <Col>
                    <p className='tarot-modal-p'><small>Подтверждение пароля</small></p>
                    <input 
                        type="password" 
                        value={password2}
                        className="tarot-modal-input"
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                </Col>
            </Row>
            {passwordError &&
            <Row id="subscribe-confirm-body">
                <Col>
                    <center>
                        <p className='tarot-modal-p-error'>
                            <small>Пароли не совпадают!</small>
                        </p>
                    </center>
                </Col>
            </Row>}
            <Row id="subscribe-confirm-body">
                <Col>
                    <p className='tarot-modal-p-sub'>
                        <small>
                            <span className='modal-registration-link'
                                onClick={() => { history.push(`/login`) }}                            
                            >&laquo; Назад ко входу</span>
                        </small>
                    </p>
                </Col>
            </Row>
            {error &&
            <Row id="subscribe-confirm-body">
                <Col>
                    <center>
                        <p className='tarot-modal-p-error'>
                            <small>{error}</small>
                        </p>
                    </center>
                </Col>
            </Row>}

            <Button className="tarot-intro-button tarot-sex-button" onClick={async () => {
                    try {
                        await register({ 
                            name: name, 
                            surname: surname, 
                            email: email,
                            country: country,
                            user_name: username, 
                            password: password 
                        }).unwrap()
                        setError(null)
                        setStep("confirm")
                        localStorage.setItem('registration-status', "confirmation")
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } catch (err: any) {
                        const error = err as FetchBaseQueryError
                        if (error.status == 409) {
                            setError("Учетная запись с таким адресом электронной почты уже зарегистрирован. Забыли пароль?")
                        } else {
                            setError("Что-то пошло не так...")
                        }
                    }
                }} 
                data-testid="modal-button-ok">
                Регистрация
            </Button>
            </>
            :
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'>
                        <small>
                            Вы успешно зарегистрированы, остался последний шаг - подтвердить адрес 
                            электронной почты. Пожалуйста проверьте почтовый ящик, указанный при 
                            регистрации и пройдите по ссылке, указанной в письме от Тарологии.
                            Если вы не можете найти письмо - возможно надо проверить папку &quot;СПАМ&quot;.
                        </small>
                    </p>
                </Col>
            </Row>}
            </div>
        </div>
        </>
    )
}

export default Register;