import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';

export const tooltip = (message: string) => (
    <Tooltip id="tooltip" style={{ backgroundColor: "transparent" }} >
        {message.split("|").map(message => {
            return (<div key={message}>{message}</div>)
        })}
    </Tooltip>
)
