import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useReminderMutation, useConfirmReminderMutation,
        useChangePasswordMutation } from '../api/authApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const Login = () => {
    const query = useLocation()
    const [email, setEmail] = useState("")
    const [step, setStep] = useState("sendMail");
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [token, setToken] = useState<string | null>(null)
    const [passwordError, setPasswordError] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const history = useHistory();

    const [reminder] = useReminderMutation();
    const [confirmReminder] = useConfirmReminderMutation();
    const [changePassword] = useChangePasswordMutation();

    useEffect(() => {
        async function fetchData() {
            let search = query.search;
            if (search.charAt(0) === "?") { search = query.search.substring(1) }
            const attr = search.split("=")
            const token = attr[1];
            if (token && token != "") {
                setToken(token)

                try {
                    await confirmReminder({ token: token }).unwrap()
                    setStep("tokenConfirmed")
                    setError(null)
                } catch (err) {
                    const error = err as FetchBaseQueryError
                    if (error.status == 401) {
                        setError("Токен некорректный или устарел.")
                    } else {
                        setError("Что-то пошло не так...")
                    }
                }    
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(
        () => {
            if (password != password2) {
                setPasswordError(true)
            } else {
                setPasswordError(false)
            }
        }, [password, password2]
    )

    return (
        <>
        <div className="splash-body" style={{ background: `url("/image/tarology-bg.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <div className='splash-card'>
                <div className='splash-logo'>
                    <Image src='/image/tarology.svg' fluid />
                </div>
                <p className='tarot-intro-title'>Восстановление пароля</p>
                {step == "sendMail" ?
                <>
                <Row className="tarot-intro-row">
                    <Col>
                        <p className='tarot-modal-p'><small>Для восстановления доступа к учетной записи
                            введите адрес e-mail, который был указан при регистрации.</small></p>
                    </Col>
                </Row>
                <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>E-mail</small></p>
                    <input 
                        type="text" 
                        value={email}
                        className="tarot-modal-input"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Col>
                </Row>
                <Row id="subscribe-confirm-body">
                <Col>
                    <p className='tarot-modal-p-sub'>
                        <small>
                            <span className='modal-registration-link'
                                onClick={() => { history.push(`/login`) }}                            
                            >&laquo; Назад ко входу</span>
                        </small>
                    </p>
                </Col>
                </Row>
            {error &&
            <Row id="subscribe-confirm-body">
                <Col>
                    <center>
                        <p className='tarot-modal-p-error'>
                            <small>{error}</small>
                        </p>
                    </center>
                </Col>
            </Row>}
            <Button className="tarot-intro-button tarot-sex-button" onClick={async () => {
                    try {
                        await reminder({ email: email }).unwrap()
                        setStep("mailSent")
                        setError(null)
                    } catch (err) {
                        const error = err as FetchBaseQueryError
                        switch (error.status) {
                            case 401:
                                setError("Пользователь с таким e-mail не зарегистрирован.")
                                break;
                            default:
                                setError("Что-то пошло не так...")
                        }
                    }
                }} 
                data-testid="modal-button-ok">
                Отправить
            </Button>
            </>
            :
            step == "tokenConfirmed" ?
            <>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Установите новый пароль.</small></p>
                </Col>
            </Row>
            <Row className="tarot-intro-row">
            <Col>
                    <p className='tarot-modal-p'><small>Пароль</small></p>
                    <input 
                        type="password" 
                        value={password}
                        className="tarot-modal-input"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Подтверждение пароля</small></p>
                    <input 
                        type="password" 
                        value={password2}
                        className="tarot-modal-input"
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                    <p></p>
                </Col>
            </Row>
            {passwordError &&
            <Row id="subscribe-confirm-body">
                <Col>
                    <center>
                        <p className='tarot-modal-p-error'>
                            <small>Пароли не совпадают!</small>
                        </p>
                    </center>
                </Col>
            </Row>}
            {error &&
            <Row id="subscribe-confirm-body">
                <Col>
                    <center>
                        <p className='tarot-modal-p-error'>
                            <small>{error}</small>
                        </p>
                    </center>
                </Col>
            </Row>}

            <Button className="tarot-intro-button tarot-sex-button" onClick={async () => {
                    try {
                        await changePassword({ 
                            token: token, 
                            password: password 
                        }).unwrap()
                        setError(null)
                        history.push("/login")
                    } catch (err) {
                        const error = err as FetchBaseQueryError
                        if (error.status == 409) {
                            setError("Учетная запись с таким адресом электронной почты уже зарегистрирован. Забыли пароль?")
                        } else {
                            setError("Что-то пошло не так...")
                        }
                    }
                }} 
                data-testid="modal-button-ok">
                Отправить
            </Button>
            </>
            :
            <>
                <Row className="tarot-intro-row">
                    <Col>
                        <p className='tarot-modal-p'><small>Выслано письмо с инструкцией 
                            по восстановлению доступа.</small></p>
                    </Col>
                </Row>
            </>
            }
            </div>
        </div>
        </>
    )
}

export default Login;