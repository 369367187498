import React, { useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { tooltip } from '../toolbar/ToolbarTooltip';
import { FaTrashAlt } from "react-icons/fa";
import { Client } from '../../types/Client';
import { showConfirmDelete, showConfirmDeleteClient } from '../../redux/modal/modalSlice';
import { useDispatch } from 'react-redux';
import { useGetPermissionsQuery } from '../../api/accountApi';
import { Deal } from '../../types/Deal';

type Props = {
    path: string;
    activeClient?: Client;
    deal?: Deal;
}

const DeleteButton = ({ path, activeClient, deal }: Props) => {
    const dispatch = useDispatch();
	const { data: permissions } = useGetPermissionsQuery()

    const deleteActive = useCallback(() => {
        let section = path?.split('/')[1]
        switch (section) {
            case "clients":
                return false
            case "client":
                if (activeClient?.id != "0") return true
                return false
            case "deal":
                if (permissions?.deals.save) {
                    return true
                }
                return false
            case "profile":
                return false
            default:
                return true
        }
    }, [activeClient?.id, path, permissions?.deals.save]);

    const handleDeleteButton = useCallback(() => {
        let section = location?.pathname?.split('/')[1]
        switch (section) {
            case "client":
                handleDeleteClient()
                break;
            case "deal":
                handleDeleteDeal()
                break;
        }
    }, []);

    const handleDeleteClient = useCallback(() => {
        if (activeClient?.id && activeClient?.id != "0") {
            dispatch(showConfirmDeleteClient())
        }
    }, []);

    const handleDeleteDeal = useCallback(() => {
        if (deal?.id && deal?.id != "0") {
            dispatch(showConfirmDelete())
        }
    }, []);

    return (
        <div className={`toolbar-button ${!deleteActive() ? "disabled" : ""}`} onClick={() => handleDeleteButton()}>
            <OverlayTrigger placement="bottom" overlay={tooltip("Удалить")}>
                <div>
                    <FaTrashAlt />
                </div>
            </OverlayTrigger>
        </div>
    );    
}

export default DeleteButton;