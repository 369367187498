import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveQuestion, setActiveDealQuestion, 
    setActiveExtraQuestion } from '../../redux/deals/dealsSlice';
import { hideQuestion, /*switchQuestionEditMode,*/ getQuestion } from '../../redux/modal/modalSlice';
// import { FaPencilAlt } from 'react-icons/fa';
import Floating from './Floating';

const DealQuestion = () => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const dispatch = useDispatch();
    const questionState = useSelector(getQuestion)
    const activeQuestion = useSelector(getActiveQuestion)

    useEffect(
        () => {
            if (questionState?.visible && inputRef.current) {
                inputRef.current.focus();
            }
        }, [questionState?.visible]
    )

    const handleChangeQuestion = useCallback((e: { target: { value: string; }; }) => {
        if (questionState?.extra?.position == "-1" && questionState?.extra?.index == -1) {
            dispatch(setActiveDealQuestion(e.target.value))
        } else {
            dispatch(setActiveExtraQuestion({ extra: questionState?.extra, question: e.target.value}))
        }
    }, [dispatch, questionState?.extra]);

    return (
        <>
        {questionState?.visible == true &&
            <Floating close={() => dispatch(hideQuestion())} h={250} 
                dataTestid="tarot-floating-question"
                title={(questionState?.extra?.position == "-1" && questionState?.extra?.index == -1) ? "Вопросы к раскладу" : "Вопросы к Даат"}>
                <>
                    {/* <div className='float-buttons-top'>
                        <div className='float-button' 
                            onClick={() => dispatch(switchQuestionEditMode())}
                            data-testid="tarot-edit-question-button">
                            <FaPencilAlt />
                        </div>
                    </div>
                    {questionState.editMode ? */}
                        <textarea 
                            ref={inputRef}
                            value={activeQuestion} 
                            rows={10}
                            onChange={handleChangeQuestion}
                            data-testid="tarot-edit-question-textarea"
                        />
                        {/* :
                        <div className='float-question'>
                            {activeQuestion || ""}
                        </div>
                    } */}
                </>
            </Floating>
        }
        </>
    )
}

export default DealQuestion;