import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Price } from '../types/Price';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getSubscribeVisible, hideSubscribe } from '../redux/modal/modalSlice';
import { Product } from '../types/Product';

type Props = {
    price: Price | null; 
    product: Product | null;
    subscribe: () => Promise<void>;
}

const SubscribeModal = ({ price, product, subscribe }: Props) => {
    const dispatch = useDispatch();

    const visible = useSelector(getSubscribeVisible)
    const [termsVisible, setTermsVisible] = useState(false);

    const handleClose = () => {
        dispatch(hideSubscribe());
    }

    return (
        <Modal show={visible} className="tarology-modal" animation={false} onHide={handleClose} data-testid="subscribe-modal">
        <Modal.Header className="bg-white" closeButton>
            <Modal.Title>Оформить подписку</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row id="subscribe-confirm-body">
                <Col>
                    <p>Вы действительно хотите перейти на подписку уровня <strong>{product?.pretty_name || ""}</strong>?</p>
                    <p>Абонентская плата составит {price?.amount} {price?.currency} в месяц.</p>
                </Col>
            </Row>
            <Row id="subscribe-confirm-terms-h" onClick={() => setTermsVisible(!termsVisible)}>
                <Col>
                    Условия предоставления сервиса {termsVisible ? <FaChevronDown/> : <FaChevronUp />}
                </Col>
            </Row>
            {termsVisible &&
            <Row id="subscribe-confirm-terms">
                <Col>
                    Тут текст
                </Col>
            </Row>}
        </Modal.Body>
        <Modal.Footer>
            <Button  className="tarot-sex-button mr-3" onClick={subscribe} data-testid="subscribe-modal-ok">
                Подтвердить
            </Button>
            <Button className="tarot-red-button" onClick={handleClose}>
                Отменить
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default SubscribeModal;