import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Option } from 'react-bootstrap-typeahead/types/types';

type Props = {
    visible: boolean;
    close: () => void;
    handleChange: (selected: Option[]) => void;
    cards: string[];
    selected: Option[];
}

const ref_suites = [
    'СКИПЕТРОВ', 
    'ЖЕЗЛОВ', 
    'КУБКОВ', 
    'ЧАШ', 
    'МЕЧЕЙ', 
    'ПЕНТАКЛЕЙ', 
    'ДИСКОВ'
]

const ref_arcanes = [
    "КОРОЛЬ",
    "КОРОЛЕВА",
    "ДАМА",
    "ПРИНЦ",
    "РЫЦАРЬ",
    "ПРИНЦЕССА",
    "ПАЖ",
    "ДЕСЯТКА",
    "ДЕВЯТКА",
    "ВОСЬМЕРКА",
    "СЕМЕРКА",
    "ШЕСТЕРКА",
    "ПЯТЕРКА",
    "ЧЕТВЕРКА",
    "ТРОЙКА",
    "ДВОЙКА",
    "ТУЗ"
]

const ModalChooseCard = ({ visible, close, handleChange, cards, selected } : Props) => { 
    const sortCards = (): string[] => {
        const sortedCards: string[] = []

        const sample = cards.filter(rec => rec.split(" ")[0] == "ТРОЙКА").map(rec => rec.split(" ")[1])
        const sortedSuites: string[] = []
        ref_suites.map(suite => {
            if (sample.includes(suite)) {
                sortedSuites.push(suite)
            }
        })
        let tag = ""

        cards.map(card => {
            if (ref_arcanes.includes(card.split(" ")[0])) {
                if (card.split(" ")[0] != tag) {
                    sortedSuites.map(rec => sortedCards.push(`${card.split(" ")[0]} ${rec}`))
                    tag = card.split(" ")[0]
                }
            } else {
                sortedCards.push(card)
            }
        })

        return sortedCards
    }

    return (
        <Modal size={"sm"} show={visible} animation={false} 
            id="tarot-select-modal" onHide={close}>
            <Modal.Body data-testid="tarot-search-card-body">
            <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                onChange={handleChange}
                options={sortCards()}
                placeholder="Начните вводить название карты"
                selected={selected}
                autoFocus={true}
                highlightOnlyResult={true}
                className="mb-3"
            />
            </Modal.Body>
        </Modal>
    )
}

export default ModalChooseCard;