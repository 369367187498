import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientUpdated, getEditMode } from '../../redux/client/clientSlice';
import { Client } from '../../types/Client';
import Form from 'react-bootstrap/Form';

type Props = {
    client: Client;
    className: string;
}

const NameControl = ({ client, className } : Props): React.ReactNode => {
    const dispatch = useDispatch();
    const editMode = useSelector(getEditMode);

    return (
        <div className={`tarot-name-control ${className}`} data-testid="tarot-name-control">
            {editMode?
            <Form.Control 
                type="text" 
                placeholder="Имя клиента"
                className="mb-3 tarot-client-name"
                value={client.name ? client.name : ""}
                onChange={(e) => dispatch(clientUpdated({ id: client?.id, name: e.target.value }))}
                autoFocus 
            />
            :
            <div className="mb-3 tarot-client-name">
                {client?.name ? client?.name : "Новый клиент"}
            </div>}
        </div>
    )
}

export default NameControl;