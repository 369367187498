import React, { useEffect } from 'react';
import {Redirect, Switch, Route, useHistory, useLocation } from "react-router-dom";
import Sidebar from './components/Sidebar';
import Toolbar from './components/Toolbar';
import { socketAuthenticated } from './redux/socket/socket';
import useRoutes from './routes';
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector, useDispatch } from 'react-redux';
import { setAuthenticated, selectIsAuthenticated, getSettingsStatus} from './redux/settings/settingsSlice';
import ConfirmDelete from './modals/ConfirmDelete';
import ConfirmDeleteClientModal from './modals/ConfirmDeleteClientModal';
import Alert from './modals/Alert';
import SocketConnection from './SocketConnection';

import './css/tile.css';
import './css/tarot.css'

const App = (props) => {
	let location = useLocation();

	// const [expanded, setExpanded] = useState(false);
    const history = useHistory();

	const queryClient = new QueryClient();
	const routes = useRoutes(props);

    const dispatch = useDispatch();
	const isAuthenticated = useSelector(selectIsAuthenticated); 
    const settingsStatus = useSelector(getSettingsStatus);

	// eslint-disable-next-line no-undef
	const { TAROT_SOCKET_URL } = process.env;

	useEffect(
		() => {
			// eslint-disable-next-line no-undef
			let accessToken = localStorage.getItem("accessToken")
			if (accessToken) {
				dispatch(setAuthenticated(true))
			} else {
				if (!isAuthenticated && 
					location.pathname != "/register" && 
					location.pathname != "/confirm" &&
					location.pathname != "/reminder") {
					history.push(`/login`)
				} 
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isAuthenticated]
	)

	useEffect(() => {
		dispatch(socketAuthenticated())
    }, [settingsStatus, dispatch])

	return (
		<SocketConnection host={`${TAROT_SOCKET_URL}`} >
			<QueryClientProvider client={queryClient}>
				<Switch>
					{routes.map((route, index) => {
						if (route.redirect == null) {
							return(
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									render={(/*props*/) => {
										return(
											<>
											{route.sidebar ?
											<div className="wrapper">
												<Toolbar />
												<div className="wrapper-right">
													<Sidebar />
													<div className='content'>
														{route.main}
													</div> 
												</div>
											</div>
											:
											<>
												{route.main}
											</>
											}
											</>
										)
									}}
								/>
							)
						} else {
							return(
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
								>
									{route.redirect != null &&
									<Redirect to={route.redirect} />}
								</Route>
							)
						}
					})}
				</Switch>

				<ConfirmDelete />
				<ConfirmDeleteClientModal />
				<Alert />
			</QueryClientProvider>
		</SocketConnection>
	);
}

export default App;