import React, { useCallback } from 'react';
import Floating from './Floating';
import { Permissions } from '../../types/Account';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveDeal, selectCommentsEditable, removeCommentEditable, 
    addComment, addCommentEditable, updateComment } from '../../redux/deals/dealsSlice';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import * as utils from '../../helpers/utils';
import { FaPencilAlt } from "react-icons/fa";
import { TbPencilPlus } from 'react-icons/tb';
import { getComments, hideComments } from '../../redux/modal/modalSlice';

type Props = {
    permissions?: Permissions;
}

const DealComments = ({ permissions }: Props) => {
    const dispatch = useDispatch();
    const deal = useSelector(getActiveDeal);
    const commentsEditable = useSelector(selectCommentsEditable)
    const commentsState = useSelector(getComments)

    const handleAddComment = useCallback(() => {
        let maxId = 0
        deal.comments.map(comment => {
            if (comment?.id && comment?.id > maxId) { maxId = comment.id }
        })
        // const newIds = deal.comments.filter(rec => rec.id?.toString().substring(0, 6) == "new-id")
        // let newId = 0
        // newIds.map((comment) => {
        //     if (comment.id) {
        //         const tmp = parseInt(comment.id.split("_")[1])
        //         if (tmp > newId) { newId = tmp }    
        //     }
        // })
        const newId = maxId + 1
        dispatch(addComment({ id: newId, text: "", created_at: new Date().toJSON(), editable: true }));
        dispatch(addCommentEditable(newId));
    }, [deal.comments, dispatch]);

    const handleCommentEditableChange = useCallback((id: number) => {
        if(permissions?.deals.save) {
            const flag = commentsEditable.find(rec => rec == id);
            if (flag === undefined) {
                dispatch(addCommentEditable(id))
            } else {
                dispatch(removeCommentEditable(id))
            }
        }
    }, [commentsEditable, dispatch, permissions?.deals.save]);
    
    const handleEditComment = useCallback((e: ContentEditableEvent, id: number) => {
        const newComment = { ...deal.comments.find(rec => rec.id == id) };
        if (e.target.value) {
            newComment.text = e.target.value;
            dispatch(updateComment(newComment));    
        }
    }, [deal.comments, dispatch]);
    
    return (
        <>
            {commentsState?.visible == true &&
                <Floating close={() => dispatch(hideComments())} h={250} title="Комментарии таролога" dataTestid="tarot-comments-float">
                    {deal && deal.comments &&
                        <div style={{ width: "100%" }}>
                            {deal.comments.map((comment, index) => {
                                const editable = commentsEditable.filter(rec => rec == comment.id).length > 0;
                                console.log(comment)
                                if (comment.id) {
                                    return (
                                        <blockquote id="comment" className="tarot-comment" key={index}>
                                            <div className="comment-text" data-testid="comment-text">
                                                <ContentEditable
                                                    html={comment.text || ""}
                                                    disabled={!editable}
                                                    onChange={(e) => handleEditComment(e, comment.id!)}
                                                    autoFocus={true}
                                                /> 
                                            </div>
                                            <div className="comment-date">{utils.formatDateTime(comment.created_at || "")}</div>
                                            <div className="comment-date">{comment.edit && `Отреактировано ${utils.formatDateTime(comment.edit)}`}</div>
                                            <div className="comment-button" data-testid="comment-edit-button" 
                                                onClick={() => handleCommentEditableChange(comment.id!)}>
                                                <FaPencilAlt />
                                            </div>
                                        </blockquote>
                                    );    
                                }
                            })}
                            <div className="tarology-comment-add" data-testid="comment-add-button">
                                <TbPencilPlus onClick={handleAddComment} />
                            </div>
                        </div>
                    }
                </Floating>
            }
        </>
    )
}

export default DealComments;