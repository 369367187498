import React from 'react';
import { useSelector } from 'react-redux';
import ClientDeal from './ClientDeal';
import Spinner from 'react-bootstrap/Spinner';
import { getDealSorts, selectDealsSortParams } from '../../redux/client/clientSlice';
import { Client } from '../../types/Client';
import { useGetClientDealsQuery } from '../../api/dealApi';
import { Deal } from '../../types/Deal';
import { Sort } from '../../types/Paging';

type Props = {
    client: Client;
}

const ClientDeals = ({ client }: Props) => {
    const sort = useSelector(selectDealsSortParams);
    const sorts = useSelector(getDealSorts);

    const { data: deals, isSuccess: isDealsSuccess, isLoading } = useGetClientDealsQuery({ clientId: client?.id, sort: sort }, { skip: (!client?.id || client?.id == "-1") })    

    const getSortedDeals = (deals: Deal[], sorts: Sort[]): Deal[] => {
        const tmpDeals = deals.slice()
        if (deals.length > 0) {
            sorts.map(sort => {
                switch(sort.field) {
                    case "date":
                        switch(sort.order) {
                            case "asc":
                                tmpDeals.sort((a, b) => ((a.date || "") > (b.date || "")) ? 1 : -1)
                                break;
                            case "desc":
                                tmpDeals.sort((a, b) => ((a.date || "") > (b.date || "")) ? -1 : 1)
                                break
                        }    
                        break;
                    case "deal_type":
                        switch(sort.order) {
                            case "asc":
                                tmpDeals.sort((a, b) => ((a.deal_type?.title || "") > (b.deal_type?.title || "")) ? 1 : -1)
                                break;
                            case "desc":
                                tmpDeals.sort((a, b) => ((a.deal_type?.title || "") > (b.deal_type?.title || "")) ? -1 : 1)
                                break
                        }    
                        break;
                }
            })    
        }
        return tmpDeals
    }

    return (
        <div className="tarot-client-deal-list" data-testid="tarot-client-deal-list">
            {isLoading ?
            <div id="tarology-spinner" data-testid="tarology-spinner">
                <Spinner animation="border" />
            </div>
            :
            isDealsSuccess ?
            <>
                {getSortedDeals(deals, sorts).map((deal, index: number) => {
                    return(
                        <ClientDeal key={index} deal={deal} />
                    );
                })}
            </>
            :
            <></>}
        </div>
    )
}

export default ClientDeals;