import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { clientUpdated, getEditMode } from '../../redux/client/clientSlice';
import { Client } from '../../types/Client';
import * as utils from '../../helpers/utils';

import "react-datepicker/dist/react-datepicker.css";

type Props = {
    client: Client;
}

const BirthdaySelector = ({ client }: Props) => {
    registerLocale('ru', ru);
    const dispatch = useDispatch();
    const editMode = useSelector(getEditMode);

    return (
        <div id="birthday" data-testid="tarot-birthday-selector">
            <div className="birtday-label"><b>Дата рождения:</b></div>
            {editMode?
            <DatePicker 
                locale="ru"
                dateFormat="dd/MM/yyyy"
                className="datepicker"
                // style={{ display: "inline" }}
                selected={client && client.birthday ? new Date(client.birthday) : new Date()} 
                onChange={(date) => dispatch(clientUpdated({ id: client?.id, birthday: date?.toJSON() }))}
            />
            :
            <span style={{ marginLeft: "10px" }}>{utils.formatDate(new Date(client?.birthday || ""))}</span>}
        </div>
    )
}

export default BirthdaySelector;