import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FaAngleDown, FaRegPlusSquare } from 'react-icons/fa';
import { setActiveExtra2 } from '../../redux/deals/dealsSlice';
import { ContextMenuState } from './DealTree';
// import { showQuestion } from '../../redux/modal/modalSlice';
import { ExtraDealType } from '../../types/ExtraDealType';
import { Point } from '../../types/Geometry';
import { ExtraDef } from '../../types/ExtraDef';
import { showQuestion } from '../../redux/modal/modalSlice';

type Props = {
    setContextMenuState: (state: ContextMenuState) => void;
    setMenuCoords: (coords: Point) => void;
    type?: ExtraDealType;
    level: number;
    index: number;
    state: ContextMenuState;
    target?: string; 
    height: number;
    width: number;
    expanded: boolean;
    question: string;
    long?: boolean;
}

const TreeExtra = ({ state, level, type, index, target, setContextMenuState, setMenuCoords,
    height, width, expanded, question, long }: Props) => {    
    const dispatch = useDispatch();

    const goToExtra = useCallback((position?: string, index?: number) => {
        let target = "-1"
        let extra = -1
        if (position) {
            target = position
        }
        if (index || index == 0) {
            extra = index
        }
        if (type) {
            dispatch(setActiveExtra2({ position: target, index: extra }, type, width, height, expanded, false, long || false))
            // dispatch(showQuestion())    
        }
    }, [dispatch, expanded, height, long, type, width]);

    const handleShowExtraQuestion = useCallback((extra: ExtraDef) => {
        dispatch(showQuestion(extra))
    }, [dispatch]);

    const classLevel = useCallback((level: number) => {
        switch(level) {
            case 1:
                return "tree-level-1-5"
            case 2:
                return "tree-level-2"
            default:
                return ""
        }
    }, []);

    const handleTreeContext = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>, target?: string, extra?: number) => {
        event.preventDefault()
        setContextMenuState({
            visible: !state.visible,
            type: "extra",
            target: target || "-1",
            extra: (extra || extra == 0) ? extra : -1,
            extra_target: null
        })
        setMenuCoords({ x: event.clientX, y: event.pageY })
    }, [setContextMenuState, setMenuCoords, state.visible]);

    return (
        <div>
            <div className={classLevel(level)} onClick={() => goToExtra(target, index)}
                data-testid="tarot-tree-extra-section"
                onContextMenu={e => handleTreeContext(e, target, index)}>
                <div className='tree-chevron'>
                    <FaAngleDown />
                </div>
                <div className='extra-deal'>
                    <FaRegPlusSquare />
                </div>
                <div className='line'>
                    {type?.title}
                </div>
            </div>
            <div className={`line question-${level}`} onDoubleClick={() => handleShowExtraQuestion({ position: target, index: index })}>
                {question == "" ? "Вопрос к Даат" : question}
            </div>
        </div>
    )
}

export default TreeExtra;