const prepareHeaders = (token?: string | null) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    if (token && token != "") {
        requestHeaders.set('Authorization', 'Bearer ' + token);
    }

    return requestHeaders;
}

export default prepareHeaders;