import React from 'react'
import useCanvas from '../hooks/useCanvas'
// import useOnClickOutside from '../hooks/useOnClickOutside';

type Props = {
    draw: (ctx: CanvasRenderingContext2D) => void; 
    onClick: (event: React.MouseEvent<HTMLElement>, ref: React.RefObject<HTMLCanvasElement>) => void;
    onContextMenu: (event: React.MouseEvent<HTMLElement>, ref: React.RefObject<HTMLCanvasElement>) => void;
    onMouseMove: (event: React.MouseEvent<HTMLElement>, ref: React.RefObject<HTMLCanvasElement>) => void;
    onMouseLeave: () => void;
    dataTestid: string;
}

const Canvas = ({ draw, onClick, onContextMenu, onMouseMove, onMouseLeave, dataTestid }: Props) => {  
    const canvasRef = useCanvas(draw)
    // useOnClickOutside(canvasRef, (e) => { if (onClickOutside) { onClickOutside(e) } });

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        onClick(e, canvasRef);
    }

    const handleLongPress = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        onContextMenu(e, canvasRef);
    }

    const handleCanvasMouseMove = (e: React.MouseEvent<HTMLElement>) => {
        onMouseMove(e, canvasRef)
    }

    const handleOnMouseLeave = () => {
        onMouseLeave()
    }

    // const handleCanvasMouseMove = (e) => {
    //     if (onMouseMove) {
    //         onMouseMove(e, canvasRef);
    //     }
    // }

    return <canvas ref={canvasRef} onClick={handleClick} 
        onContextMenu={handleLongPress} onMouseMove={handleCanvasMouseMove} /*{...rest}*/ 
        onMouseLeave={handleOnMouseLeave}
        data-testid={dataTestid}/>
}

export default Canvas