import { apiSlice } from '../redux/apiSlice'
import { ExtraDealType } from '../types/ExtraDealType';

const enhancedApi = apiSlice.enhanceEndpoints({
  addTagTypes: ["ExtraDealTypes"],
});

const extraDealTypeApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getExtraDealTypes: builder.query<ExtraDealType[], void>({
        query: () => '/extra-deal-types',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        providesTags: (result, error, id) => [{ type: 'ExtraDealTypes', id: 'LIST' }],
        keepUnusedDataFor: 36000
    }),
    getExtraDealType: builder.query<ExtraDealType[], string>({
        query: extraDealTypeId => `/extra-deal-types/${extraDealTypeId}`,
        providesTags: (result, error, id) => [{ type: 'ExtraDealTypes', id }],
        keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetExtraDealTypeQuery, useGetExtraDealTypesQuery } = extraDealTypeApi