import React, { useRef, JSX } from 'react';
import { animated, useSpring } from "@react-spring/web";
import { useDrag } from '@use-gesture/react';
import { IoMdClose } from "react-icons/io";

type Props = {
    title: string;
    children: JSX.Element;
    close: () => void;
    x0?: number;
    y0?: number;
    h?: number;
    dataTestid: string;
}

const Floating = ({ title, children, close, x0, y0, h, dataTestid }: Props) => {
    const [{ x, y, width, height }, api] = useSpring(() => ({ x: x0 || 100, y: y0 || 400, width: 400, height: h || 600 }));
    const dragEl = useRef(null);

    const bind = useDrag((state) => {
        const isResizing = (state?.event.target === dragEl.current);
        if (isResizing) {
            api.set({
                width: state.offset[0],
                height: state.offset[1]
            })    
        } else {
            api.set({
                x: state.offset[0],
                y: state.offset[1]
            })    
        }
    }, {
        from: (event) => {
            const isResizing = (event.target === dragEl.current);
            if (isResizing) {
                return [width.get(), height.get()];
            } else {
                return [x.get(), y.get()];
            }
        }, 
        bounds: (state) => {
            const isResizing = (state?.event.target === dragEl.current);
            if (isResizing) {
                return {
                    top: 100,
                    left: 100
                }    
            } else {
                return {
                    top: 0,
                    left: 0
                }    
            }
        }
    })

    return (
        <animated.div id="floating" style={{ left: x, top: y, width: width, height: height }} 
            data-testid={dataTestid} {...bind()}>
            <div className="header">
                {title}
                <div className="icon">
                    <IoMdClose color="white" onClick={close} data-testid="tarot-floating-close"/>
                </div>
            </div>
            <div className="body">
                <div className='content'>
                    {children}
                </div>
            </div>
            <div 
                className="resizer"
                style={{ backgroundImage: "url(/image/corner.svg)" }}
                ref={dragEl}
            ></div>
        </animated.div>
    )
}

export default Floating;