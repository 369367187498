import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import Spinner from 'react-bootstrap/esm/Spinner';
import { getActiveDeal, /*setActiveDealType,*/ increaseLimit2, setActiveDealType2 } from '../../redux/deals/dealsSlice';
import { resetGeometry } from '../../redux/geometry/geometrySlice';
import { useGetDealTypeQuery, useGetDealTypesByDeckQuery } from '../../api/dealTypeApi';
import { Permissions } from '../../types/Account';
import useWindowDimensions from '../../useWindowDimensions';
import { getExpanded } from '../../redux/modal/modalSlice';
import { ToastContent } from '../../types/ToastContent';

type Props = {
    permissions?: Permissions;
    disabled: boolean;
    setShowToast: (visible: boolean) => void;
    setToast: (toast: ToastContent) => void;
}

const DealTypeSelector = ({ permissions, disabled, setShowToast, setToast }: Props) => {
    const dispatch = useDispatch();
    const expanded = useSelector(getExpanded);
    const deal = useSelector(getActiveDeal);
    const { data: dealTypes, isLoading } = useGetDealTypesByDeckQuery(deal?.deck?.id, { skip: !deal?.deck?.id })
    const { data: dealType, isError } = useGetDealTypeQuery(deal?.deal_type?.id, { skip: !deal?.deal_type?.id }) 
    const { height, width } = useWindowDimensions();

    useEffect(
        () => {
            if (isError) {
                setToast({
                    title: 'Интернет недоступен',
                    description: "Не удалось связаться с Тарологией, возможно проблемы со связью. Попробуйте повторить позже.",
                    status: 'error'
                })
                setShowToast(true)
                dispatch(resetGeometry()); 
                const long = deal?.deck?.long;
                dispatch(setActiveDealType2(null, width, height, expanded, false, long));
            }
        }, [deal?.deck?.long, dispatch, expanded, height, isError, setShowToast, setToast, width]
    )

    const activeDealTypeName = useCallback((shortened: boolean) => {
        if (deal) {
            let suffix = ""
            if (deal?.deal_type?.id) {
                if (dealType?.title) {
                    if (dealType?.title.length > 12) suffix = "..."
                    return shortened ? dealType?.title.substring(0,12) + suffix : dealType?.title
                }
                if (deal.deal_type?.title) {
                    if (deal.deal_type?.title.length > 12) suffix = "..."
                    return shortened ? deal.deal_type?.title.substring(0,12) + suffix : deal.deal_type?.title
                }
                return shortened ? "Выберите раск..." : "Выберите расклад"
            }
        }
        return shortened ? "Выберите раск..." : "Выберите расклад"
    }, [deal, dealType]);

    const tooltip = (
        <Tooltip id="tooltip" style={{ backgroundColor: "transparent" }}>
            {activeDealTypeName(false)}
        </Tooltip>
    );

    const handleDealTypeSelect = (i: string | null) => {
        const dt = dealTypes?.find(rec => rec.id == i)
        if (dt && !isError) {
            const long = deal?.deck?.long;
            dispatch(resetGeometry()); 
            // dispatch(setActiveDealType(dt));
            dispatch(setActiveDealType2(dt, width, height, expanded, false, long));
            const limit = dt?.initialLimit || dt?.deal_cards?.length
            // dispatch(increaseLimit({ position: "-1", index: -1, type: dt, value: limit }))    
            dispatch(increaseLimit2({ position: "-1", index: -1}, dt, limit || 0, width, height, expanded, long))    
        }
    }

    return(
        <>
            {isLoading 
            ? 
            <Spinner 
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
                data-testid="tarot-save-client-spinner"
            />
            :
            <OverlayTrigger placement="right" overlay={tooltip}>
                <DropdownButton
                    onSelect={handleDealTypeSelect}
                    title={activeDealTypeName(true)}
                    size="sm"
                    disabled={permissions?.deals?.save === undefined ? disabled : (!permissions?.deals?.save || disabled)}
                    className="pb-2 toolbar-dropdown"
                    variant={"primary"}
                    data-testid="tarot-deal-types-selector"
                >
                    {dealTypes?.map((item, index) => {
                        return <Dropdown.Item 
                            key={index} 
                            eventKey={item?.id}
                            data-testid="tarot-deal-type"
                        >
                            {item?.title}
                        </Dropdown.Item>
                    })}
                </DropdownButton>
            </OverlayTrigger>
            }
        </>            
    )
}

export default DealTypeSelector;