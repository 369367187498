import React from 'react';
import ReactMarkdown from 'react-markdown'
import { blockquoteRenderer } from '../helpers/blockquote-renderer';

type Props = {
    article: string;
}

const ArticleText = ({ article }: Props) => {
    return (
        <>
            <ReactMarkdown components={{ 
                    h2: 'h3', 
                    blockquote: ({node}) => { return(blockquoteRenderer(node)) }
                }}
                // eslint-disable-next-line react/no-children-prop
                children={article} 
            />
        </>
    )
}

export default ArticleText;