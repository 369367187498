import { apiSlice } from '../redux/apiSlice'
import type { Client } from '../types/Client';

const enhancedApi = apiSlice.enhanceEndpoints({
  addTagTypes: ["Customers", "Tags"],
});

interface Paging {
  sort: string;
  paging: string | null;
}

interface PagedClients {
  page: number;
  total_pages: number;
  entities: Client[];
}

const clientApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<PagedClients, Paging>({
      query: ({sort, paging}) => `/customers?sort=${sort}&${paging}`,
      providesTags: () => [{ type: 'Customers', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getCustomer: builder.query<Client, string>({
      query: id => `/customers/${id}`,
      providesTags: (result, error, id) => [{ type: 'Customers', id }],
      keepUnusedDataFor: 36000
    }),
    getFavoriteCustomers: builder.query<Client[], void>({
      query: () => `/customers/favorites`,
      providesTags: () => [{ type: 'Customers', id: 'FAVS' }],
      keepUnusedDataFor: 36000
    }),
    getTags: builder.query<Array<string>, void>({
      query: () => `/customers/tags`,
      providesTags: () => [{ type: 'Tags', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    addCustomer: builder.mutation<Client, Client>({
      query(data) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {id, ...body} = data;
        return {
          url: `/customers`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Customers', id: 'FAVS' },
                        { type: 'Customers', id: 'LIST' }]
    }),
    updateCustomer: builder.mutation<Client, Client>({
      query(data) {
        const {id, ...body} = data;
        return {
          url: `/customers/${id}`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: (result, error, { id }) => [ { type: 'Customers', id }, 
                                                    { type: 'Customers', id: 'FAVS' },
                                                    { type: 'Customers', id: 'LIST' }],
    }),
    deleteCustomer: builder.mutation<void, string>({
      query(id) {
        return {
          url: `/customers/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [ { type: 'Customers', id }, 
                                                { type: 'Customers', id: 'FAVS' },
                                                { type: 'Customers', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetCustomerQuery, useGetCustomersQuery, useGetFavoriteCustomersQuery, 
  useGetTagsQuery, useAddCustomerMutation, useUpdateCustomerMutation, 
  useDeleteCustomerMutation } = clientApi