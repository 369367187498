import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserAvatar, setUserEmail, setUserGivenName, setUserFamilyName, 
    setUserPhone, setUserCountry, setUserUsername, selectAccount, 
    selectAvatar, selectIsAuthenticated, getOdStatus, /*getOverdue, */
    getChangeInProgress, /*getAvaChanged,*/ setAvaChanged, switchEditMode,
    getEditMode } from '../redux/settings/settingsSlice';
import { FaUpload, FaPencilAlt, FaSave } from "react-icons/fa";
import { BiZoomIn } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import * as utils from '../helpers/utils';
import AvatarEditor from 'react-avatar-editor'
import Country from './Country';
import useEscape from '../hooks/useEscape';
import Field from './Field';
import { useGetSelfAccountQuery, useGetProductQuery } from '../api/accountApi';

import '../scss/custom.scss';
import "react-datepicker/dist/react-datepicker.css";
import '../css/tile.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../css/nkp.css';
import { ToastContent } from '../types/ToastContent';
import { getExpanded } from '../redux/modal/modalSlice';

const UserProfile = () => {
    const expanded = useSelector(getExpanded);
    const { data: existingAccount, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
    const { data: product } = useGetProductQuery()
    const account = useSelector(selectAccount);
    const avatar = useSelector(selectAvatar);
    const editMode = useSelector(getEditMode);
    const [scale, setScale] = useState(1);
    const [editorActive, setEditorActive] = useState(false);
    const [editUsername, setEditUsername] = useState(false);
    const [editUsernameVisible, setEditUsernameVisible] = useState(false);
    const [editAvaVisible, setEditAvaVisible] = useState(false);
    const authStatus = useSelector(selectIsAuthenticated);
    const odStatus = useSelector(getOdStatus);
    // const overdue = useSelector(getOverdue);
    const changeInProgress = useSelector(getChangeInProgress);
    const [toast] = useState<ToastContent>({});
    const [showToast, setShowToast] = useState(false);

    useEscape(() => handleCancelAva())

    const editorRef = useRef<InstanceType<typeof AvatarEditor>>(null)
    const avaRef = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const dispatch = useDispatch();

    // useEffect(
    //     () => {
    //         if (isAccountSuccess) {
    //             dispatch(setUser(existingAccount))
    //         }
    //     }, [account, dispatch, existingAccount, isAccountSuccess]
    // )

    useEffect(() => {
        // if (odStatus === 'idle' && authStatus === true) {
        //     dispatch(fetchOverdue(authData))
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [odStatus, authStatus, dispatch])

    const handleNewImage = useCallback((e: { target: { files: FileList | null; }; }) => {
        console.log(avatar)
        const reader = new FileReader();
        if ((e.target.files?.length || 0) > 0) {
            reader.onload = function(e) {
                dispatch(setUserAvatar(e.target?.result))
            };
            reader.readAsDataURL(e.target.files ? e.target.files[0] : new Blob())
        }
    }, [avatar, dispatch]);

    const handleScale = useCallback((e: { target: { value: string; }; }) => {
        const newScale = parseFloat(e.target.value)
        setScale(newScale)
    }, []);

    const handleSaveAva = useCallback(() => {
        const editor = editorRef.current;
        const canvasScaled = editor?.getImageScaledToCanvas();
        const croppedImg = canvasScaled?.toDataURL();
        dispatch(setAvaChanged(true))
        dispatch(setUserAvatar(croppedImg))
        setEditorActive(false)
        return croppedImg;
    }, [dispatch]);

    const handleCancelAva = useCallback(() => {
        dispatch(setUserAvatar(null))
        dispatch(setAvaChanged(false))
        setEditorActive(false)
    }, [dispatch]);

    const handleSubscribe = useCallback(() => {
        history.push("/subscribe")
    }, [history]);

    const userData = useCallback(() => {
        let message
        switch (product?.phase) {
            case "TRIAL":
                message = `Подписка активна. Вам предоставлен пробный бесплатный период на 1 месяц. Дата первого списания абонентской платы - ${utils.formatDate(new Date(product?.billed_till || ""))}`;
                break;
            case "ACTIVE":
                message = `Подписка активна. Дата ближайшего списания абонентской платы - ${utils.formatDate(new Date(product?.billed_till || ""))}`;
                break;
            case "OVERDUE":
                message = `Подписка активна, но пропущена дата списания абонентской платы. Если не оплатить ее до ${utils.shiftDate(new Date(product?.billed_till || ""), /*overdue?.warning_threshold ||*/ 0)}, сервис будет ограничен.`;
                break;
            case "SUSPENDED":
                message = `Подписка приостановлена. Если не возобновить ее до ${utils.shiftDate(new Date(product?.billed_till || ""), /*overdue?.block_threshold ||*/ 0)}, ваши данные будут удалены`;
                break;
            case "BLOCKED":
                message = `Подписка неактивна. В связи с длительным периодом неуплаты ваши данные были удалены, доступ предоставляется в демо-режиме.`;
                break;
            default:
                message = `Подписка неактивна, доступ предоставляется в демо-режиме.`;
        }
        return message
    }, [product?.billed_till, product?.phase]);

    const sanitizeValue = useCallback((value: string | undefined) => {
        if (!value) { return "" }

        return value
    }, []);

    const productLevel = useCallback(() => {
        {
            if (changeInProgress) {
                return (
                    <div data-testid="tarot-subscribe-status">
                        В настоящее время происходит смена уровня подписки, пожалуйста немного подождите.
                    </div>
                )
            }

            return (
                <>
                    {product?.name == "DEMO" ?
                        <>
                        <div data-testid="tarot-subscribe-status">
                            Подписка неактивна, приложение работает в демо-режиме.
                        </div>
                        <div data-testid="tarot-subscribe-button">
                            <label id="theme-switch" onClick={handleSubscribe}>Оформить подписку</label>
                        </div>
                        </>
                        :
                        <>
                        <div data-testid="tarot-subscribe-status">
                            <b>{product?.pretty_name}</b>
                        </div>
                        {/* <div>
                            {product?.description}
                        </div> */}
                        <div data-testid="tarot-subscribe-button">
                            <label id="theme-switch" onClick={handleSubscribe}>Сменить тариф</label>
                        </div>
                        </>
                    }
                </>
            )
        }

    }, [changeInProgress, handleSubscribe, product]);

    return (
        <div className={expanded ? "tarot-content-expanded" : "tarot-content"}>
            {!isAccountSuccess && existingAccount ?
            <div id="tarology-spinner" data-testid="tarot-spinner">
                <Spinner animation="border" />
            </div>
            :
            <>
            <div className="tarot-profile-content">
                <div className='content'>
                {editorActive ?
                <div id="user-profile">
                    <div id="user-avatar-bed" data-testid="tarot-avatar-bed"
                        onMouseEnter={() => setEditAvaVisible(true)}
                        onMouseLeave={() => setEditAvaVisible(false)}                 
                    >
                    <div id="user-profile-avatar" className="edit" ref={avaRef}>
                        <AvatarEditor
                            className='avatar-editor'
                            crossOrigin='anonymous'
                            image={avatar}
                            width={170}
                            height={170}
                            border={15}
                            borderRadius={500}
                            color={[255, 255, 255, 0.9]} // RGBA
                            scale={scale}
                            rotate={0}
                            ref={editorRef}
                        />
                            <BiZoomIn className='avatar-editor-zoom'/>
                            <input
                                name="scale"
                                type="range"
                                onChange={handleScale}
                                min="0.1"
                                max="5"
                                step="0.01"
                                defaultValue="1"
                            />
                            <center>
                                <label htmlFor="avatar-pic">
                                    <FaUpload /> Загрузить фото
                                    <input id="avatar-pic" className="tarot-sex-button mt-1" name="newImage" type="file" onChange={handleNewImage} /> 
                                </label>
                            </center>
                    {editAvaVisible && <FaSave className='edit-avatar' onClick={handleSaveAva} /> }
                </div>
                </div>
                    <div id="user-profile-name" data-testid="tarot-profile-name">
                        {account?.user_name}
                    </div>
                </div>
                :
                <div id="user-profile">
                    <div id="user-avatar-bed" data-testid="tarot-avatar-bed"
                        onMouseEnter={() => setEditAvaVisible(true)}
                        onMouseLeave={() => setEditAvaVisible(false)}                                       
                    >
                    <div id="user-profile-avatar" data-testid="tarot-profile-avatar">
                            <img src={avatar} />
                            {editAvaVisible && <FaPencilAlt className='edit-avatar' onClick={() => setEditorActive(!editorActive)} />}
                    </div>
                    </div>
                    <div id="user-profile-name" data-testid="tarot-profile-name"
                        onMouseEnter={() => setEditUsernameVisible(true)}
                        onMouseLeave={() => setEditUsernameVisible(false)}                                                           
                    >
                        {editUsername ?
                            <>
                                <input 
                                    type="text" 
                                    value={sanitizeValue(account?.user_name)} 
                                    onChange={(e) => dispatch(setUserUsername(e.target.value))}
                                    //onBlur={() => setEditUsername(!editUsername)}

                                />
                                <FaPencilAlt className='edit-username' onClick={() => setEditUsername(!editUsername)} />
                            </>
                        :
                            <>
                                {account?.user_name}
                                {editUsernameVisible && <FaPencilAlt className='edit-username' onClick={() => setEditUsername(!editUsername)} />}
                            </>
                        }
                    </div>
                </div>}
                <div style={{ marginRight: '-10px' }} data-testid="tarot-profile-edit-button">
                    <center>
                        <Button className="tarot-sex-button mr-3 button-mobile-only" size="sm" onClick={() => dispatch(switchEditMode())}><FaPencilAlt/> Изменить</Button>
                    </center>
                </div>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">{toast?.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{toast?.description}</Toast.Body>
                </Toast>
                <div id="user-profile-data">
                    <Row className="mb-5 tarot-user-data-section">
                        <Col className="content">
                            <span>Контактная информация</span>
                            <div className='profile-section-wrapper'>
                                <div id="profile-section">
                                    <Field  editMode={editMode || false}
                                            label={"Имя"}
                                            value={sanitizeValue(account?.name)}
                                            action={setUserGivenName}
                                            data-testid="tarot-profile-field-name"
                                    />
                                    <Field  editMode={editMode || false}
                                            label={"Фамилия"}
                                            value={sanitizeValue(account?.surname)}
                                            action={setUserFamilyName}
                                            data-testid="tarot-profile-field-surname"
                                    />
                                <div className='field'>
                                    <div className='label'>
                                        Страна:
                                    </div>
                                    <div className='data' data-testid="tarot-profile-field-country">
                                        {editMode ?
                                        <select value={sanitizeValue(account?.country)}
                                            onChange={(e) => dispatch(setUserCountry(e.target.value))}
                                            //onBlur={() => setEditCountry(!editCountry)}
                                        >
                                            {Object.keys(Country).map((key, index) => {
                                                return (
                                                    <option key={index} value={key}>{Country[key]}</option>
                                                )
                                            })}
                                        </select>
                                        :
                                        Country[(account?.country || "")]}
                                    </div>
                                </div>
                                    <Field  editMode={editMode || false}
                                            label={"E-mail"}
                                            value={sanitizeValue(account?.email)}
                                            action={setUserEmail}
                                            data-testid="tarot-profile-field-email"
                                    />
                                    <Field  editMode={editMode || false}
                                            label={"Телефон"}
                                            value={sanitizeValue(account?.phone)}
                                            action={setUserPhone}
                                            data-testid="tarot-profile-field-phone"
                                    />
                                </div>
                                <div>
                                    <FaPencilAlt className='edit-field' onClick={() => dispatch(switchEditMode())} />
                                </div>
                            </div>
                        </Col>
                        <Col className="content">
                            <span>Статус подписки</span>
                            <div className='profile-section-wrapper'>
                            <div id="profile-section">
                            <div className='field'>
                                <div className='label'>
                                    Уровень подписки:
                                </div>
                                <div className='data' data-testid="tarot-profile-level">
                                    {productLevel()}
                                </div>
                            </div>
                            <div className='field'>
                                <div className='label'>
                                    Баланс:
                                </div>
                                <div className='data' data-testid="tarot-profile-balance">
                                    {`${account?.balance == null ? 0 : account?.balance.toFixed(2)} ${account?.currency}`}
                                </div>
                            </div>
                            <div className='field'>
                                <div className='label'>
                                    Текущий статус:
                                </div>
                                <div className='data' data-testid="tarot-profile-status">
                                    {userData()}
                                </div>
                            </div>
                            </div>
                            </div>
                        </Col>
                    </Row> 
                </div>                
            </div>
            </div>
            </>}
        </div>
    );
}

export default UserProfile;