import React, { useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaRegFilePdf } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { tooltip } from '../toolbar/ToolbarTooltip';
import { getDealExportStatus, getDealPDFFromApi, getDealUndoQueueSize } from '../../redux/deals/dealsSlice'; 
import { useGetPermissionsQuery } from '../../api/accountApi';
import { getExportStatus, getPDFFromApi } from '../../redux/client/clientSlice';
import { Deal } from '../../types/Deal';

type Props = {
    deal: Deal;
    path: string;
}

const ExportPDFButton = ({ deal, path }: Props) => {
    const dispatch = useDispatch();
	const { data: permissions } = useGetPermissionsQuery()

    const dealUndoQueue = useSelector(getDealUndoQueueSize);
    const clientExportStatus = useSelector(getExportStatus);
    const dealExportStatus = useSelector(getDealExportStatus);

    const getQueues = useCallback(() => {
        return dealUndoQueue == 0
    }, [dealUndoQueue]);

    const exportToPdfActive = useCallback(() => {
        let section = path?.split('/')[1]
        switch (section) {
            case "clients":
                return true
            case "client":
                return false
            case "deal":
                if (permissions?.deals.view && getQueues()) {
                    return true
                }
                return false
            case "profile":
                return false
            default:
                return true
        }
    }, [getQueues, path, permissions?.deals.view]);


    const handleExportToPDFButton = useCallback(() => {
        let section = path?.split('/')[1]
        switch (section) {
            case "clients":
                handleExportClients()
                break;
            case "deal":
                handleExportDeal()
                break;
        }
    }, []);

    const handleExportClients = useCallback(() => {
        if (clientExportStatus == 'idle') {
            dispatch(getPDFFromApi())
        }
    }, []);

    const handleExportDeal = useCallback(() => {
		if (deal?.id != "0" && dealExportStatus == 'idle') {
            dispatch(getDealPDFFromApi(deal?.id || ""))
		}
    }, []);

    return (
        <div className={`toolbar-button ${!exportToPdfActive() ? "disabled" : ""}`} onClick={() => handleExportToPDFButton()}>
            <OverlayTrigger placement="bottom" overlay={tooltip("Выгрузка в PDF")}>
                <div>
                    <FaRegFilePdf />
                </div>
            </OverlayTrigger>
        </div>
    )
}

export default ExportPDFButton;