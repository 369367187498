import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setAuthenticated, selectAvatar } from '../redux/settings/settingsSlice';
import { setExpanded, getExpanded } from '../redux/modal/modalSlice';
import Spinner from 'react-bootstrap/Spinner';
import { Link, /*useLocation,*/ useHistory } from "react-router-dom";
import { FaHandSparkles, FaUserTie, FaAngleDoubleRight, FaAngleDoubleLeft, 
    FaUsers, FaAngleDown, FaSearch, FaAngleRight } from "react-icons/fa";
import { BiExit } from 'react-icons/bi';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGetProductQuery, useGetSelfAccountQuery } from '../api/accountApi';
import { useGetCustomersQuery, useGetFavoriteCustomersQuery } from '../api/clientApi';
import { useLogoutMutation } from '../api/authApi';
import { showAlert } from '../redux/modal/modalSlice';
import DemoPlate from './DemoPlate';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Client } from '../types/Client';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { socketAuthenticated } from '../redux/socket/socket';

const { TAROT_VERSION } = process.env;

const Sidebar = () => {
    const [singleSelections, setSingleSelections] = useState<Option[]>([]);
    const [clientsOpen, setClientsOpen] = useState(false);
    const history = useHistory();

    const dispatch = useDispatch();
    const { data: clients, isSuccess: isClientsSuccess } = useGetCustomersQuery({ sort: "name:asc", paging: null })
    const { data: favoriteClients } = useGetFavoriteCustomersQuery()
    const { data: product } = useGetProductQuery()
    const { data: account } = useGetSelfAccountQuery()
    const avatar = useSelector(selectAvatar);
    const expanded = useSelector(getExpanded);

    // const location = useLocation();
    const [logout] = useLogoutMutation()

    const menuIconClick = () => {
        dispatch(setExpanded(!expanded));
    };

    useEffect(
        () => {
            if (singleSelections.length > 0) {
                const client: Client = singleSelections[0] as Client;
                history.push(`/client/${client.id}`);
                setSingleSelections([]);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [singleSelections]
    )

    useEffect(
        () => {
            if(account != null && account?.id != null) {
                dispatch(socketAuthenticated(account.id));
            }
        }, [account, dispatch]
    )

    const handleNewDeal = () => {
        history.push(`/deal/0`);
        dispatch({ type: "API_DEAL_FLAG_SET" });
    }

    // const newDealActive = () => {
    //     let section = location?.pathname?.split('/')[1]
    //     let id = location?.pathname?.split('/')[2]
    //     if (section == "deal" && id == "0") return false
    //     return true
    // }

    return (
        <div id="sidebar" className={`tarot-sidebar ${expanded ? "" : "collapsed"}`}>
        <div className="expand-button" onClick={menuIconClick}>
            {!expanded ? (
                <FaAngleDoubleRight/>
            ) : (
                <FaAngleDoubleLeft/>
            )}
        </div>
        <div className="sidebar-profile-avatar" data-testid="tarot-profile-avatar">
            <Link to="/profile">
                <img src={avatar} />
            </Link>
        </div>
        <div className="menu" data-testid="tarot-sidebar-menu">
            <div className="menu-item" /*disabled={(parseLocation(location).location == "deal" && parseLocation(location).id == 0) ? false : true} */
                onClick={handleNewDeal} data-testid="tarot-menu-item">
                <FaHandSparkles />
                <span className="menu-item-text">Новый расклад</span>
            </div>
            <div className="menu-item" onClick={() => setClientsOpen(!clientsOpen)}>
                <FaUsers />
                <span className="menu-item-text">Клиенты</span>
                {clientsOpen ? <FaAngleDown className='angle' /> : <FaAngleRight className='angle' />}
            </div>
            <div className={`submenu ${clientsOpen ? "" : "hidden"}`}>
                <div className='submenu-item'>
                    <Link to={`/clients`} data-testid="tarot-client-all">
                        Все клиенты
                    </Link>
                </div>                    
                <div className='submenu-item'>
                    <Link to={`/client/0`} data-testid="tarot-client-new">
                        <i>&lt; Новый клиент &gt;</i>
                    </Link>
                </div>
                {favoriteClients?.map((customer) => {
                    let shortName = ""
                    const nameParts = customer.name?.split(" ");
                    if(nameParts) {
                        if (nameParts.length > 1) {
                            shortName = nameParts[0] + " " + nameParts[1].substring(0, 1) + "."
                        } else {
                            shortName = customer.name || ""
                        }    
                    }
                    return (
                        <div className='submenu-item' key={customer.id}>
                            <Link to={`/client/${customer.id}`} data-testid="tarot-client-menu">
                                {shortName}
                            </Link>    
                        </div>
                    )
                })}

            </div>
            <div className="menu-item">
                <FaUserTie />
                <Link to={`/profile`}>
                    <span className="menu-item-text">Личный кабинет</span>
                </Link>
            </div>
            <div className="menu-item">
                <BiExit />
                <span className="menu-item-text"
                    onClick={async () => {``
                        try {
                            await logout().unwrap()
                            localStorage.removeItem("accessToken")
                            localStorage.removeItem("refreshToken")
                            dispatch(setAuthenticated(false))
                            history.push('/login')
                        } catch (err) {
                            dispatch(showAlert("Что-то пошло не так..."))
                        }
                    }}                
                >Выход</span>
            </div>

            {!isClientsSuccess ?
                <div id="tarology-spinner">
                    <Spinner animation="border" />
                </div>
                :            
                <div className="menu-item">
                    <FaSearch />
                    <div className="menu-item-text" data-testid="tarot-customer-search">
                        <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            onChange={setSingleSelections}
                            options={clients.entities}
                            placeholder="Имя клиента"
                            selected={singleSelections}
                            emptyLabel="Клиент не найден"
                            className={expanded ? "mb-3" : "mb-3 search-typeahead"}
                        />
                    </div> 
                </div>}
        </div>
        {product?.name == 'DEMO' && <DemoPlate />}
        <div className='tarot-version'>Версия {TAROT_VERSION}</div>
    </div>
    )
}

export default Sidebar;