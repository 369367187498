/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store';
import { ExtraDef } from '../../types/ExtraDef';

interface MobileState {
  expanded: boolean;
  confirmDeleteVisible: boolean;
  confirmDeleteClientVisible: boolean;
  subscribeVisible: boolean;
  alert: {
    visible: boolean;
    message: string;
  };
  login: {
    visible: boolean;
  };
  register: {
    visible: boolean;
  };
  forgotten: {
    visible: boolean;
  };
  hint: {
    visible: boolean;
  };
  question: {
    visible: boolean;
    // editMode: boolean;
    // question: string;
    extra: ExtraDef;
  };
  comments: {
    visible: boolean;
  };
}

const initialState: MobileState = {
  expanded: false,
  confirmDeleteVisible: false,
  confirmDeleteClientVisible: false,
  subscribeVisible: false,
  alert: {
    visible: false,
    message: ""
  },
  login: {
    visible: false,
  },
  register: {
    visible: false,
  },
  forgotten: {
    visible: false,
  },
  hint: {
    visible: false
  },
  question: {
    visible: false,
    // editMode: false,
    // question: "",
    extra: {}
  },
  comments: {
    visible: false
  }
}

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setExpanded(state, action: PayloadAction<boolean>) {
      state.expanded = action.payload
    },
    showConfirmDeleteClient(state, action: PayloadAction<void>) {
      state.confirmDeleteClientVisible = true
    },
    hideConfirmDeleteClient(state, action: PayloadAction<void>) {
      state.confirmDeleteClientVisible = false
    },
    showConfirmDelete(state, action: PayloadAction<void>) {
      state.confirmDeleteVisible = true
    },
    hideConfirmDelete(state, action: PayloadAction<void>) {
      state.confirmDeleteVisible = false
    },
    showSubscribe(state, action: PayloadAction<void>) {
      state.subscribeVisible = true
    },
    hideSubscribe(state, action: PayloadAction<void>) {
      state.subscribeVisible = false
    },
    showAlert(state, action: PayloadAction<string>) {
      state.alert.visible = true
      state.alert.message = action.payload
    },
    hideAlert(state, action: PayloadAction<void>) {
      state.alert.visible = false
      state.alert.message = ""
    },
    showLogin(state, action) {
      state.login.visible = true
    },
    hideLogin(state, action) {
      state.login.visible = false
    },
    showRegister(state, action) {
      state.register.visible = true
    },
    hideRegister(state, action) {
      state.register.visible = false
    },
    showForgotten(state, action) {
      state.forgotten.visible = true
    },
    hideForgotten(state, action) {
      state.forgotten.visible = false
    },
    showHint(state, action: PayloadAction<void>) {
      state.hint.visible = true
    }, 
    hideHint(state, action: PayloadAction<void>) {
      state.hint.visible = false
    },
    showQuestion(state, action: PayloadAction<ExtraDef>) {
      state.question.visible = true
      state.question.extra = action.payload
    }, 
    hideQuestion(state, action: PayloadAction<void>) {
      state.question.visible = false
    },
    showComments(state, action: PayloadAction<void>) {
      state.comments.visible = true
    }, 
    hideComments(state, action: PayloadAction<void>) {
      state.comments.visible = false
    }, 
    // switchQuestionEditMode(state, action: PayloadAction<void>) {
    //   state.question.editMode = !state.question.editMode
    // }
  },
})

export const { setExpanded, showConfirmDelete, hideConfirmDelete, showSubscribe,
  hideSubscribe, showAlert, hideAlert, showLogin, hideLogin, showRegister, 
  hideRegister, showForgotten, hideForgotten, showHint, hideHint, showQuestion,
  hideQuestion, showComments, hideComments, showConfirmDeleteClient, 
  hideConfirmDeleteClient } = modalSlice.actions
  
export default modalSlice.reducer

export const getExpanded = (state: RootState) => state.modals.expanded;

export const getConfirmDeleteVisible = (state: RootState) => state.modals.confirmDeleteVisible;

export const getConfirmDeleteClientVisible = (state: RootState) => state.modals.confirmDeleteClientVisible;

export const getSubscribeVisible = (state: RootState) => state.modals.subscribeVisible;

export const getAlert = (state: RootState) => state.modals.alert;

export const getLogin = (state: RootState) => state.modals.login;

export const getRegister = (state: RootState) => state.modals.register;

export const getForgotten = (state: RootState) => state.modals.forgotten;

export const getHint = (state: RootState) => state.modals.hint;

export const getQuestion = (state: RootState) => state.modals.question;

export const getComments = (state: RootState) => state.modals.comments;