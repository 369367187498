import { createSlice, createEntityAdapter, PayloadAction, EntityId, Dictionary } from '@reduxjs/toolkit'
import * as geo from '../../helpers/geometry';
import { RootState } from '../store';
import { Position } from '../../types/Position';
import { Point, GeometryPayload } from '../../types/Geometry';

declare global {
  export interface Path2D {
    roundRectRotated(x: number, y: number, w: number, h: number, rg: number, crn: number): Path2D;
    center: Point;
  }  
}

const geometryAdapter = createEntityAdapter<Position>({
  selectId: (position: Position) => position.position,
})

// const extrasAdapter = createEntityAdapter({
//   selectId: (position: Position) => position.position,
// })

// const extraSetsAdapter = createEntityAdapter()

interface GeometryState {
  geo: geo.Geometry;
  ids: EntityId[];
  entities: Dictionary<Position>;
}

const initialState: GeometryState = geometryAdapter.getInitialState({
  geo: { positions: [] },
})

Path2D.prototype.roundRectRotated = function (x: number, y: number, w: number, h: number, rg: number, crn: number) {
  if (w < 2 * crn) crn = w / 2;
  if (h < 2 * crn) crn = h / 2;
  const hwh = w / 2;
  const hhh = (h - (2 * crn)) / 2;
  const hwv = (w - (2 * crn)) / 2;
  const hhv = h / 2;
  const hw = w / 2;
  const hh = h / 2;
  const r = rg * Math.PI / 180;
  const c = Math.cos(r);
  const s = Math.sin(r);
  const rh1x = - hwh * c - hhh * s;
  const rh1y = - hwh * s + hhh * c;
  const rh2x = hwh * c - hhh * s;
  const rh2y = hwh * s + hhh * c;
  const rv1x = - hwv * c - hhv * s;
  const rv1y = - hwv * s + hhv * c;
  const rv2x = hwv * c - hhv * s;
  const rv2y = hwv * s + hhv * c;
  const r1x = - hw * c - hh * s;
  const r1y = - hw * s + hh * c; 
  const r2x = hw * c - hh * s;
  const r2y = hw * s + hh * c;
  this.moveTo(x + rh1x, y + rh1y);
  this.arcTo(x + r1x, y + r1y, x + rv1x, y + rv1y, crn);
  this.lineTo(x + rv2x, y + rv2y);
  this.arcTo(x + r2x, y + r2y, x + rh2x, y + rh2y, crn);
  this.lineTo(x - rh1x, y - rh1y);
  this.arcTo(x - r1x, y - r1y, x - rv1x, y - rv1y, crn);
  this.lineTo(x - rv2x, y - rv2y);
  this.arcTo(x - r2x, y - r2y, x - rh2x, y - rh2y, crn);
  this.closePath();

  const ox1 = Math.min(x + r1x, x + r2x, x - r1x, x - r2x);
  const oy1 = Math.min(y + r1y, y + r2y, y - r1y, y - r2y);
  const ox2 = Math.max(x + r1x, x + r2x, x - r1x, x - r2x);
  const oy2 = Math.max(y + r1y, y + r2y, y - r1y, y - r2y);

  const cx = ox1 + (ox2 - ox1) / 2;
  const cy = oy1 + (oy2 - oy1) / 2;

  this.center = { x: cx, y: cy }

  return this;
}

const geometrySlice = createSlice({
  name: 'geometry',
  initialState,
  reducers: {
    fillGeometry(state, action: PayloadAction<GeometryPayload>) {
      const {positions, ...newVals} = action.payload.geometry;
      const stage = action.payload.geometry.stage;
      const positionEntries = positions.map(pos => {
        const rot = geo.applyRotation(pos);
        const newGeo = {...pos};
        newGeo.offsetX = rot.offX;
        newGeo.offsetY = rot.offY;
        newGeo.x = rot.x;
        newGeo.y = rot.y;
        newGeo.rotation = rot.rotation;

        const path = new Path2D();
        path.roundRectRotated((rot.x || 0), (rot.y || 0), (pos.width || 0), 
                              (pos.height || 0), (rot.rotation || 0), (pos.width || 0) / 12);
        newGeo.path = path;
        
        return newGeo;
      });

      const hintBtn = new Path2D();
      hintBtn.roundRectRotated(stage ? (stage.width || 0) - 30 : 70, 30, 50, 50, 0, 12);

      const plusBtn = new Path2D();
      plusBtn.roundRectRotated(stage ? (stage.width || 0) - 30 : 70, 
        stage ? (stage.height || 0) - 30 : 70,  50, 50, 0, 12);

      geometryAdapter.setAll(state, positionEntries);
      state.geo = {...state.geo, ...newVals, hintBtn: hintBtn, plusBtn: plusBtn};
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetGeometry(state, action: PayloadAction<void>) {
      geometryAdapter.removeAll(state);
      state.geo = { positions: [] };
    },
  }
})

export const { fillGeometry, resetGeometry } = geometrySlice.actions

export default geometrySlice.reducer

export const { selectAll: selectAllPositions,
  selectById: selectPositionById,
  selectIds: selectPositionIds
} = geometryAdapter.getSelectors((state: RootState) => state.geometry)

export const selectGeometry = (state: RootState) => state.geometry;

export const selectPosition = (state: RootState, position: string) => {
  return state.geometry.entities[position]
}