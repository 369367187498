import React from 'react';
import Floating from './Floating';
import ArticleText from '../Article';
import { Article } from '../../types/Articles';

type Props = {
    articles: Article[];
    switchFunc: (position: string) => void;
}

const CardArticles = ({ articles, switchFunc }: Props) => {
    return (
        <>
            {articles.map((article, index) => {
                // if (article.position) {
                    return (
                        <Floating 
                            key={index} 
                            close={() => switchFunc(article.position!)} 
                            title={article.title}
                            x0={article.x}
                            y0={article.y}
                            dataTestid="tarot-article-float"
                        >
                            <ArticleText article={article.article} />
                        </Floating>
                    )    
                // }
            })}
        </>
    )
}

export default CardArticles;