/* eslint-disable @typescript-eslint/no-unused-vars */
import { apiSlice } from '../redux/apiSlice'
import { Deck } from '../types/Deck';

const enhancedApi = apiSlice.enhanceEndpoints({
  addTagTypes: ["Decks"],
});

const deckApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getDecks: builder.query<Deck[], void>({
      query: () => `/decks`,
      transformResponse: (response: Deck[], meta, arg) => response.sort((a, b) => (a.title > b.title) ? 1 : -1),
      providesTags: (result, error, id) => [{ type: 'Decks', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getOnlyAuthorizedDecks: builder.query<Deck[], void>({
      query: () => `/decks/auth-only`,
      transformResponse: (response: Deck[], meta, arg) => response.sort((a, b) => (a.title > b.title) ? 1 : -1),
      providesTags: (result, error, id) => [{ type: 'Decks', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getDeck: builder.query({
      query: deckId => `/decks/${deckId}`,
      providesTags: (result, error, id) => [{ type: 'Decks', id }],
      keepUnusedDataFor: 36000
    }),
    getDeckByName: builder.query({
      query: deckName => `/decks/title=${deckName}`,
      providesTags: (result, error, id) => [{ type: 'Decks', id }],
      keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetDeckQuery, useGetDecksQuery, useGetOnlyAuthorizedDecksQuery, useGetDeckByNameQuery } = deckApi