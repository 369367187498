import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setDealToDetele, getDealPDFFromApi, getExportId, 
    getDealExportStatus } from '../../redux/deals/dealsSlice';
import { Deal } from '../../types/Deal';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { FaHandHolding, FaTrash, FaFilePdf } from "react-icons/fa";
import * as utils from '../../helpers/utils';
import { showConfirmDelete } from '../../redux/modal/modalSlice';
import { useGetDealTypeQuery } from '../../api/dealTypeApi';

type Props = {
    deal: Deal;
}

const ClientDeal = ({ deal }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const dealExportStatus = useSelector(getDealExportStatus);
    const dealExportId = useSelector(getExportId);

    const { data: dealType } = useGetDealTypeQuery(deal?.deal_type?.id, { skip: !deal?.deal_type })

    const handleDeleteDeal = useCallback((deal: Deal) => {
        dispatch(setDealToDetele(deal))
        dispatch(showConfirmDelete())
    }, [dispatch]);

    const getPDF = useCallback((dealId?: string) => {
		if (dealExportStatus == 'idle' && dealId) {
            dispatch(getDealPDFFromApi(dealId!))
		}
    }, [dealExportStatus, dispatch]);

    const goTo = useCallback((id?: string) => {
        if (id) {
            history.push(`/deal/${id}`)
        }
    }, [history]);

    return (
        <Row className='tarot-client-deal-bed'>
        {dealExportStatus == 'active' && dealExportId == deal.id &&
        <div data-testid='tarot-spinner' className='tarot-spinner-overlay'>
            <Spinner 
                as="span"
                variant="dark"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
            /> Скачиваю...
        </div>}
        <Col className="content mt-2 tarot-client-deal" data-testid="tarot-client-deal">
            <div>
                <Row>
                    {/* {console.log(dealType)} */}
                    <div style={{ width: "30px", marginLeft: "20px"  }} onClick={() => goTo(deal.id)}
                        data-testid="tarot-client-deal-icon">
                        <FaHandHolding size={30}/>
                    </div>
                    <Col>
                        <div onClick={() => goTo(deal.id)} data-testid="tarot-client-deal-type">
                            <b>{dealType?.title}</b>
                        </div>
                        <div onClick={() => goTo(deal.id)} data-testid="tarot-client-deal-question">
                            {deal?.questions && deal.questions}
                        </div>
                        <div onClick={() => goTo(deal.id)} data-testid="tarot-client-deal-date">
                            <i>{utils.formatDate(new Date(deal?.date || ""))}</i>
                        </div>
                        <div className="client-export-pdf" onClick={() => getPDF(deal.id)}
                            data-testid="tarot-export-pdf-button">
                            <FaFilePdf />
                        </div>
                        <div className="client-trash-bin" onClick={() => handleDeleteDeal(deal)}
                            data-testid="tarot-delete-deal-button">
                            <FaTrash />
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
        </Row>
    )
}

export default ClientDeal;