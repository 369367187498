import { CardRef } from "../types/CardRef";
import { Position } from "../types/Position";
import { Geometry } from "./geometry";

// eslint-disable-next-line no-undef
Path2D.prototype.roundRect = function (x: number, y: number, w: number, h: number, r: number) {
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    this.moveTo(x + r, y);
    this.arcTo(x + w, y,   x + w, y + h, r);
    this.arcTo(x + w, y + h, x,   y + h, r);
    this.arcTo(x,   y + h, x,   y,   r);
    this.arcTo(x,   y,   x + w, y,   r);
    this.closePath();
    return this;
}

// export const drawHintButton = (ctx: CanvasRenderingContext2D, geometry: Geometry) => {
//     if (geometry.hintBtn) {
//         ctx.save();
//         ctx.globalAlpha = 0.5;
//         ctx.fillStyle = "#D4E8EA";
//         ctx.fill(geometry.hintBtn)
//         ctx.strokeStyle = "#185c39";
//         ctx.setLineDash([3, 3]);
//         ctx.stroke(geometry.hintBtn)
//         ctx.setLineDash([]);
//         ctx.fillStyle = "#185c39";
//         ctx.beginPath();
//         ctx.arc(geometry.stage?.width ? geometry.stage.width - 30 : 70, 
//             30, 15, 0, 2 * Math.PI);
//         ctx.fill();
//         ctx.fillStyle = "white";
//         ctx.font = `bold 30px sans-serif`;
//         ctx.textAlign="center"; 
//         ctx.textBaseline = "middle";
//         ctx.fillText('?', geometry.stage?.width ? geometry.stage.width - 30 : 70, 32);
//         ctx.restore();    
//     }
// }

export const drawPlusButton = (ctx: CanvasRenderingContext2D, geometry: Geometry) => {
    if (geometry.plusBtn) {
        ctx.save();
        ctx.globalAlpha = 0.5;
        ctx.fillStyle = "#D4E8EA";
        ctx.fill(geometry.plusBtn)
        ctx.strokeStyle = "#185c39";
        ctx.setLineDash([3, 3]);
        ctx.stroke(geometry.plusBtn)
        ctx.setLineDash([]);
        ctx.fillStyle = "#185c39";
        ctx.beginPath();
        ctx.arc(geometry.stage?.width ? geometry.stage.width - 30 : 70, 
            geometry.stage?.height ? geometry.stage.height - 30 : 70, 15, 0, 2 * Math.PI);
        ctx.fill();
        ctx.fillStyle = "white";
        ctx.font = `bold 30px sans-serif`;
        ctx.textAlign="center"; 
        ctx.textBaseline = "middle";
        ctx.fillText('+', geometry.stage?.width ? geometry.stage.width - 30 : 70, 
            geometry.stage?.height ? geometry.stage.height - 30 : 70);
        ctx.restore();    
    }
}

export const drawPlusButtonExtra = (ctx: CanvasRenderingContext2D, geometry: Geometry) => {
    if (geometry.plusBtn) {
        ctx.save();
        ctx.globalAlpha = 0.5;
        ctx.fillStyle = "#D4E8EA";
        ctx.fill(geometry.plusBtn)
        ctx.strokeStyle = "#185c39";
        ctx.setLineDash([3, 3]);
        ctx.stroke(geometry.plusBtn)
        ctx.setLineDash([]);
        ctx.fillStyle = "#185c39";
        ctx.beginPath();
        ctx.arc(geometry.stage?.width ? geometry.stage.width / 2 : 50, 
            geometry.stage?.height ? geometry.stage.height + 20 : 50, 15, 0, 2 * Math.PI);
        ctx.fill();
        ctx.fillStyle = "white";
        ctx.font = `bold 30px sans-serif`;
        ctx.textAlign="center"; 
        ctx.textBaseline = "middle";
        ctx.fillText('+', geometry.stage?.width ? geometry.stage.width / 2 : 50, 
            geometry.stage?.height ? geometry.stage.height + 20 : 50);
        ctx.restore();    
    }
}

export const drawTarget = (ctx: CanvasRenderingContext2D, pos: Position) => {
    if (pos.width && pos.path?.center.x !== undefined && pos.x !== undefined && pos.y !== undefined) {
        ctx.save();
        ctx.globalAlpha = 0.5;
        ctx.fillStyle = "white";
        ctx.beginPath();
        ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 3, 0, 2 * Math.PI);
        ctx.fill();
        ctx.strokeStyle = "#185c39";
        ctx.beginPath();
        ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 3, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.beginPath();
        ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 4, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.beginPath();
        ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 6, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.beginPath();
        ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 12, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(pos.x - pos.width / 3, pos.y);
        ctx.lineTo(pos.x + pos.width / 3, pos.y);
        ctx.moveTo(pos.x, pos.y - pos.width / 3);
        ctx.lineTo(pos.x, pos.y + pos.width / 3);
        ctx.stroke();
        ctx.restore();    
    }
}

export const drawCards = (ctx: CanvasRenderingContext2D, pos: Position | undefined, 
                            images: { [key: string]: HTMLImageElement; }, ref: CardRef | undefined) => {
    if (pos?.path && pos.x !== undefined && pos.y !== undefined && pos.rotation !== undefined && 
            pos.width !== undefined && pos.offsetX !== undefined && pos.height !== undefined && pos.offsetY !== undefined) {
        ctx.save();
        ctx.shadowOffsetX = 5;
        ctx.shadowOffsetY = 5;
        ctx.shadowBlur = 5;
        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
        ctx.fillStyle = "#D4E8EA";
        ctx.fill(pos.path)
        ctx.restore();
    
        if (images[pos.position]) {
            ctx.save();
            ctx.clip(pos.path)
            ctx.translate(pos.x, pos.y)
            let position = pos.rotation
            if (ref?.inverted) { position = position + 180 }
            ctx.rotate(position * Math.PI / 180);
            // ctx.rotate(pos.rotation * Math.PI / 180);
            ctx.translate(-pos.x, -pos.y)
            ctx.drawImage(images[pos.position], pos.x - pos.offsetX, pos.y - pos.offsetY, pos.width, pos.height);
            ctx.restore();
        }                   
    
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;
    
        if (!images[pos.position]) {
            ctx.save()
            ctx.translate(pos.x, pos.y)
            ctx.rotate(pos.rotation * Math.PI / 180);
            ctx.translate(-pos.x, -pos.y)
            ctx.fillStyle = "white";
            ctx.font = `bold ${pos.width / 2}px sans-serif`;
            ctx.textAlign="center"; 
            ctx.textBaseline = "middle";
            ctx.fillText(pos.position, pos.path?.center.x, pos.path?.center.y);
            ctx.restore();
        }
    
        ctx.stroke(pos.path)
    }
}